<template>
	<div :class="$t('TradingAccount.box-name')">
		<ss-header mode="Home" bg="rgb(38, 5, 5)"></ss-header>
		<div class="ta-t-sub1 ta-account-new0">
                    <h1 class="p1">{{$t('TradingAccount.screen5.title')}}</h1>
                    <img src="../assets/image/TradingAccount/fth1-line.png" alt="">
                </div>

                <div class="ta-account-new1">
                    <div class="container">
                        <p class="ta-public-title">{{$t('TradingAccount.screen5.text1')}}</p>
                        <p class="ta-public-line"></p>
                        <p class="ta-public-desc">{{$t('TradingAccount.screen5.text2')}}</p>
                        <div class="row">
                            <div class="account-type">
                                <ul>
                                    <li class="li1">
                                        <img src="../assets/image/TradingAccount/account-new-icon4.svg" alt="">
                                        <p class="p1">{{$t('TradingAccount.screen5.text3')}}</p>
                                        <p class="p2">{{$t('TradingAccount.screen5.text4')}}</p>
                                    </li>
                                    <li class="li2">
                                        <p v-html="$t('TradingAccount.screen5.text5')">
                                        </p>
                                        <p v-html="$t('TradingAccount.screen5.text6')">
                                        </p>
                                        <p v-html="$t('TradingAccount.screen5.text7')">
                                        </p>
                                        <a rel="nofollow" :href="khUrl">{{$t('TradingAccount.screen5.register-btn')}}</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="account-type account-type2">
                                <ul>
                                    <li class="li1">
                                        <img src="../assets/image/TradingAccount/account-new-icon5.svg" alt="">
                                        <p class="p1">{{$t('TradingAccount.screen5.text8')}}</p>
                                        <p class="p2">{{$t('TradingAccount.screen5.text9')}}</p>
                                    </li>
                                    <li class="li2">
                                        <p v-html="$t('TradingAccount.screen5.text10')">
                                        </p>
                                        <p v-html="$t('TradingAccount.screen5.text11')">
                                        </p>
                                        <p v-html="$t('TradingAccount.screen5.text12')">
                                        </p>
                                        <a  rel="nofollow" :href="khUrl">{{$t('TradingAccount.screen5.register-btn')}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="ta-account1 ta-account-new2">
                    <p class="ta-public-title">{{$t('TradingAccount.screen5.text13')}}</p>
                    <p class="ta-public-line"></p>
                    <p class="ta-public-desc">{{$t('TradingAccount.screen5.text14')}}</p>
                    <div class="container">
                        <div class="row">
                            <div class="col-md-4 div1">
                                <p class="title">{{$t('TradingAccount.screen5.text15')}}</p>
                                <p class="desc1" :key="index" v-for="(item, index) in $t('TradingAccount.screen1.list')">{{item}}</p>
                            </div>
                            <div class="col-md-4">
                                <p class="title">{{$t('TradingAccount.screen5.text3')}}</p>
                                <p class="desc2">{{$t('TradingAccount.screen1.line11')}}</p>
                                <p class="desc2">0</p>
                                <p class="desc2">500:1</p>
                                <p class="desc2">0.01</p>
                                <p class="desc2">40</p>
                                <p class="desc2">{{$t('TradingAccount.screen1.line100')}}</p>
                                <p class="desc2">30%</p>
                                <p class="desc2">5</p>
                                <p class="desc2"><img src="../assets/image/TradingAccount/account-new-icon3.svg" alt=""></p>
                                <p class="desc2"><img src="../assets/image/TradingAccount/account-new-icon3.svg" alt=""></p>
                                <p class="desc2"><img src="../assets/image/TradingAccount/account-new-icon3.svg" alt=""></p>
                                <p class="desc2"><img src="../assets/image/TradingAccount/account-new-icon3.svg" alt=""></p>
                                <p class="desc2">{{$t('TradingAccount.screen5.text17')}}</p>
                                <p class="desc2"><a  rel="nofollow" :href="khUrl">{{$t('TradingAccount.screen5.text19')}}</a></p>
                            </div>
                            <div class="col-md-4">
                                <p class="title title2">{{$t('TradingAccount.screen5.text8')}}</p>
                                <p class="desc3">{{$t('TradingAccount.screen1.line13')}}</p>
                                <p class="desc3">6USD/lot</p>
                                <p class="desc3">500:1</p>
                                <p class="desc3">0.01</p>
                                <p class="desc2">40</p>
                                <p class="desc2">5000USD</p>
                                <p class="desc2">30%</p>
                                <p class="desc2">0</p>
                                <p class="desc2"><img src="../assets/image/TradingAccount/account-new-icon3.svg" alt=""></p>
                                <p class="desc2"><img src="../assets/image/TradingAccount/account-new-icon3.svg" alt=""></p>
                                <p class="desc2"><img src="../assets/image/TradingAccount/account-new-icon3.svg" alt=""></p>
                                <p class="desc2"><img src="../assets/image/TradingAccount/account-new-icon3.svg" alt=""></p>
                                <p class="desc2">{{$t('TradingAccount.screen5.text18')}}</p>
                                <p class="desc2"><a  rel="nofollow" :href="khUrl">{{$t('TradingAccount.screen5.text20')}}</a></p>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="ta-account-new3">
                    <div class="container">
                        <p class="ta-public-title">{{$t('TradingAccount.screen5.text21')}}</p>
                        <p class="ta-public-line"></p>
                        <p class="ta-public-desc">{{$t('TradingAccount.screen5.text22')}}</p>
                        <div class="row">
                            <div class="col-md-4">
                                <span>1</span>
                                <p class="title" v-html="$t('WhyEBC.screen5.list[0].title')"></p>
                                <p class="desc">{{$t('WhyEBC.screen5.list[0].desc')}}</p>
                            </div>
                            <div class="col-md-4">
                                <span>2</span>
                                <p class="title" v-html="$t('WhyEBC.screen5.list[1].title')"></p>
                                <p class="desc">{{$t('WhyEBC.screen5.list[1].desc')}}</p>
                            </div>
                            <div class="col-md-4">
                                <span>3</span>
                                <p class="title" v-html="$t('WhyEBC.screen5.list[2].title')"></p>
                                <p class="desc">{{$t('WhyEBC.screen5.list[2].desc')}}</p>
                            </div>
                            <div class="col-md-4">
                                <span>4</span>
                                <p class="title" v-html="$t('WhyEBC.screen5.list[3].title')"></p>
                                <p class="desc">{{$t('WhyEBC.screen5.list[3].desc')}}</p>
                            </div>
                            <div class="col-md-4">
                                <span>5</span>
                                <p class="title" v-html="$t('WhyEBC.screen5.list[4].title')"></p>
                                <p class="desc">{{$t('WhyEBC.screen5.list[4].desc')}}</p>
                            </div>
                            <div class="col-md-4">
                                <span>6</span>
                                <p class="title" v-html="$t('WhyEBC.screen5.list[5].title')"></p>
                                <p class="desc">{{$t('WhyEBC.screen5.list[5].desc')}}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="ta-t-sub3 ta-account-new4">
                    <div class="container">
                        <p class="ta-public-title">{{$t('TradingAccount.screen5.text23')}}</p>
                        <p class="ta-public-line"></p>
                        <p class="ta-public-desc">{{$t('TradingAccount.screen5.text24')}}</p>
                        <div class="row">
                            <div class="col-md-4">
                                <img src="../assets/image/TradingAccount/account-icon-01.svg" alt="">
                                <p class="title">{{$t('TradingAccount.screen2.list[0].title')}}</p>
                                <p class="desc">{{$t('TradingAccount.screen2.list[0].desc')}}</p>
                            </div>
                            <div class="col-md-4">
                                <img src="../assets/image/TradingAccount/account-icon-02.svg" alt="">
                                <p class="title">{{$t('TradingAccount.screen2.list[1].title')}}</p>
                                <p class="desc">{{$t('TradingAccount.screen2.list[1].desc')}}</p>
                            </div>
                            <div class="col-md-4">
                                <img src="../assets/image/TradingAccount/account-icon-03.svg" alt="">
                                <p class="title">{{$t('TradingAccount.screen2.list[2].title')}}</p>
                                <p class="desc">{{$t('TradingAccount.screen2.list[2].desc')}}</p>
                            </div>
                        </div>
                        <div class="btn-box">
                            <a class="ta-public-link2" style="margin-right: 0"  rel="nofollow" :href="khUrl">{{$t('TradingAccount.screen5.register-btn')}}</a>
                        </div>
                    </div>
                </div>



                <div class="ta-account3 ta-account-new5">
                    <div class="container">
                        <p class="ta-public-title">{{$t('TradingAccount.screen5.text25')}}</p>
                        <p class="ta-public-line"></p>
                        <p class="ta-public-desc">{{$t('TradingAccount.screen5.text26')}}</p>
                        <div class="row">
                            <img src="../assets/image/TradingAccount/account-new-mt4.png?t=1719287916" alt="">
                        </div>
                        <a class="ta-public-link2" href="/MT4/">{{$t('TradingAccount.screen5.text27')}}</a>
                    </div>
                </div>


                <page-slogan></page-slogan>
				<new-footer></new-footer>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				khUrl: 'https://client.ebccrm.com/signup',
				mnUrl: 'https://client.ebccrm.com/simulation',
				seoInfo: ""
			}
		},
		metaInfo() {
			return {
				title: this.seoInfo.title || '',
				meta: [ // set meta
					{
						name: 'keyWords',
						content: this.seoInfo.keyWords || ''
					},
					{
						name: 'description',
						content: this.seoInfo.description || ''
					}
				]
			}
		},
		created() {
			this.$getSeoInfo({
				lang: this.$store.state.Lan,
				path: "/TradingAccount"
			}).then(res=>{
				this.seoInfo = res
			})
			let par = localStorage.getItem('advert')
			let lang = localStorage.getItem('language')
			let parms;
			if (lang === 'ct') {
				parms = 'tw'
			} else if (lang === 'ja') {
				parms = 'jp'
			} else if (lang === 'vi') {
				parms = 'zh'
			} else {
				parms = lang
			}
			par = JSON.parse(par)
			if (par != null) {
				this.khUrl = this.khUrl + '?utm_source=' + par.utm_source + '&utm_medium=' + par.utm_medium +
					'&utm_campaign=' + par.utm_campaign + '&utm_term=' + par.utm_term + '&utm_content=' + par.utm_content +
					'&hl=' + parms
			} else {
				this.khUrl = this.khUrl + '?hl=' + parms
			}
			this.mnUrl = this.mnUrl + '?hl=' + parms
		}
	}
</script>

<style>
	@charset "UTF-8";
	.c-footer .fp-overflow{
		display: unset !important;
	}
    .ta-t-sub1{
  background: url(../assets/image/TradingAccount/account-bg-new.jpg) 100%/auto 100% no-repeat;
  height: 266px;
  margin-top: 0px;
  text-align: center;
  padding-top: 110px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.ta-t-sub1 img {
    margin-top: 26px;
    width: 330px;
    margin-bottom: 14px;
}
.ta-t-sub1 .p1{
    color: #FFF;
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    margin-top: 19px;
}
.ta-t-sub1 .p2{
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1px;
    width: 666px;
    margin: 0 auto;
    line-height: 36px;
    opacity: 0.9;
}
.ta-t-sub1 .btn{
    display: block;
    width: max-content;
    padding: 10px 56px;
    height: 46px;
    font-size: 16px;
    border-radius: 2px;
    background: #3D0101;
    color: #AF8147;
    margin: 28px auto;
}
.ta-t-sub1 .btn:hover{
    color: #3D0101;
    background: #AF8147;
}

.ta-public-title{
    color: #3D0101;
    text-align: center;
    font-size: 34px;
    font-weight: 700;
}
.ta-public-line{
    width: 70px;
    border: 1px solid #AF8147;
    margin: 46px auto 36px;
}
.ta-public-title2{
    color: #3D0101;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}
.ta-public-desc{
    width: 70%;
    color: #666666;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    margin: 20px auto 0;
}


.ta-t-sub2{
    background: #F8F8F8;
    padding: 80px 0;
}
.ta-t-sub2 .row{
    background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
    margin: 46px auto;
    padding: 50px 10% 50px 17%;
    width: 1000px;
}
.ta-t-sub2 .row div{
    margin-top: 10px;
}
.ta-t-sub2 .row img{
    width: 30px;
    position: relative;
    top: 7px;
}
.ta-t-sub2 .row p{
    color: #080E1C;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    display: inline;
    line-height: 46px;
    margin-left: 16px;
}


.ta-public-btn{
    display: block;
    width: max-content;
    padding: 13px 40px;
    height: 44px;
    font-size: 16px;
    border-radius: 2px;
    color: #3D0101;
    margin: 50px auto 0px;
    text-align: center;
    border: 1px solid #3D0101;
    border-radius: 2px;
}
.ta-public-btn:hover{
    color: #3D0101;
    background: #AF8147;
    border: 1px solid #AF8147;
}

.btn-box{
    width: max-content;
    margin: auto;
}


.ta-t-sub3{
    background: #ffffff;
    padding: 90px 0 160px;
}
.ta-t-sub3 .row{
    margin: 54px auto;
    width: 1200px;
}
.ta-t-sub3 .row div{
    text-align: center;
}
.ta-t-sub3 .row img{
    width: 65px;
    position: relative;
    top: 26px;
}
.ta-t-sub3 .row img{
    width: 90px;
}
.ta-t-sub3 .row .title{
    color: #080E1C;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 48px;
    margin-top: 22px;
}
.ta-t-sub3 .row .desc{
    color: #080E1C;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 28px;
    padding: 0 10%;
}


.ta-t-sub4{
    background: #F8F8F8;
    padding: 80px 0;
}
.ta-t-sub4 img{
    display: block;
    margin: 40px auto;
    width: 60%;
    background: #fff;
    padding: 30px;
}
.ta-t-sub4 .public-desc{
    width: 60%;
    text-align: left;
}


.ta-t-sub5{
    background: #FFFFFF;
    padding: 80px 0;
}
.ta-t-sub5 .t-sub5-img{
    display: block;
    margin: 40px auto;
    width: 1000px;
}
.ta-t-sub5 .public-desc{
    width: 500px;
}
.ta-t-sub5-content{
    position: relative;
    width: 1000px;
    margin: 0 auto;
}
.ta-t-sub5-bottom{
    position: absolute;
    bottom: 0px;
    height: 80px;
    background: rgba(29, 1, 1, 0.75);
    width: 1000px;
    text-align: center;
}
.ta-t-sub5-bottom p{
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline;
    padding: 0 60px;
    line-height: 74px;
}
.ta-t-sub5-bottom img{
    width: 7px;
    margin-left: 10px;
}
.ta-t-sub5-bottom span{
    font-size: 26px;
    font-weight: bold;
}
.ta-public-slogan{
    padding: 28px 0px;
    background: #F8F8F8;
    color: #3D0101;
    font-size: 16px;
    text-align: center;
    line-height: 30px;
    font-weight: 600;
}

.ta-t-sub1 .btn-ug{
    width: 190px;
}


.ta-t-sub5 .container{
    max-width: 1100px;
}
.ta-t-sub5 .row div{
    text-align: center;
    margin-top: 30px;
    margin-bottom: 40px;
}
.ta-t-sub5 .row img{
    width: 80%;
}
.ta-t-sub5 .row p{
    color: #666666;
    font-size: 16px;
    line-height: 26px;
    width: 90%;
    margin: 30px auto 0;
}

.ta-t-sub6{
    background: #F8F8F8;
    padding: 80px 0;
}
.ta-t-sub6 .container{
    max-width: 1100px;
}
.ta-t-sub6 .row div{
    background: #fff;
    width: auto;
    margin: 10px 2% 20px;
    height: 44px;
    padding: 14px 20px 14px 46px;
    position: relative;
    border-radius: 3px;
}
.ta-t-sub6 .row div img{
    width: 18px;
    position: absolute;
    top: 13px;
    left: 20px;
}
.ta-t-sub6 .row div p{
    float: left;
    font-size: 16px;
}
.ta-t-sub1 .p1 br{
    display: none;
}

.en-style .ta-t-sub1 .p2{
    letter-spacing: 0px;
}
.en-style .ta-t-sub1 .p1{
    margin-top: 80px;
}
.vi-box .ta-t-sub1 .p1{
    margin-top: 40px;
}




.ta-public-link{
    background: #3d0101;
    /* padding: 11px 28px; */
    color: #fff;
    font-size: 16px;
    letter-spacing: 1px;
    display: block;
    width: max-content;
    padding: 15px 29px;
    float: left;
    margin-top: 20px;
}
.ta-public-link img {
    width: 7px;
    margin-left: 12px;
}
.ta-public-link:hover{
  color: #ffffff;
  background: #AF8147;
}

.ta-public-link2{
    padding: 14px 28px;
    color: #3d0101;
    font-size: 16px;
    border: 1px solid #3d0101;
    margin-right: 12px;
    letter-spacing: 1px;
    display: block;
    width: max-content;
    float: left;
    margin-top: 20px;
    font-family: auto;
}
.ta-public-link2 img {
    width: 7px;
    margin-left: 12px;
}
.ta-public-link2:hover{
  color: #ffffff;
  background: #3d0101;
  border: 1px solid #3d0101;
}
.ta-public-link2:hover .arrow1{
  display: none!important;
}
.ta-public-link2:hover .arrow2{
  display: inline-block!important;
}



.en-style2 .ta-t-sub1 .p2{
    letter-spacing: 0px;
}
.en-style2 .ta-t-sub3 .row .title{
    letter-spacing: 0px;
    line-height: 26px;
    margin-top: 32px;
    margin-bottom: 14px;
}
.en-style2 .ta-t-sub3 .row .desc{
    letter-spacing: 0px;
}
.en-style2 .ta-public-link2{
    letter-spacing: 0px;
}
.en-style2 .ta-public-link{
    letter-spacing: 0px;
}

.ta-t-sub1 .btn{
    display: none;
}

.ta-account1{
    padding: 80px 0 170px;
    background: #f0f0f0;
}
.ta-account1 .container{
    width: 1000px;
    text-align: center;
}
.ta-account1 .row .title{
    font-size: 28px;
    color: #080E1C;
    font-weight: 700;
    margin-bottom: 30px;
    letter-spacing: 1px;
}
.ta-account1 .row .title2{
    color: #3D0101;
}
.ta-account1 .row .desc1,.ta-account1 .row .desc2,.ta-account1 .row .desc3{
    font-size: 17px;
    line-height: 50px;
    letter-spacing: 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ta-account1 .row .desc1{
    color: #949699;
}
.ta-account1 .row .desc2{
    color: #080E1C;
}
.ta-account1 .row .desc3{
    color: #080E1C;
}
.ta-account1 .public-link2{
    margin-right: 0px;
    margin-top: 40px;
}
.ta-account2{
    padding: 100px 0 80px;
    background: #f8f8f8;
}

.ta-account2 .row img{
    width: 80%;
    margin: 40px auto 40px;
}

.ta-account2 .ta-public-desc{
    color: #949699;
    font-size: 18px;
}

.ta-account2 .ta-public-link2{
    float: unset;
    margin: 0 auto;
}

.ta-account3{
    padding: 100px 0 80px;
}

.ta-account3 .row img{
    width: 80%;
    margin: 40px auto 40px;
}

.ta-account3 .ta-public-desc{
    color: #949699;
    font-size: 18px;
}

.ta-account3 .ta-public-link2{
    float: unset;
    margin: 0 auto;
}

.en-style2 .ta-t-sub1 .p2{
    letter-spacing: 0px;
}
.en-style2 .ta-account1 .row .desc1, .en-style2 .ta-account1 .row .desc2, .en-style2 .ta-account1 .row .desc3{
    letter-spacing: 0px;
}
.en-style2 .ta-account1 .row .title{
    letter-spacing: 0px;
    font-size: 24px;
}
.en-style2 .ta-public-desc{
    width: 80%;
}
.en-style2 .ta-public-title{
    padding: 0 10%;
    line-height: 38px;
}



.ta-account-new1{
    padding: 80px 0;
    background: #F8F8F8;
}
.ta-account-new1 .ta-public-line{
    width: 42px;
    margin: 26px auto 20px;
}
.ta-account-new1 .ta-public-desc{
    font-family: auto;
    font-size: 14px;
    color: #999999;
    margin-bottom: 10px;
}
.ta-account-new1 .account-type{
    border: 1.5px solid rgba(175, 129, 71, 0.50);
    border-radius: 5px;
    background: #FFF;
    margin-top: 30px;
    padding: 50px 0 28px;
}
.ta-account-new1 .account-type2{
    border: 1.5px solid #8D0101;
}
.ta-account-new1 .row{
    width: 100%;
    margin: 0 auto;
}
.ta-account-new1 .li1{
    width: 40%;
    position: relative;
    float: left;
    margin-top: 28px;
}
.ta-account-new1 .li1 .p1{
    font-weight: 900;
    color: #AF8147;
    letter-spacing: 1px;
    font-size: 36px;
    text-align: center;
}
.ta-account-new1 .account-type2 .li1 .p1{
    color: #8D0101;
}
.ta-account-new1 .li1 .p2{
    font-family: auto;
    font-size: 16px;
    color: #333;
    text-align: center;
    margin-top: 12px;
}
.ta-account-new1 .li1 img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 346px;
}
.ta-account-new1 .li2{
    width: 60%;
    float: left;
    position: relative;
}
.ta-account-new1 .li2 p{
    color: #333;
    font-size: 15px;
    margin: 0px 0px 26px;
    font-family: auto;
    position: relative;
    background: url(../assets/image/TradingAccount/account-new-icon1.svg);
    background-size: 20px;
    background-repeat: no-repeat;
    padding-left: 33px;
    background-position: center left;
}
.ta-account-new1 .account-type2 .li2 p{
    background: url(../assets/image/TradingAccount/account-new-icon2.svg);
    background-size: 20px;
    background-repeat: no-repeat;
    padding-left: 33px;
    background-position: center left;
}
.ta-account-new1 .li2 p i{
    font-weight: bold;
    color: #AF8147;
    font-size: 19px;
}
.ta-account-new1 .account-type2 .li2 p i{
    color: #8D0101;
}
.ta-account-new1 .li2 img{
    width: 18px;
    margin-right: 8px;
    position: relative;
    top: 3px;
    left: 0;
}
.ta-account-new1 .li2 a{
    color: #4D0101;
    font-family: auto;
    padding: 14px 28px;
    font-size: 16px;
    border: 1.5px solid #4D0101;
    position: absolute;
    top: 43%;
    right: 30px;
    transform: translate(-50%, -50%);
}
.ta-account-new1 .li2 a:hover{
    background: #3D0101;
    color: #fff;
}

.ta-account-new2{
    padding: 80px 0;
    background: #F0F0F0;
}
.ta-account-new2 .ta-public-line{
    width: 42px;
    margin: 26px auto 20px;
}
.ta-account-new2 .ta-public-desc{
    font-family: auto;
    font-size: 14px;
    color: #999999;
    margin-bottom: 36px;
}
.ta-account-new2 .row .title{
    background: #4D0101;
    color: #fff;
    font-size: 18px;
    padding: 14px;
    margin-bottom: 0;
}
.ta-account-new2 .col-md-4{
    padding: 0;
}

.ta-account-new2 .desc1:nth-child(even),.ta-account-new2 .desc2:nth-child(even),.ta-account-new2 .desc3:nth-child(even) {
    background: #FBF6F1;
}
.ta-account-new2 .desc1:nth-child(odd),.ta-account-new2 .desc2:nth-child(odd),.ta-account-new2 .desc3:nth-child(odd) {
    background: #ffffff;
}
.ta-account-new2 .desc1{
    color: #666;
    font-family: auto;
}
.ta-account-new2 .desc2,.ta-account-new2 .desc3{
    color: #333;
    font-family: auto;
    font-weight: bold;
}
.ta-account-new2 .row .desc1, .ta-account-new2 .row .desc2, .ta-account-new2 .row .desc3{
    font-size: 15px;
    text-align: left;
    padding-left: 20%;
}
.ta-account-new2 .row .title{
    text-align: left;
    padding-left: 20%;
}
.ta-account-new2 p img{
    width: 14px;
}
.ta-account-new2 .desc1:last-child,.ta-account-new2 .desc2:last-child,.ta-account-new2 .desc3:last-child {
    padding: 20px 0 20px 20%; 
}
.ta-account-new2 p a{
    color: #4D0101;
    font-family: auto;
    padding: 14px 28px;
    font-size: 15px;
    border: 1.5px solid #4D0101;
    font-weight: normal;
}
.ta-account-new2 p a:hover{
    background: #3D0101;
    color: #fff;
}

.ta-account-new3{
  padding: 80px 0 40px;
  text-align: center;
  background: #F8F8F8;
}
.ta-account-new3 .ta-public-title{
    color: #333;
}
.ta-account-new3 .ta-public-line{
    width: 42px;
    margin: 26px auto 20px;
}
.ta-account-new3 .ta-public-desc{
    font-family: auto;
    font-size: 14px;
    color: #999999;
    margin-bottom: 40px;
}
.ta-account-new3 .row div{
  position: relative;
  text-align: center;
  margin: 80px 0 90px;
}
.ta-account-new3 .row span{
  font-size: 270px;
  font-family: 'Baskerville Old Face';
  font-weight: 400;
  text-align: center;
  color: #949699;
  opacity: 0.15;
  position: absolute;
  top: 42px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ta-account-new3 .row .title{
  color: #4D0101;
  font-weight: 700;
  font-size: 48px;
}

.ta-account-new3 .row .desc{
  color: #666;
  font-size: 16px;
  line-height: 30px;
  margin-top: 20px;
  letter-spacing: 2px;
  padding: 0 8%;
  font-family: auto;
}
.ta-account-new3 .row .title i{
  font-size: 30px;
}

.ta-account-new4{
    padding: 80px 0 140px;
    background: #f0f0f0;
}
.ta-account-new4 .ta-public-title{
    color: #333;
}
.account-new4 .ta-public-line{
    width: 42px;
    margin: 26px auto 20px;
}
.ta-account-new4 .ta-public-desc{
    font-family: auto;
    font-size: 14px;
    color: #999999;
    margin-bottom: 10px;
}
.ta-account-new4 .row .title{
    font-family: auto;
    font-size: 20px;
}
.ta-account-new4 .desc{
    font-family: auto;
}

.ta-account-new5{
    padding: 80px 0 60px;
    background: #F7F7F7;
}
.ta-account-new5 .ta-public-title{
    color: #333;
}
.ta-account-new5 .ta-public-line{
    width: 42px;
    margin: 26px auto 20px;
}
.ta-account-new5 .ta-public-desc{
    font-family: auto;
    font-size: 14px;
    color: #999999;
    margin-bottom: 10px;
}
.ta-account-new5 .row img{
    width: 55%;
}

.en-account-page .ta-account-new1 .li2 {
    width: 40%;
    float: left;
    position: relative;
}
.en-account-page .ta-account-new1 .li1 img{
    width: 52%;
    top: 48%;
}
.en-account-page .ta-account-new1 .li1 .p1{
    letter-spacing: 0px;
    font-size: 30px;
}
.en-account-page .ta-account-new1 .li1{
    padding: 0 3%;
}
.en-account-page .ta-account-new1 .li1 .p2{
    line-height: 22px;
}
.en-account-page .ta-account-new1 .account-type .li2 p{
    line-height: 22px;
}
.en-account-page .ta-account-new1 .li2 a{
    right: -54%;
}
.en-account-page .ta-public-desc{
    font-size: 16px;
    line-height: 26px;
}
.en-account-page .ta-account1 .container{
    width: 1120px;
}
.en-account-page .ta-account-new2 .row .title{
    letter-spacing: 0px;
}
.en-account-page .ta-account1 .row .desc1, .en-account-page .ta-account1 .row .desc2, .en-account-page .ta-account1 .row .desc3{
    letter-spacing: 0px;
}
.en-account-page .ta-account-new2 p a{
    padding: 12px 22px;
}
.en-account-page .ta-account-new3 .row .desc{
    letter-spacing: 0px;
    line-height: 24px;
}
.en-account-page .ta-account-new4 .row .title{
    letter-spacing: 0px;
    line-height: 30px;
    margin-top: 40px;
    font-family: 'Noto Serif SC';
}
.en-account-page .ta-t-sub3 .row .desc{
    margin-top: 15px;
    line-height: 26px;
    letter-spacing: 0px;
}
.en-account-page .ta-public-link2{
    letter-spacing: 0px;
}




@media(max-width:1400px) {
    .ta-t-sub1 .p1{
        font-size: 34px;
    }
    .ta-t-sub1 .p2{
        line-height: 32px;
        font-size: 16px;
        width: 600px;
    }
    .ta-t-sub4 img{
        width: 700px;
    }
    .ta-t-sub5 .t-sub5-img{
        width: 700px;
    }
    .ta-t-sub5-bottom{
        width: 700px;
        height: 68px;
    }
    

    .ta-account-new1 .li2 a{
        right: -16px;
    }
    .ta-account-new1 .li1 img{
        width: 66%;
    }
    .ta-account-new1 .li1 .p1{
        font-size: 30px;
    }
    .ta-account-new1 .account-type{
        padding: 50px 0 28px;
    }

    .ta-t-sub3 .row{
        width: auto;
    }
    .en-account-page .ta-account-new1 .li2 a{
        right: -59%;
    }
}


@media(max-width:995px) {
    .ta-t-sub1 .p1 br{
        display: block !important;
    }
    .ta-t-sub2 .row{
        width: 90%;
        padding: 20px 0% 0px 13%;
        margin: 30px auto;
    }
    .ta-t-sub2 .row p{
        font-size: 14px;
    }
    .ta-t-sub2 .row div{
        margin-top: 0px;
    }
    .ta-t-sub3 .row{
        width: 100%;
        padding: 19px 0% 0px 0%;
        margin: 10px auto 30px;
        background: #ffffff;
    }
    .ta-t-sub3 .row div{
        width: 50%;
        margin-bottom: 30px;
    }
    .ta-t-sub3 .row img{
        width: 90px;
    }
    .ta-public-link2{
        padding: 14px 18px;
        margin-top: 0px;
        text-align: center;
        width: 100%;
    }
    .ta-public-link{
        padding: 15px 19px;
        margin-top: 0px;
        width: 100%;
        text-align: center;
    }
    .ta-t-sub3 .row .desc{
        font-size: 15px;
        padding: 0 0%;
        line-height: 26px;
    }
    .ta-t-sub1 .p2{
        width: 100%;
        padding: 8%;
        font-size: 16px;
        line-height: 30px;
    }
    .ta-t-sub1 .p1{
        margin-top: 20px;
        line-height: 58px;
        font-size: 34px;
    }
    .ta-t-sub1 .btn{
        margin: 14px auto;
    }
    .ta-public-desc{
        width: 90%;
        font-size: 15px;
        line-height: 24px;
    }
    .ta-t-sub4 .public-desc,.ta-t-sub5 .public-desc{
        width: 90%;
    }
    .ta-t-sub2,.ta-t-sub3,.ta-t-sub4,.ta-t-sub5{
        padding: 56px 0;
    }
    .ta-public-line{
        width: 50px;
        margin: 40px auto 0px;
    }
    .ta-public-title{
        font-size: 26px;
    }
    .ta-t-sub2 .row img{
        width: 28px;
    }
    .ta-t-sub4 img{
        width: 90%;
    }
    .ta-public-slogan{
        padding: 20px 20px;
        line-height: 28px;
    }
    .ta-public-title2{
        line-height: 26px;
    }
    .ta-t-sub1 .p1 br{
        display: block;
    }
    .ta-t-sub4 img{
        padding: 0;
        background: none;
        margin: 0 auto;
        width: 96%;
    }
    .ta-public-btn{
        margin: 40px auto 0px;
    }
    .ta-t-sub4 .public-desc{
        margin-top: 30px;
    }
    .ta-t-sub5 .row div{
        width: 50%;
        margin-bottom: 0px;
    }
    .ta-t-sub5 .row p{
        font-size: 14px;
        width: 82%;
        margin: 20px auto 0;
        line-height: 22px;
    }
    .ta-t-sub5 .row img{
        width: 88%;
    }
    .ta-t-sub6 .row div p{
        font-size: 14px;
    }
    .ta-t-sub6 .row div{
        margin-bottom: 6px;
        margin-left: 4%;
        padding: 16px 10px 14px 36px;
    }
    .ta-t-sub6 .row div img{
        top: 13px;
        left: 12px;
    }
    .ta-t-sub6{
        padding-bottom: 50px;
    }
    .en-style .ta-t-sub6 .row div{
        width: 92%;
        line-height: 22px;
        height: auto;
        padding: 14px 10px 12px 36px;
    }
    .en-style .ta-t-sub6 .row div p{
        font-size: 14px;
    }
    .en-style .ta-t-sub6 .row div img{
        top: 18px;
        left: 12px;
        width: 13px;
    }
    .en-style .ta-public-title{
        font-size: 23px;
    }
    .en-style .ta-t-sub1 .p1{
        line-height: 46px;
        font-size: 30px;
        margin-top: 55px;
    }
    .en-style2 .ta-public-link2{
        float: unset;
        margin-bottom: 16px;
    }
    .en-style2 .ta-public-link{
        float: unset;
    }
    .en-style2 .ta-t-sub3 .row .title{
        line-height: 24px;
    }
    .en-style2 .ta-t-sub3 .row .desc{
        margin-top: 14px;
    }
    .en-style2 .ta-public-title{
        padding: 0 8%;
        line-height: 32px;
    }
    .ta-t-sub1 .btn{
        display: block;
    }
    .ta-t-sub1 {
        background: url(../assets/image/TradingAccount/account-bg-new-m.jpg) 100%/auto 100% no-repeat;
        height: 520px;
        margin-top: 0px;
        text-align: center;
        padding-top: 110px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
    .ta-t-sub1 .p1{
        font-size: 27px;
    }
    .ta-account1{
        padding: 50px 2% 50px;
        background: #ffffff;
    }
    .ta-account1 .container{
        width: 100%;
    }
    .ta-account1 .row div{
        width: 33.3%;
    }
    .ta-account1 .row .title{
        font-size: 17px;
        letter-spacing: 0px;
        white-space: nowrap;
        max-width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 20px;
    }
    .ta-account1 .row .desc1, .ta-account1 .row .desc2, .ta-account1 .row .desc3{
        font-size: 14px;
        line-height: 20px;
        margin-top: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        letter-spacing: 0px;
    }
    .ta-account1 .row .div1 {
        margin-top: 22px;
        text-align: right;
    }
    .ta-account1 .btn-box{
        display: none;
    }
    .ta-t-sub3{
        background: #f8f8f8;
    }
    .ta-t-sub3 .row{
        background: #f8f8f8;
    }
    .ta-t-sub3 .row div{
        width: 100%;
    }
    .ta-public-link, .ta-public-link2{
        width: 70%;
        text-align: center;
        margin: 20px auto 10px;
        float: unset;
    }
    .ta-account2{
        background: #ffffff;
        padding: 60px 3%;
    }
    .ta-account2 .ta-public-desc{
        width: 100% !important;
        font-size: 16px;
        line-height: 24px;
    }
    .ta-account2 .row img{
        width: 100%;
        margin: 20px auto 30px;
    }
    .ta-account3{
        padding: 60px 3%;
        background: #f8f8f8;
    }
    .ta-account3 .ta-public-desc{
        width: 100% !important;
        font-size: 16px;
        line-height: 24px;
    }
    .ta-account3 .row img{
        width: 100%;
    }
    .ta-public-line{
        margin: 30px auto 30px;
    }
    .ta-t-sub3 .ta-public-line{
        margin-bottom: 0px;
    }
    .en-style2 .ta-t-sub1 .p2{
        padding-top: 14px;
    }
    .en-style2 .ta-account1 .row .title{
        font-size: 16px;
    }
    .en-style2 .ta-public-title{
        font-size: 24px;
    }
    .en-style2 .ta-t-sub3 .row .desc{
        padding: 0 10%;
    }


    .ta-account-new0{
        background: url(../assets/image/TradingAccount/account-bg-new-m.jpg) 100% / auto 100% no-repeat;
        height: 210px;
        margin-top: 0px;
        text-align: center;
        padding-top: 70px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
    .ta-account-new0 img{
        width: 60%;
        margin-top: 8px;
    }
    .ta-account-new1{
        padding: 60px 4%;
    }
    .ta-account-new1 .account-type{
        padding: 20px 5% 20px;
    }
    .ta-account-new1 .li1{
        width: 100%;
    }
    .ta-account-new1 .li2{
        width: 100%;
        margin-top: 30px;
    }
    .ta-account-new1 .li2 a{
        position: unset;
        width: 100%;
        margin-top: 12px;
        display: inline-block;
        transform: none;
        text-align: center;
        background: #3D0101;
        color: #ffffff;
        margin-bottom: 18px;
    }
    .ta-account-new1 .li1 .p1{
        font-size: 24px;
        margin-top: 12px;
    }
    .ta-account-new1 .li1 img{
        width: 50%;
        top: 42%;
    }
    .ta-account-new1 .li1 .p2{
        font-size: 14px;
        margin-top: 10px;
    }
    .ta-account-new1 .li2 p{
        font-size: 14px;
        margin: 0px 0px 16px;
        line-height: 24px;
    }
    .ta-account-new2{
        padding: 60px 5px;
        background: #F0F0F0;
    }
    .ta-account-new2 .row .div1{
        margin-top: 0;
        text-align: left;
    }
    .ta-account1 .row .title{
        max-width: 100%;
        font-size: 14px;
        margin-bottom: 0;
        padding-left: 12%;
    }
    .ta-account1 .row .desc1, .ta-account1 .row .desc2, .ta-account1 .row .desc3{
        margin-top: 0px;
        padding: 10px 0 10px 12%;
    }
    .ta-account-new2 .ta-public-desc{
        margin-bottom: 24px;
    }
    .ta-account-new2 .desc1:last-child, .ta-account-new2 .desc2:last-child, .ta-account-new2 .desc3:last-child{
        padding: 20px 0 20px 12%;
        display: none;
    }
    .ta-account-new2 p a{
        padding: 6px 16px;
    }

    .ta-account-new3{
      padding: 60px 3% 30px;
    }
    .ta-account-new3 .row div{
      width: 50%;
      margin: 60px 0 50px;
    }
    .ta-account-new3 .row span{
      font-size: 210px;
      top: 38px;
    }
    .ta-account-new3 .row .title{
      font-size: 32px;
    }
    .ta-account-new3 .row .desc{
      padding: 0 6%;
      margin-top: 10px;
      line-height: 24px;
      font-size: 14px;
    }
    .ta-account-new4{
        padding: 60px 0 60px;
        background: #f0f0f0;
    }
    .ta-account-new4 .row{
        background: #f0f0f0;
    }
    .ta-account-new4 a{
        margin: 0 auto;
        float: unset;
        display: inline-block;
        width: 100%;
        background: #3D0101;
        color: #fff;
    }
    .ta-account-new4 .btn-box{
        width: 86%;
    }
    .ta-account-new5 .public-desc {
        font-family: auto;
        font-size: 14px;
        color: #999999;
        margin-bottom: 10px;
    }
    .ta-account-new5 a{
        background: #3D0101;
        color: #fff;
    }

    .en-account-page .ta-account1 .container{
        width: 100%;
    }
    .en-account-page .ta-account-new1 .li2{
        width: 100% !important;
    }
    .en-account-page .ta-account-new1 .li1 .p1{
        font-size: 24px;
    }
    .en-account-page .ta-public-desc{
        font-size: 14px;
        line-height: 20px;
    }
    .en-account-page .ta-account-new3 .row .desc{
        line-height: 22px;
    }
    .en-account-page .ta-public-title{
        font-size: 24px;
        line-height: 32px;
    }
}
@media(max-width:400px) {
    .ta-t-sub6 .row div p{
        font-size: 13px;
    }
    .ta-t-sub6 .row div{
        margin-bottom: 6px;
        margin-left: 4%;
        padding: 14px 10px 12px 34px;
        height: 38px;
    }
    .ta-t-sub6 .row div img{
        top: 13px;
        left: 12px;
        width: 14px;
    }
    .en-style .ta-t-sub6 .row div{
        width: 92%;
        line-height: 22px;
        height: auto;
    }
    .en-style .ta-t-sub6 .row div p{
        font-size: 14px;
    }
}

@media(max-width:390px) {
    .ta-account1 .row .desc1, .ta-account1 .row .desc2, .ta-account1 .row .desc3{
        font-size: 13px;
    }
}
</style>