<template>
	<div class="from0tohero">
		<ss-header mode="Home"  bg="rgb(38, 5, 5)"></ss-header>
		<full-page :options="options" ref="fullpage">
			<div class="from0tohero-pages1">
				<div class="pages1-box flex-ajcenter flex">
					<div class="pages1-box__tit">
						{{$t('from0tohero.pages1.title')}}
					</div>
					<img src="@/assets/image/from0tohero/icon-line.png" alt="" class="pages1-box__img">
					<div class="pages1-box__tips">
						{{$t('from0tohero.pages1.tips')}}
					</div>
					<div class="pages1-box__en">
						From Zero To Hero
					</div>
				</div>
				<div class="pages1-menu flex flex-ajcenter" id="menuId" :style="{top:topNum + 'px'}">
					<div class="pages1-menu__item flex flex-ajcenter" @click="onClick(2)"
						:class="{'active':pagIndex == 0}">
						{{$t('from0tohero.pages1.text')}}
					</div>
					<div class="pages1-menu__item flex flex-ajcenter" @click="onClick(3)"
						:class="{'active':pagIndex == 1}">
						{{$t('from0tohero.pages1.text1')}}
					</div>
					<div class="pages1-menu__item flex flex-ajcenter" @click="onClick(4)"
						:class="{'active':pagIndex == 2}">
						{{$t('from0tohero.pages1.text2')}}
					</div>
					<div class="pages1-menu__item flex flex-ajcenter" @click="onClick(5)"
						:class="{'active':pagIndex == 3}">
						{{$t('from0tohero.pages1.text3')}}
					</div>
					<a class="pages1-menu__item flex flex-ajcenter" href="https://ebcfinsns.co/login" target="_blank">
						{{$t('from0tohero.pages1.text4')}}<img src="@/assets/image/from0tohero/rihgt-icon.png" alt="">
					</a>
				</div>
			</div>
			<div class="from0tohero-pages2 section">
				<div class="container">
					<div class="from0tohero-title">
						<div class="from0tohero-title__name">
							{{$t('from0tohero.pages2.title')}}
						</div>
						<div class="from0tohero-title__line">

						</div>
					</div>
					<div class="pages2-box flex">
						<div class="pages2-box__item flex-1" v-for="(item,index) in $t('from0tohero.pages2.list')">
							<div class="item-tit" v-html="item.tit">
							</div>
							<div class="item-tips" v-html="item.tips">
							</div>
						</div>
					</div>
					<div class="from0tohero-btn">
						<a class="from0tohero-btn__box" href="https://ebcfinsns.co/login" target="_blank">
							<div class="from0tohero-btn__box__name">
								{{$t('from0tohero.pages2.btn')}}
							</div>
							<img src="@/assets/image/from0tohero/rihgt-icon.png" alt="">
						</a>
					</div>
				</div>
			</div>
			<div class="from0tohero-pages3 section">
				<div class="container">
					<div class="from0tohero-title">
						<div class="from0tohero-title__name">
							{{$t('from0tohero.pages3.title')}}
						</div>
						<div class="from0tohero-title__line">

						</div>
					</div>
					<div class="pages3-box flex">
						<div class="pages3-box__item flex-1" v-for="(item,index) in infoData" :key="index"
							v-if="index<4">
							<div class="item-info flex">
								<div class="item-info__img">
									<Avatar :username="item.signalName" background-color="none" color="#fff" :size="80"
										style=" vertical-align: middle;" :inline="true">
									</Avatar>
								</div>
								<div class="item-info__name">
									{{item.signalName}}
								</div>
								<div class="item-info__tips">
									EBC Trader
								</div>
							</div>
							<div class="item-growth flex">
								<div class="item-growth__num">
									{{(item.growth*100).toFixed(2)}}%
								</div>
								<div class="item-growth__tit">
									{{$t('from0tohero.pages3.text')}}
								</div>
							</div>
							<div class="item-desc flex">
								<div class="item-desc__pag flex-1 flex">
									<div class="item-desc__pag__num">
										{{(item.drawdown*100).toFixed(2)}}%
									</div>
									<div class="item-desc__pag__tit">
										{{$t('from0tohero.pages3.text1')}}
									</div>
								</div>
								<div class="item-desc__pag flex-1 flex">
									<div class="item-desc__pag__num">
										{{item.sharpRatio.toFixed(2)}}
									</div>
									<div class="item-desc__pag__tit">
										{{$t('from0tohero.pages3.text2')}}
									</div>
								</div>
								<div class="item-desc__pag flex-1 flex">
									<div class="item-desc__pag__num">
										{{(item.plr*100).toFixed(2)}}%
									</div>
									<div class="item-desc__pag__tit">
										{{$t('from0tohero.pages3.text3')}}
									</div>
								</div>
							</div>
							<div class="item-bot flex">
								<a class="item-bot__btn flex-1" href="https://ebcfinsns.co/login" target="_blank">
									{{$t('from0tohero.pages3.text4')}}
								</a>
								<a class="item-bot__btn flex-1" href="https://ebcfinsns.co/login" target="_blank">
									{{$t('from0tohero.pages3.text5')}}
								</a>
							</div>
						</div>
					</div>
					<div class="from0tohero-btn">
						<a class="from0tohero-btn__box" href="https://ebcfinsns.co/login" target="_blank">
							<div class="from0tohero-btn__box__name">
								{{$t('from0tohero.pages3.btn')}}
							</div>
							<img src="@/assets/image/from0tohero/rihgt-icon.png" alt="">
						</a>
					</div>
				</div>
			</div>
			<div class="from0tohero-pages4 section">
				<div class="container">
					<div class="from0tohero-title">
						<div class="from0tohero-title__name">
							{{$t('from0tohero.pages4.title')}}
						</div>
						<div class="from0tohero-title__line">

						</div>
					</div>
					<div class="pages4-box flex">
						<div class="pages4-box__item flex-1">
							<div class="item-tit">
								{{$t('from0tohero.pages4.num')}}
							</div>
							<div class="item-img">
								<img src="@/assets/image/from0tohero/icon-1.png" alt="">
							</div>
							<div class="item-pag">
								<div class="flex-1">
									{{$t('from0tohero.pages4.text1')}}
								</div>
								>$20,000
							</div>
							<div class="item-pag">
								<div class="flex-1">
									{{$t('from0tohero.pages4.text2')}}
								</div>
								≥10
							</div>
							<div class="item-name">
								<img src="@/assets/image/from0tohero/icon-xx.png" alt="">
								{{$t('from0tohero.pages4.name')}}
								<img src="@/assets/image/from0tohero/icon-xx.png" alt="">
							</div>
						</div>
						<div class="pages4-box__item flex-1">
							<div class="item-tit">
								{{$t('from0tohero.pages4.num1')}}
							</div>
							<div class="item-img">
								<img src="@/assets/image/from0tohero/icon-2.png" alt="">
							</div>
							<div class="item-pag">
								<div class="flex-1">
									{{$t('from0tohero.pages4.text1')}}
								</div>
								>$50,000
							</div>
							<div class="item-pag">
								<div class="flex-1">
									{{$t('from0tohero.pages4.text2')}}
								</div>
								>30
							</div>
							<div class="item-name">
								<img src="@/assets/image/from0tohero/icon-xx.png" alt="">
								{{$t('from0tohero.pages4.name1')}}
								<img src="@/assets/image/from0tohero/icon-xx.png" alt="">
							</div>
						</div>
						<div class="pages4-box__item flex-1">
							<div class="item-tit">
								{{$t('from0tohero.pages4.num2')}}
							</div>
							<div class="item-img">
								<img src="@/assets/image/from0tohero/icon-3.png" alt="">
							</div>
							<div class="item-pag">
								<div class="flex-1">
									{{$t('from0tohero.pages4.text1')}}
								</div>
								>$100,000
							</div>
							<div class="item-pag">
								<div class="flex-1">
									{{$t('from0tohero.pages4.text2')}}
								</div>
								>50
							</div>
							<div class="item-name">
								<img src="@/assets/image/from0tohero/icon-xx.png" alt="">
								{{$t('from0tohero.pages4.name2')}}
								<img src="@/assets/image/from0tohero/icon-xx.png" alt="">
							</div>
						</div>
						<div class="pages4-box__item flex-1">
							<div class="item-tit">
								{{$t('from0tohero.pages4.num3')}}
							</div>
							<div class="item-img">
								<img src="@/assets/image/from0tohero/icon-4.png" alt="">
							</div>
							<div class="item-pag">
								<div class="flex-1">
									{{$t('from0tohero.pages4.text1')}}
								</div>
								>$500,000
							</div>
							<div class="item-pag">
								<div class="flex-1">
									{{$t('from0tohero.pages4.text2')}}
								</div>
								>100
							</div>
							<div class="item-name">
								<img src="@/assets/image/from0tohero/icon-xx.png" alt="">
								{{$t('from0tohero.pages4.name3')}}
								<img src="@/assets/image/from0tohero/icon-xx.png" alt="">
							</div>
						</div>
						<div class="pages4-box__item flex-1">
							<div class="item-tit">
								{{$t('from0tohero.pages4.num4')}}
							</div>
							<div class="item-tips">
								{{$t('from0tohero.pages4.tips')}}
							</div>
							<div class="item-img">
								<img src="@/assets/image/from0tohero/icon-5.png" alt="">
							</div>
							<div class="item-pag">
								<div class="flex-1">
									{{$t('from0tohero.pages4.text1')}}
								</div>
								>$1,000,000
							</div>
							<div class="item-pag">
								<div class="flex-1">
									{{$t('from0tohero.pages4.text2')}}
								</div>
								>200
							</div>
							<div class="item-name">
								<img src="@/assets/image/from0tohero/icon-xx-5.png" alt="">
								{{$t('from0tohero.pages4.name4')}}
								<img src="@/assets/image/from0tohero/icon-xx-5.png" alt="">
							</div>
						</div>
					</div>
					<div class="from0tohero-btn">
						<a class="from0tohero-btn__box" href="https://ebcfinsns.co/login" target="_blank">
							<div class="from0tohero-btn__box__name">
								{{$t('from0tohero.pages4.btn')}}
							</div>
							<img src="@/assets/image/from0tohero/rihgt-icon.png" alt="">
						</a>
					</div>
				</div>
			</div>
			<div class="from0tohero-pages5 section">
				<div class="container">
					<div class="from0tohero-title">
						<div class="from0tohero-title__name">
							{{$t('from0tohero.pages5.title')}}
						</div>
						<div class="from0tohero-title__line">

						</div>
					</div>
					<div class="pages5-box">
						<div class="pages5-box__title flex">
							<div class="pages5-box__title__pag flex-1">
								{{$t('from0tohero.pages5.title')}}
							</div>
							<div class="pages5-box__title__pag flex-1">
								{{$t('from0tohero.pages4.name1')}}
							</div>
							<div class="pages5-box__title__pag flex-1">
								{{$t('from0tohero.pages4.name2')}}
							</div>
							<div class="pages5-box__title__pag flex-1">
								{{$t('from0tohero.pages4.name3')}}
							</div>
							<div class="pages5-box__title__pag flex-1">
								{{$t('from0tohero.pages4.name4')}}
							</div>
						</div>
						<div class="pages5-box__desc flex">
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								{{$t('from0tohero.pages5.text')}}
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-yes.png" alt="">
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-yes.png" alt="">
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-yes.png" alt="">
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-yes.png" alt="">
							</div>
						</div>
						<div class="pages5-box__desc flex">
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								{{$t('from0tohero.pages5.text1')}}
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-yes.png" alt="">
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-yes.png" alt="">
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-yes.png" alt="">
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-yes.png" alt="">
							</div>
						</div>
						<div class="pages5-box__desc flex">
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								{{$t('from0tohero.pages5.text2')}}
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-no.png" alt="">
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-yes.png" alt="">
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-yes.png" alt="">
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-yes.png" alt="">
							</div>
						</div>
						<div class="pages5-box__desc flex">
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								{{$t('from0tohero.pages5.text3')}}
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-no.png" alt="">
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-yes.png" alt="">
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-yes.png" alt="">
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-yes.png" alt="">
							</div>
						</div>
						<div class="pages5-box__desc flex">
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								{{$t('from0tohero.pages5.text4')}}
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-no.png" alt="">
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-yes.png" alt="">
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-yes.png" alt="">
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-yes.png" alt="">
							</div>
						</div>
						<div class="pages5-box__desc flex">
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								{{$t('from0tohero.pages5.text5')}}
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-no.png" alt="">
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-yes.png" alt="">
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-yes.png" alt="">
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-yes.png" alt="">
							</div>
						</div>
						<div class="pages5-box__desc flex">
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								{{$t('from0tohero.pages5.text6')}}
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-no.png" alt="">
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-no.png" alt="">
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-yes.png" alt="">
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-yes.png" alt="">
							</div>
						</div>
						<div class="pages5-box__desc flex">
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								{{$t('from0tohero.pages5.text7')}}
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-no.png" alt="">
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-no.png" alt="">
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-yes.png" alt="">
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-yes.png" alt="">
							</div>
						</div>
						<div class="pages5-box__desc flex">
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								{{$t('from0tohero.pages5.text8')}}
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-no.png" alt="">
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-no.png" alt="">
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-no.png" alt="">
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-yes.png" alt="">
							</div>
						</div>
						<div class="pages5-box__desc flex">
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								{{$t('from0tohero.pages5.text9')}}
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-no.png" alt="">
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-no.png" alt="">
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-no.png" alt="">
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-yes.png" alt="">
							</div>
						</div>
						<div class="pages5-box__desc flex">
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1"
								v-html="$t('from0tohero.pages5.text10')">

							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-no.png" alt="">
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-no.png" alt="">
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-no.png" alt="">
							</div>
							<div class="pages5-box__desc__pag flex flex-ajcenter flex-1">
								<img src="@/assets/image/from0tohero/icon-yes.png" alt="">
							</div>
						</div>
					</div>
					<div class="from0tohero-btn">
						<a class="from0tohero-btn__box" href="https://ebcfinsns.co/login" target="_blank">
							<div class="from0tohero-btn__box__name">
								{{$t('from0tohero.pages5.btn')}}
							</div>
							<img src="@/assets/image/from0tohero/rihgt-icon.png" alt="">
						</a>
					</div>
				</div>
			</div>
		</full-page>
		<ss-contact-us mode="all"></ss-contact-us>
		<new-footer></new-footer>
	</div>
</template>
<script>
	import Avatar from 'vue-avatar'
	export default {
		components: {
			Avatar
		},
		metaInfo() {
			return {
				title: this.seoInfo.title || '',
				meta: [ // set meta
					{
						name: 'keyWords',
						content: this.seoInfo.keyWords || ''
					},
					{
						name: 'description',
						content: this.seoInfo.description || ''
					}
				]
			}
		},
		data() {
			return {
				seoInfo: "",
				infoData: "",
				topNum: 295,
				pagIndex: 0,
				options: {
					licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
					//是否显示导航，默认为false
					afterLoad: this.afterLoad,
					autoScrolling: false,
					controlArrows: false,
					fitToSection: false,
					autoScrolling: false
				},
			}
		},
		created() {
			this.$getSeoInfo({
				lang: this.$store.state.Lan,
				path: "/from0tohero"
			}).then(res => {
				this.seoInfo = res
			})
			this.getData()
		},
		mounted() {
			window.addEventListener('scroll', this.scroll)
		},
		destroyed() {
			window.removeEventListener('scroll', this.scroll, false)
		},
		methods: {
			afterLoad(anchors, item) {
				this.pagIndex = item.index
			},
			async scroll() {
				const elOffsetTop = document.getElementById('menuId').offsetTop
				const docScrollTop = document.documentElement.scrollTop

				if (docScrollTop <= 218) {
					this.topNum = 295 - docScrollTop
				} else {
					this.topNum = 81
				}
			},
			onClick(index) {
				$('html,body').animate({
					scrollTop: $('.from0tohero-pages' + index).offset().top - 350
				}, 200);
			},
			getData() {
				$.ajax({
					type: "GET",
					url: "https://socialtradingwebapi.eappservice.com/api/listSignalIndicators",
					success: (res) => {
						this.infoData = res.data
					}
				})
			},
		}
	}
</script>
<style lang="scss">
	.from0tohero {
		background: #F8F8F8;
		text-transform: capitalize;

		.section {
			height: initial !important;
		}

		.fp-tableCell {
			height: initial !important;
		}

		&-title {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			&__name {
				font-weight: 600;
				font-size: 32px;
				color: #3D0101;
				letter-spacing: 2px;
				line-height: 3.75;
			}

			&__line {
				background: #949699;
				width: 48px;
				height: 1px;
			}
		}

		&-btn {
			display: flex;
			justify-content: center;

			&__box {
				min-width: 140px;
				height: 36px;
				background: #3D0101;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0 20px;

				&__name {
					color: #FFFFFF;
					font-weight: 500;
					font-size: 14px;
					margin-right: 8px;
				}

				img {
					width: 8px;
				}
			}
		}

		&-pages1 {
			background-position: center center;
			text-align: center;
			padding-top: 80px;

			.pages1-box {
				background: url(../assets/image/from0tohero/pages-bg.png) no-repeat;
				background-size: 100% 100%;
				flex-direction: column;

				&__tit {
					font-weight: 700;
					font-size: 32px;
					color: #FFFFFF;
					padding-top: 40px;
				}

				&__img {
					padding: 16px 0;
				}

				&__tips {
					font-weight: 400;
					font-size: 14px;
					color: #FFFFFF;
					letter-spacing: 2px;
				}

				&__en {
					padding: 16px 0 28px;
					font-weight: 400;
					font-size: 40px;
					color: #FFFFFF;
					font-family: 'Baskerville Old Face';
					opacity: 0.2;
				}
			}

			.pages1-menu {
				position: fixed;
				left: 0;
				right: 0;
				z-index: 10;
				background: #FFFFFF;
				box-shadow: 0px 2px 10px rgba(61, 1, 1, 0.08);
				padding: 12px 0;

				&__item {
					height: 38px;
					border: 1px solid #4D0101;
					font-weight: 400;
					color: #4D0101;
					font-size: 14px;
					letter-spacing: 1px;
					margin-right: 24px;
					padding: 0 20px;
					cursor: pointer;

					&.active {
						background-color: #4D0101;
						color: #fff;
					}

					&:last-child {
						margin: 0;
						background-color: #AF8147;
						border: 1px solid #AF8147;
						color: #fff;

						img {
							width: 8px;
							margin-left: 6px;
						}
					}
				}
			}
		}

		&-pages2 {
			padding-top: 38px;

			.pages2-box {
				margin: 50px 0;
				height: 366px;
				align-items: center;
				background: linear-gradient(90deg, rgba(148, 150, 153, 0) 0%, rgba(148, 150, 153, 0.06) 92.82%);

				&__item {
					display: flex;
					flex-direction: column;
					align-items: center;
					height: 100%;
					background: url('../assets/image/from0tohero/num-1.png') no-repeat center 50px;

					.item-tit {
						padding: 140px 5px 0;
						text-align: center;
						line-height: 1.25;
						color: #000000;
						letter-spacing: 2px;
						font-weight: 600;
						font-size: 20px;
					}

					&:nth-child(2) {
						background: url('../assets/image/from0tohero/num-2.png') no-repeat center 50px;
					}

					&:nth-child(3) {
						background: url('../assets/image/from0tohero/num-3.png') no-repeat center 50px;
					}

					&:nth-child(4) {
						background: url('../assets/image/from0tohero/num-4.png') no-repeat center 50px;
					}

					&:nth-child(5) {
						background: url('../assets/image/from0tohero/num-5.png') no-repeat center 50px;
					}

					.item-tips {
						font-weight: 400;
						font-size: 12px;
						color: #666666;
						text-align: center;
						margin-top: 20px;
						line-height: 20px;
					}
				}
			}
		}

		&-pages3 {
			.pages3-box {
				margin: 40px 0;

				&__item {
					margin-right: 20px;
					padding: 20px;
					box-sizing: border-box;
					background: linear-gradient(0deg, rgba(148, 150, 153, 0.06) 0%, rgba(148, 150, 153, 0.02) 100%);

					&:last-child {
						margin-right: 0;
					}

					.item {
						&-info {
							align-items: center;
							justify-content: center;
							flex-direction: column;

							&__img {
								background: url('../assets/image/from0tohero/icon-xx-bg.png') no-repeat center center #5D0101;
								border-radius: 40px;
							}

							&__name {
								color: #000000;
								font-weight: 400;
								font-size: 16px;
								letter-spacing: 1px;
								margin-top: 8px;
							}

							&__tips {
								font-weight: 400;
								font-size: 12px;
								letter-spacing: 1px;
								color: #949699;
								line-height: 1.75;
							}
						}

						&-growth {
							flex-direction: column;
							align-items: center;
							margin: 20px 0;

							&__num {
								color: #8D0101;
								font-weight: 900;
								font-size: 24px;
							}

							&__tit {
								line-height: 1.75;
								font-weight: 400;
								font-size: 12px;
								color: #949699;
							}
						}

						&-desc {
							&__pag {
								flex-direction: column;
								align-items: center;
								text-align: center;

								&__num {
									font-weight: 700;
									font-size: 16px;
									color: #3D0101;
								}

								&__tit {
									font-weight: 400;
									font-size: 12px;
									color: #949699;
									padding-top: 14px;
								}
							}
						}

						&-bot {
							padding: 30px 30px 0;

							&__btn {
								height: 32px;
								display: flex;
								align-items: center;
								justify-content: center;
								font-weight: 400;
								font-size: 14px;
								color: #3D0101;
								cursor: pointer;

								&:last-child {
									background: #AF8147;
									border-radius: 2px;
								}
							}
						}
					}
				}
			}
		}

		&-pages4 {
			margin: 60px 0;

			.pages4-box {
				margin: 40px 0;
				align-items: flex-end;

				&__item {
					margin-left: 30px;
					background: linear-gradient(0deg, rgba(148, 150, 153, 0.06) 0%, rgba(148, 150, 153, 0.02) 100%);
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					width: 100%;

					&:nth-child(1) {
						margin-left: 0;
					}

					.item {
						&-tit {
							padding-top: 20px;
							font-weight: 700;
							font-size: 16px;
							color: #3D0101;
						}

						&-tips {
							color: #8D0101;
							font-weight: 500;
							font-size: 12px;
							padding-top: 10px;
						}

						&-img {
							padding-top: 20px;
						}

						&-pag {
							display: flex;
							font-weight: 700;
							align-items: center;
							font-size: 13px;
							letter-spacing: 1px;
							color: #3D0101;
							padding-top: 12px;
							width: 100%;
							padding: 12px 30px 0;

							.flex-1 {
								font-weight: 100;
							}
						}

						&-name {
							padding: 24px 0;
							font-weight: 700;
							font-size: 14px;
							letter-spacing: 1px;
							color: #AF8147;

							img {
								margin: 0 5px;
							}
						}
					}

					&:nth-child(5) {
						border: 1px solid #8D0101;

						.item {
							&-tit {
								color: #8D0101;
							}

							&-pag {
								color: #8D0101;
							}

							&-name {
								color: #8D0101;
							}
						}
					}
				}
			}
		}

		&-pages5 {
			margin: 60px 0;

			.pages5-box {
				margin: 40px 0;

				&__title {
					&__pag {
						font-weight: 500;
						font-size: 16px;
						color: #000000;
						display: flex;
						align-items: center;
						justify-content: center;
						padding-top: 15px;
						height: 68px;
						background: url('../assets/image/from0tohero/icon-xx-max.png') no-repeat center center;

						&:nth-child(1) {
							background: none;
						}

						&:last-child {
							color: #8D0101;
							font-weight: 700;
						}
					}
				}

				&__desc {
					&:nth-child(2n+0) {
						background: rgba(148, 150, 153, 0.04);
					}

					&__pag {
						min-height: 36px;
						font-weight: 500;
						font-size: 14px;
						color: #3D0101;
						line-height: 1.5;
						text-align: center;
					}
				}
			}
		}

		@media (max-width: 767px) {
			&-title {
				&__name {
					font-size: 20px;
					line-height: 3.25;
				}
			}

			&-btn {
				display: flex;
				justify-content: center;

				&__box {
					height: 45px;
				}
			}

			&-pages1 {
				position: initial;
				background-position: center center;
				text-align: center;
				padding-top: 60px;

				.pages1-box {
					&__tit {
						font-size: 26px;
					}

					&__tips {
						font-size: 13px;
						letter-spacing: 1px;
					}

					&__en {
						font-size: 26px;
						font-family: 'Baskerville Old Face';
						opacity: 0.2;
					}
				}

				.pages1-menu {
					background: #FFFFFF;
					box-shadow: 0px 2px 10px rgba(61, 1, 1, 0.08);
					padding: 12px 0;
					display: none;

					&__item {
						height: 38px;
						border: 1px solid #4D0101;
						font-weight: 400;
						color: #4D0101;
						font-size: 14px;
						letter-spacing: 1px;
						margin-right: 24px;
						padding: 0 20px;
						cursor: pointer;

						&:last-child {
							margin: 0;
							background-color: #AF8147;
							border: 1px solid #AF8147;
							color: #fff;

							img {
								width: 8px;
								margin-left: 6px;
							}
						}
					}
				}
			}

			&-pages2 {
				padding-top: 0;

				.pages2-box {
					margin: 30px 0;
					flex-wrap: wrap;
					height: initial;

					&__item {
						display: flex;
						flex: initial;

						&:nth-child(1) {
							width: 100%;
						}

						.item-tit {
							padding: 100px 5px 0;
						}

						.item-tips {
							font-weight: 400;
							font-size: 12px;
							color: #666666;
							text-align: center;
							margin-top: 20px;
							line-height: 20px;
						}
					}
				}
			}

			&-pages3 {
				.pages3-box {
					margin: 20px 0;
					overflow-x: auto;

					&__item {
						margin-right: 10px;
						padding: 5px;

						.item {
							&-info {
								align-items: center;
								justify-content: center;
								flex-direction: column;

								&__img {
									background: url('../assets/image/from0tohero/icon-xx-bg.png') no-repeat center center #5D0101;
									border-radius: 40px;
								}

								&__name {
									color: #000000;
									font-weight: 400;
									font-size: 16px;
									letter-spacing: 1px;
									margin-top: 8px;
								}

								&__tips {
									font-weight: 400;
									font-size: 12px;
									letter-spacing: 1px;
									color: #949699;
									line-height: 1.75;
								}
							}

							&-growth {
								flex-direction: column;
								align-items: center;
								margin: 20px 0;

								&__num {
									color: #8D0101;
									font-weight: 900;
									font-size: 24px;
								}

								&__tit {
									line-height: 1.75;
									font-weight: 400;
									font-size: 12px;
									color: #949699;
								}
							}

							&-desc {
								&__pag {
									flex-direction: column;
									align-items: center;
									text-align: center;

									&__num {
										font-weight: 700;
										font-size: 16px;
										color: #3D0101;
									}

									&__tit {
										font-weight: 400;
										font-size: 12px;
										color: #949699;
										padding-top: 14px;
									}
								}
							}

							&-bot {
								padding: 20px 5px;

								&__btn {
									height: 32px;
									display: flex;
									align-items: center;
									justify-content: center;
									font-weight: 400;
									font-size: 14px;
									color: #3D0101;
									cursor: pointer;

									&:last-child {
										background: #AF8147;
										border-radius: 2px;
									}
								}
							}
						}
					}
				}
			}

			&-pages4 {
				margin: 20px 0;

				.pages4-box {
					flex-wrap: wrap;
					flex-direction: column;

					&__item {
						margin-left: 0;
						margin-bottom: 10px;
						width: 100%;
						display: flex;

						.item {
							&-tit {
								padding-top: 20px;
								font-weight: 700;
								font-size: 16px;
								color: #3D0101;
							}

							&-tips {
								color: #8D0101;
								font-weight: 500;
								font-size: 12px;
								padding-top: 10px;
							}

							&-img {
								padding-top: 20px;
							}

							&-pag {
								display: flex;
								font-weight: 700;
								align-items: center;
								font-size: 13px;
								letter-spacing: 1px;
								color: #3D0101;
								padding-top: 12px;
								width: 100%;
								padding: 12px 30px 0;

								.flex-1 {
									font-weight: 100;
								}
							}

							&-name {
								padding: 24px 0;
								font-weight: 700;
								font-size: 14px;
								letter-spacing: 1px;
								color: #AF8147;

								img {
									margin: 0 5px;
								}
							}
						}

						&:nth-child(5) {
							.item {
								&-tit {
									color: #8D0101;
								}

								&-pag {
									color: #8D0101;
								}

								&-name {
									color: #8D0101;
								}
							}
						}
					}
				}
			}

			&-pages5 {
				margin: 30px 0;

				.pages5-box {
					margin: 20px 0;

					&__title {
						&__pag {
							font-size: 12px;
							background-size: 70%;
						}
					}

					&__desc {
						&__pag {
							font-size: 12px;
						}
					}
				}
			}
		}
	}
</style>