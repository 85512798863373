<template>
	<div class="cash-in-and-out" :class="$t('CFooters.className')">
		<ss-header mode="Home" bg="rgb(38, 5, 5)"></ss-header>
		<div class="page-screen-0">
			<div class="container-xxl">
				<div class="page-screen-0__box">
					<div class="page-screen-0__box__title">{{$t('LegalDocumen.screen0.title')}}</div>
					<div class="page-screen-0__box__info">
						<div class="tit" v-html="$t('LegalDocumen.screen0.tit')"></div>
						<div class="btn">
							<a href="" v-for="(item, index) in $t('LegalDocumen.screen0.list')"
								:key="index">{{item}}</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="page-screen-1">
			<div class="container-xxl">
				<ul class="page-screen-1__list" :class="$t('CFooters.className')">
					<li v-for="(item, index) in $t('LegalDocumen.screen1.list')" :key="index">
						<a :href="item.pdfUrl" target="_blank">{{item.title}}</a>
					</li>
				</ul>
				<!-- <div class="page-screen-1__tit">{{$t('LegalDocumen.screen1.tit')}}</div>
				<div class="page-screen-1__cont">
					<div class="cont-lf">
						<div class="cont-lf__name">{{$t('LegalDocumen.screen1.name')}}</div>
						<div class="cont-lf__input">
							<input type="text" :placeholder="$t('LegalDocumen.screen1.placeholder')" />
							<button type="button">{{$t('LegalDocumen.screen1.btn')}}</button>
						</div>
					</div>
					<div class="cont-lr">
						<div class="cont-lr__name">{{$t('LegalDocumen.screen1.text')}}</div>
						<div class="cont-lr__btn">
							<a href="#">{{$t('LegalDocumen.screen1.btn1')}}</a>
						</div>
					</div>
				</div> -->
			</div>
		</div>
		<ss-contact-us mode="all"></ss-contact-us>
		<new-footer></new-footer>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				seoInfo: ""
			}
		},
		metaInfo() {
			return {
				title: this.seoInfo.title || '',
				meta: [ // set meta
					{
						name: 'keyWords',
						content: this.seoInfo.keyWords || ''
					},
					{
						name: 'description',
						content: this.seoInfo.description || ''
					}
				]
			}
		},
		created() {
			this.$getSeoInfo({
				lang: this.$store.state.Lan,
				path: "/LegalDocumen"
			}).then(res => {
				this.seoInfo = res
			})
		},
		methods: {

		}
	}
</script>

<style lang="scss" scoped="scoped">
	.cash-in-and-out {
		&.vi-box {
			font-family: "UKIJ Tuz Tom", "Open Sans", "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", Arial, Verdana, Tahoma, sans-serif;
		}

		.page-screen-0 {
			background: url(../assets/image/WhyEBC/law-firm-01.jpg)no-repeat center center;
			padding: 134px 0;

			&__box {
				background-color: #fff;
				width: 45%;
				border-bottom: 8px solid #7d1614;

				&__title {
					height: 120px;
					background-color: #7d1614;
					color: #fff;
					line-height: 190px;
					text-indent: 75px;
					font-size: 16px;
				}

				&__info {
					padding: 0 83px;

					.tit {
						font-family: 'Noto Serif SC', Georgia, "Times New Roman", serif;
						font-weight: 700;
						font-size: 52px;
						line-height: 1.2em;
						color: #333;
						padding: 87px 0 50px;
					}

					.btn {
						display: flex;
						align-items: center;
						margin-bottom: 170px;
						justify-content: center;

						a {
							color: #872927;
							display: block;
							text-indent: 33px;
							margin-right: 20px;
							background: url(../assets/image/WhyEBC/icon_2.png) no-repeat 8px 1px;

							&:last-child {
								background: url(../assets/image/WhyEBC/icon_1.png) no-repeat 10px 1px;
							}
						}
					}
				}
			}
		}

		.page-screen-1 {
			&__list {
				padding: 96px 0 121px;

				li {
					margin-bottom: 40px;

					a {
						color: #333;
						font-family: 'Noto Serif SC', Georgia, "Times New Roman", serif;
						font-weight: 600;
						text-decoration: underline;
						text-decoration-color: #7d1614;
						font-size: 30px;
					}
				}

				&.vi-box {
					li {
						a {
							font-family: "UKIJ Tuz Tom", "Open Sans", "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", Arial, Verdana, Tahoma, sans-serif;
						}
					}
				}
			}

			&__tit {
				color: #000;
				font-family: 'Noto Serif SC', Georgia, "Times New Roman", serif;
				font-weight: 600;
				font-size: 36px;
				padding-bottom: 25px;
			}

			&__cont {
				background-color: #333;
				padding: 3px 0;
				margin-bottom: 40px;
				display: flex;

				.cont-lf {
					width: 50%;
					padding: 70px 30px;
					background-color: #7d1614;
					color: #fff;

					&__name {
						font-family: 'Noto Serif SC', Georgia, "Times New Roman", serif;
						font-size: 22px;
						margin-bottom: 20px;
					}

					&__input {
						display: flex;
						height: 36px;
						border: 1px solid #fff;
						border-radius: 5px;
						overflow: hidden;

						input {
							flex-grow: 1;
							background-color: #7d1614;
							border: none;
							color: #fff;
							text-indent: 10px;

							&:-moz-placeholder {
								color: #fff;
							}
						}

						button {
							background: initial;
							border: none;
							color: #fff;
						}
					}
				}

				.cont-lr {
					width: 50%;
					margin: 70px 0;
					text-align: center;

					&__name {
						font-family: 'Noto Serif SC', Georgia, "Times New Roman", serif;
						font-size: 22px;
						margin-bottom: 20px;
						color: #fff;
					}

					&__btn {
						a {
							display: inline-block;
							border: 2px solid #fff;
							text-align: center;
							color: #2ea3f2;
							font-size: 20px;
							padding: 0 70px;
							border-radius: 3px;
							line-height: 1.7em
						}
					}
				}
			}
		}

		@media (width: 768px) {
			.page-screen-0 {
				&__box {
					width: 80%;
				}
			}
		}

		@media (max-width: 767px) {
			.page-screen-1 {
				&__list {
					padding: 30px 0;

					li a {
						font-size: 20px;
					}
				}

				&__tit {
					font-size: 40px;
				}

				&__cont {
					flex-direction: column;

					&>div {
						width: 100% !important;
						padding: 20px 30px !important;
					}

					.cont-lr__name {
						font-size: 18px;
						text-align: center;
					}

					.cont-lr__btn a {
						padding: 0;
						display: block;
						width: 100%;
						font-size: 16px;
						line-height: 2;
					}
				}
			}

			.page-screen-0 {
				padding: 30px 0;

				&__box {
					width: 100%;

					&__title {
						line-height: 67px;
						height: 67px;
					}

					.tit {
						padding: 0;
					}

					&__info {
						text-align: center;
						padding: 80px 0;

						.btn {
							margin-bottom: 0;
						}
					}
				}
			}

			.container-xxl {
				padding: 0 30px;
			}
		}

	}
</style>