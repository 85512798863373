<template>
	<div class="cash-in-and-out" :class="$t('Dividend.box-name')">
		<ss-header mode="Home" bg="rgb(38, 5, 5)"></ss-header>
		<div class="funding1">
                    <h1 class="p1">{{$t('CashInAndOut.funding1.text1')}}</h1>
                    <img src="../assets/image/TradingAccount/fth1-line.png" alt="">
                    <p class="p2">{{$t('CashInAndOut.funding1.text2')}}</p>
                    <a rel="nofollow" :href="khUrl" class="btn">{{$t('CashInAndOut.funding1.btn')}}</a>
                </div>

                <div class="funding2">
                    <div class="container">
                        <p class="funding-new-public-title">{{$t('CashInAndOut.funding2.text1')}}</p>
                        <p class="funding-new-public-desc">{{$t('CashInAndOut.funding2.text2')}}</p>
                        <div class="row">
                            <img src="../assets/image/CashInAndOut/funding-new1.png" alt="">
                            <img src="../assets/image/CashInAndOut/funding-new2.png" alt="">
                            <img src="../assets/image/CashInAndOut/funding-new3.png" alt="">
                            <img src="../assets/image/CashInAndOut/funding-new4.png" alt="">
                            <img src="../assets/image/CashInAndOut/funding-new5.png" alt="">
                            <img src="../assets/image/CashInAndOut/funding-new6.png" alt="">
                            <img src="../assets/image/CashInAndOut/funding-new7.png" alt="">
                            <img src="../assets/image/CashInAndOut/funding-new8.png" alt="">
                        </div>
                        <a class="funding-new-public-link2" rel="nofollow" :href="khUrl">{{$t('CashInAndOut.funding1.btn')}}<img class="arrow1" src="../assets/image/TradingAccount/public-link-arrow.png" alt=""><img class="arrow2" src="../assets/image/TradingAccount/fca12.png" alt=""></a>
                    </div>
                </div>

                 <div class="funding3">
                    <div class="container">
                        <p class="funding-new-public-title">{{$t('CashInAndOut.funding3.text1')}}</p>
                        <p class="funding-new-public-desc">{{$t('CashInAndOut.funding3.text2')}}</p>
                        <div class="row">
                            <img src="../assets/image/CashInAndOut/funding-new9.png" alt="">
                            <p>{{$t('CashInAndOut.funding3.text3')}}</p>
                        </div>
                        <router-link class="funding-new-public-link2" to="/security">{{$t('CashInAndOut.funding3.btn')}}<img class="arrow1" src="../assets/image/TradingAccount/public-link-arrow.png" alt=""><img class="arrow2" src="../assets/image/TradingAccount/fca12.png" alt=""></router-link>
                    </div>
                </div>

                <div class="funding4">
                    <div class="container">
                        <p class="funding-new-public-title">{{$t('CashInAndOut.funding4.text1')}}</p>
                        <p class="funding-new-public-desc">{{$t('CashInAndOut.funding4.text2')}}</p>
                        <div class="row">
                            <img src="../assets/image/CashInAndOut/funding-new10.png" alt="">
                            <img src="../assets/image/CashInAndOut/funding-new11.png" alt="">
                            <img src="../assets/image/CashInAndOut/funding-new12.png" alt="">
                        </div>
                        <router-link class="funding-new-public-link2" to="/security">{{$t('CashInAndOut.funding3.btn')}}<img class="arrow1" src="../assets/image/TradingAccount/public-link-arrow.png" alt=""><img class="arrow2" src="../assets/image/TradingAccount/fca12.png" alt=""></router-link>
                    </div>
                </div>


                <div class="funding5">
                    <div class="container">
                        <p class="funding-new-public-title">{{$t('CashInAndOut.funding5.text1')}}</p>
                        <p class="funding-new-public-desc">{{$t('CashInAndOut.funding5.text2')}}</p>
                        <div class="row">
                                <table v-if="$store.state.Lan != 'ja_JP'">
                                <tbody>
                                <th>{{$t('CashInAndOut.funding5.text3')}}</th>
                                <th>{{$t('CashInAndOut.funding5.text4')}}</th>
                                <th>{{$t('CashInAndOut.funding5.text5')}}</th>
                                <th>{{$t('CashInAndOut.funding5.text6')}}</th>
                                <th>{{$t('CashInAndOut.funding5.text7')}}</th>
                                <tr>
                                    <td>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 1.98L21.8967 6.05178L21.8967 8.46H2.10327L2.10327 6.05178L12 1.98Z" stroke="#AF8147" stroke-width="1.17818"/>
                                        <path d="M4.91998 11.28V19.32" stroke="#AF8147" stroke-width="1.2" stroke-linecap="round"/>
                                        <path d="M12 11.28V19.32" stroke="#AF8147" stroke-width="1.2" stroke-linecap="round"/>
                                        <path d="M18.96 11.28V19.32" stroke="#AF8147" stroke-width="1.2" stroke-linecap="round"/>
                                        <path d="M2.40002 21.84L21.5997 21.9582" stroke="#AF8147" stroke-width="1.2" stroke-linecap="round"/>
                                        </svg>
                                        {{$t('CashInAndOut.funding5.text8')}}
                                    </td>
                                    <td>
                                        CNY, THB, VND, IDR, JPY
                                    </td>
                                    <td>110-29000</td>
                                    <td>{{$t('CashInAndOut.funding5.text9')}}</td>
                                    <td>{{$t('CashInAndOut.funding5.text10')}}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <rect x="3.48" y="3.6" width="17.04" height="13.92" stroke="#AF8147" stroke-width="1.2" stroke-linecap="round"/>
                                            <path d="M13.86 7.38H17.46" stroke="#AF8147" stroke-width="1.2" stroke-linecap="round"/>
                                            <path d="M5.28003 20.88L18.6 20.88" stroke="#AF8147" stroke-width="1.2" stroke-linecap="round"/>
                                            <path d="M13.8 10.56H15.96" stroke="#AF8147" stroke-width="1.2" stroke-linecap="round"/>
                                            <path d="M8.324 14.28V13.4916C7.60887 13.4165 7.02644 13.1844 6.57671 12.7954C6.12699 12.4063 5.89475 11.8841 5.88 11.2288H7.37295C7.38769 11.5018 7.47616 11.7407 7.63836 11.9455C7.80793 12.1503 8.03648 12.2902 8.324 12.3653V10.2049C8.25765 10.1844 8.18761 10.164 8.11389 10.1435C8.04753 10.123 7.97749 10.1025 7.90377 10.082C7.30659 9.89092 6.8495 9.64519 6.53248 9.34485C6.22283 9.0445 6.068 8.64519 6.068 8.14689C6.06063 7.55986 6.26706 7.08887 6.6873 6.73392C7.10753 6.37215 7.6531 6.16396 8.324 6.10935V5.28H9.06495V6.11959C9.72848 6.18102 10.263 6.39604 10.6685 6.76464C11.0813 7.12642 11.2988 7.59399 11.3209 8.16737H9.80589C9.79851 7.96942 9.72848 7.78853 9.59577 7.62471C9.47044 7.45406 9.29349 7.33461 9.06495 7.26635V9.17078C9.12393 9.19126 9.18291 9.21174 9.24189 9.23222C9.30087 9.24587 9.35985 9.26293 9.41883 9.28341C9.8022 9.40628 10.1524 9.54962 10.4694 9.71345C10.7864 9.87727 11.0408 10.0957 11.2325 10.3687C11.4242 10.6349 11.52 10.9797 11.52 11.4029C11.52 11.7578 11.4242 12.0889 11.2325 12.396C11.0482 12.7032 10.7717 12.9592 10.4031 13.164C10.0418 13.3619 9.59577 13.4745 9.06495 13.5018V14.28H8.324ZM7.54989 8.05474C7.54989 8.27317 7.61993 8.45065 7.76 8.58717C7.90008 8.71686 8.08808 8.82949 8.324 8.92505V7.22539C8.09546 7.26635 7.90746 7.3585 7.76 7.50184C7.61993 7.64519 7.54989 7.82949 7.54989 8.05474ZM10.016 11.495C10.016 11.222 9.92753 11.007 9.75059 10.85C9.57365 10.693 9.3451 10.5599 9.06495 10.4506V12.396C9.35985 12.3551 9.59208 12.2561 9.76165 12.0991C9.93122 11.9421 10.016 11.7407 10.016 11.495Z" fill="#AF8147"/>
                                        </svg>
                                        {{$t('CashInAndOut.funding5.text11')}}
                                    </td>
                                    <td>USD/EUR/GBP</td>
                                    <td>{{$t('CashInAndOut.funding5.text12')}}</td>
                                    <td>{{$t('CashInAndOut.funding5.text13')}}</td>
                                    <td>{{$t('CashInAndOut.funding5.text10')}}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <mask id="path-1-inside-1_53_392" fill="white">
                                            <rect x="2.64001" y="8.04" width="18.72" height="13.44" rx="0.48"/>
                                            </mask>
                                            <rect x="2.64001" y="8.04" width="18.72" height="13.44" rx="0.48" stroke="#AF8147" stroke-width="2.4" stroke-linecap="round" mask="url(#path-1-inside-1_53_392)"/>
                                            <path d="M15.96 14.76C15.96 13.6996 16.8196 12.84 17.88 12.84H20.76V16.68H17.88C16.8196 16.68 15.96 15.8204 15.96 14.76Z" stroke="#AF8147" stroke-width="1.2" stroke-linecap="round"/>
                                            <path d="M15.4596 8.56375L15.5941 8.05725C15.7642 7.41671 15.3829 6.75955 14.7423 6.58946L8.00408 4.80008C7.36354 4.62998 6.70639 5.01135 6.53629 5.65189L5.95929 7.82469L5.75998 8.56375" stroke="#AF8147" stroke-width="1.2" stroke-linecap="round"/>
                                            <path d="M17.3636 8.55257L17.7276 7.19166L17.8622 6.68516C18.0323 6.04462 17.6509 5.38747 17.0103 5.21737L10.2721 3.42799C9.63157 3.25789 8.97441 3.63926 8.80431 4.2798L8.66981 4.7863" stroke="#AF8147" stroke-width="1.2" stroke-linecap="round"/>
                                        </svg>
                                        {{$t('CashInAndOut.funding5.text14')}}
                                    </td>
                                    <td>USD</td>
                                    <td>110-1000</td>
                                    <td>{{$t('CashInAndOut.funding5.text9')}}</td>
                                    <td>{{$t('CashInAndOut.funding5.text10')}}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <circle cx="12" cy="12" r="9.36" stroke="#AF8147" stroke-width="1.2" stroke-linecap="round"/>
                                            <path d="M9.59998 7.56H13.26C14.4198 7.56 15.36 8.5002 15.36 9.66V9.66C15.36 10.8198 14.4198 11.76 13.26 11.76H9.59998V7.56Z" stroke="#AF8147" stroke-width="1.2" stroke-linecap="round"/>
                                            <path d="M9.59998 11.76H13.74C15.0323 11.76 16.08 12.8076 16.08 14.1V14.1C16.08 15.3923 15.0323 16.44 13.74 16.44H9.59998V11.76Z" stroke="#AF8147" stroke-width="1.2" stroke-linecap="round"/>
                                            <path d="M11.04 7.56H7.73999" stroke="#AF8147" stroke-width="1.2" stroke-linecap="round"/>
                                            <path d="M11.04 16.44H7.73999" stroke="#AF8147" stroke-width="1.2" stroke-linecap="round"/>
                                            <path d="M10.92 17.64L10.92 16.44" stroke="#AF8147" stroke-width="1.2" stroke-linecap="round"/>
                                            <path d="M10.92 7.56L10.92 6.36" stroke="#AF8147" stroke-width="1.2" stroke-linecap="round"/>
                                            <path d="M12.48 17.64L12.48 16.44" stroke="#AF8147" stroke-width="1.2" stroke-linecap="round"/>
                                            <path d="M12.48 7.56L12.48 6.36" stroke="#AF8147" stroke-width="1.2" stroke-linecap="round"/>
                                        </svg>
                                        {{$t('CashInAndOut.funding5.text15')}}
                                    </td>
                                    <td>USDT-TRC20/ERC20</td>
                                    <td>50-10000</td>
                                    <td>{{$t('CashInAndOut.funding5.text9')}}</td>
                                    <td>{{$t('CashInAndOut.funding5.text10')}}</td>
                                </tr>
                                </tbody>
                            </table>
                            <div class="funding-tips" v-if="$store.state.Lan != 'ja_JP'" v-html="$t('CashInAndOut.入金到账说明')">
                            </div>
                            <div v-else> 
                                <img style="margin-top: 10px;" src="../assets/image/CashInAndOut/funding-jp-method.jpg" alt="">
                                <p class="funding-new-public-desc" style="width: 100%;margin-top: 0;font-size: 14px;line-height: 26px;text-align: left;">※1　ご入金が反映されない場合、入金時の振込明細や入金受付の詳細やスクリーンショットを添えてcs@ebc.comまでご連絡ください。<br>
                                    ※2　お振込みの際、銀行の入金画面の振込人名義欄に、【取引口座番号+ご本人様の氏名（カタカナ）】を入力してください。記載がない場合、反映が遅れる場合がございます。記載例：123456 ヤマダタロウ</p>
                            </div>
                            
                        </div>
                        <a class="funding-new-public-link2" rel="nofollow" :href="khUrl">{{$t('CashInAndOut.funding1.btn')}}<img class="arrow1" src="../assets/image/TradingAccount/public-link-arrow.png" alt=""><img class="arrow2" src="../assets/image/TradingAccount/fca12.png" alt=""></a>
                    </div>
                </div>


                <div class="funding-new-page-screen-3">
                    <p class="funding-new-public-title">{{$t('CashInAndOut.screen3.title')}}</p>
                    <div class="problem-box">
                        <div id="accordionExample" class="accordion">
                            <div id="accordionFlushExample" class="accordion accordion-flush">
                                <div class="accordion-item" v-for="(item, index) in $t('CashInAndOut.screen3.faqList')"
							:key="index">
							<h2 class="accordion-header" :id="'flush-heading' + index">
								<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
									:data-bs-target="'#flush-collapse' + index" aria-expanded="false"
									:aria-controls="'flush-collapse' + index">
									{{index + 1}}. {{item.title}}
								</button>
							</h2>
							<div :id="'flush-collapse' + index" class="accordion-collapse collapse"
								:aria-labelledby="'flush-heading' + index" data-bs-parent="#accordionFlushExample">
								<div class="accordion-body" v-html="item.desc"></div>
							</div>
						</div>
                            </div>
                        </div>
                    </div>
                </div>


				<new-footer></new-footer>
	</div>
</template>

<script>
	export default {
		data(){
			return{
				khUrl: 'https://client.ebccrm.com/signup',
				loginUrl: 'https://client.ebccrm.com',
				mnUrl: 'https://client.ebccrm.com/simulation',
				seoInfo: ""
			}
		},
		metaInfo() {
			return {
				title: this.seoInfo.title || '',
				meta: [ // set meta
					{
						name: 'keyWords',
						content: this.seoInfo.keyWords || ''
					},
					{
						name: 'description',
						content: this.seoInfo.description || ''
					}
				]
			}
		},
		created() {
			this.$getSeoInfo({
				lang: this.$store.state.Lan,
				path: "/funding"
			}).then(res=>{
				this.seoInfo = res
			})
			let par = localStorage.getItem('advert')
			let lang = localStorage.getItem('language')
			let parms;
			if (lang === 'ct') {
				parms = 'tw'
			} else if (lang === 'ja') {
				parms = 'jp'
			} else if (lang === 'vi') {
				parms = 'zh'
			} else {
				parms = lang
			}
			par = JSON.parse(par)
			if (par != null) {
				this.khUrl = this.khUrl + '?utm_source=' + par.utm_source + '&utm_medium=' + par.utm_medium +
					'&utm_campaign=' + par.utm_campaign + '&utm_term=' + par.utm_term + '&utm_content=' + par.utm_content +
					'&hl=' + parms
			} else {
				this.khUrl = this.khUrl + '?hl=' + parms
			}
			this.mnUrl = this.mnUrl + '?hl=' + parms
			this.loginUrl = this.loginUrl + '?hl=' + parms
		}
	}
</script>

<style>
.c-footer .fp-overflow{
		display: unset !important;
	}
    .funding1{
  background: url(../assets/image/CashInAndOut/funding-bg.jpg) 100%/auto 100% no-repeat;
  height: 360px;
  margin-top: 0px;
  text-align: center;
  padding-top: 126px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.funding-tips {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    color: #949699;
    line-height: 180%;
}

.funding1 img {
    margin-top: 26px;
    width: 330px;
    margin-bottom: 14px;
}
.funding1 .p1{
    color: #FFF;
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    margin-top: 19px;
}
.funding1 .p2{
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1px;
    width: 666px;
    margin: 0 auto;
    line-height: 36px;
    opacity: 0.9;
}
.funding1 .btn{
    display: block;
    width: max-content;
    padding: 10px 56px;
    height: 46px;
    font-size: 16px;
    border-radius: 2px;
    background: #3D0101;
    color: #AF8147;
    margin: 28px auto;
}
.funding1 .btn:hover{
    color: #3D0101;
    background: #AF8147;
}

.funding-new-public-title{
    color: #3D0101;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
}
.funding-new-public-title2{
    color: #3D0101;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}
.funding-new-public-desc{
    width: 84%;
    color: #949699;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    margin: 24px auto 0;
    font-family: auto;
}




.funding1 .btn-ug{
    width: 190px;
}


.funding1 .p1 br{
    display: none;
}

.en-style .funding1 .p2{
    letter-spacing: 0px;
}
.en-style .funding1 .p1{
    margin-top: 80px;
}
.vi-box .funding1 .p1{
    margin-top: 40px;
}




.funding-new-public-link2{
    padding: 14px 28px;
    color: #3d0101;
    font-size: 16px;
    border: 1px solid #3d0101;
    letter-spacing: 1px;
    display: block;
    width: max-content;
    margin: 40px auto 0;
}
.funding-new-public-link2 img {
    width: 7px;
    margin-left: 12px;
}
.funding-new-public-link2:hover{
  color: #ffffff;
  background: #3d0101;
  border: 1px solid #3d0101;
}
.arrow2{
  display: none!important;
}
.funding-new-public-link2:hover .arrow1{
  display: none!important;
}
.funding-new-public-link2:hover .arrow2{
  display: inline-block!important;
}




.accordion-body{
    font-size: 14px;
    font-family: auto;
    background: #f8f8f8;
}


.funding-new-page-screen-3 {
    background-color: #ffffff;
    position: relative;
    padding-top: 80px;
    padding-bottom: 50px;
}

.funding-new-page-screen-3 .problem-box {
    width: 100%;
    max-width: 882px;
    margin: 22px auto 0
}

.funding-new-page-screen-3 .problem-box .accordion {
    padding: 20px 0 0px;
}

.funding-new-page-screen-3 .problem-box .accordion-item {
    border-radius: 4px 4px 4px 4px;
    overflow: hidden;
    border: 0 solid #d9d9d9;
    margin-bottom: 25px;
}
.funding-new-page-screen-3 .problem-box .accordion-item:hover{
    box-shadow: 0 12px 18px -6px rgba(34, 56, 101, .12);
}

.funding-new-page-screen-3 .problem-box .accordion-button {
    font-size: 16px;
    line-height: 1.5;
    color: #333 !important;
    background: #ffffff;
    font-family: auto;
}

.funding-new-page-screen-3 .problem-box .accordion-button:focus,
.funding-new-page-screen-3 .problem-box .accordion-button:not(.collapsed) {
    box-shadow: none;
    /* background-color: #fff; */
}

.funding-new-page-screen-3 .problem-box .accordion .cont-info {
    display: none
}

.funding-new-page-screen-3 .problem-box .accordion .cont-info.active {
    display: block
}

.funding-new-page-screen-3 .problem-box .accordion .accordion-body {
    line-height: 34px;
    font-size: 16px;
    color: #585858;
    background: #ffffff;
    font-family: auto;
}

.funding-new-page-screen-3 .problem-box .accordion .accordion-body ::v-deep p {
    padding-bottom: 13px
}

.funding-new-page-screen-3 .problem-box .accordion .accordion-body ::v-deep a {
    color: #2ea3f2
}

.funding-new-page-screen-3 .problem-box .accordion-item {
    border: none
}

.rotate .accordion-button:after{
    transform: rotate(180deg);
}

.funding-new-page-screen-3 .page-title {
    text-align: center
}

.funding-new-page-screen-3 .leaf-left,
.funding-new-page-screen-3 .leaf-right {
    position: absolute;
    top: 0;
    right: 0
}

.funding-new-page-screen-3 .leaf-left img,
.funding-new-page-screen-3 .leaf-right img {
    width: 650px;
    display: block
}

.funding-new-page-screen-3 .leaf-left {
    left: -70px;
    right: auto
}

.en-style2 .funding1 .p2{
    letter-spacing: 0px;
}
.en-style2 .t-sub3 .row .title{
    letter-spacing: 0px;
    line-height: 26px;
    margin-top: 32px;
    margin-bottom: 14px;
}
.en-style2 .t-sub3 .row .desc{
    letter-spacing: 0px;
}
.en-style2 .funding-new-public-link2{
    letter-spacing: 0px;
}
.en-style2 .public-link{
    letter-spacing: 0px;
}

.funding1 .btn{
    display: none;
}



.en-style2 .funding1 .p2{
    letter-spacing: 0px;
}


.funding2{
    background: #f3f3f3;
    padding: 80px 0;
}
.funding2 .container{
    width: 1150px;
}
.funding2 .row img{
    width: 25%;
    display: inline-block;
    margin-top: 30px;
}

.funding3{
    background: #ffffff;
    padding: 80px 0;
}
.funding3 .row{
    text-align: center;
}
.funding3 .row img{
    width: 36%;
    display: inline-block;
    margin: 40px auto 30px;
}
.funding3 .row p{
    color: #000;
    text-align: center;
    font-size: 16px;
    line-height: 28px;
    width: 80%;
    display: block;
    margin: 0 auto;
    font-family: auto;
}

.funding4{
    background: #f3f3f3;
    padding: 80px 0;
}
.funding4 .container{
    width: 1150px;
}
.funding4 .row img{
    width: 33.3%;
    display: inline-block;
    margin-top: 30px;
}

.funding5{
    background: #F8F8F8;
    padding: 80px 0;
}
.funding5 .container{
    width: 1150px;
}
.funding5 table{
    margin-top: 40px;
}
.funding5 table th{
    color: #000;
    font-size: 14px;
    font-family: auto;
    background: #CBCED3;
    font-weight: bold;
    padding: 18px 0px 18px 40px;
}
.funding5 table tr{
    border-bottom: 2px solid #f7f7f7;
}
.funding5 table td{
    color: #333;
    font-size: 14px;
    font-family: auto;
    padding: 24px 0px 22px 40px;
    background: #ffffff;
}
.funding5 table td svg{
    margin-right: 5px;
    margin-top: -3px;
}


@media(max-width:1400px) {
    .funding1 .p1{
        font-size: 34px;
    }
    .funding1 .p2{
        line-height: 32px;
        font-size: 16px;
        width: 600px;
    }
}


@media(max-width:995px) {
    .funding1 .p1 br{
        display: block !important;
    }
    .funding-new-public-link2{
        padding: 14px 26px;
        text-align: center;
        width: max-content;
        margin-top: 30px;
    }
    .funding1 .p2{
        width: 100%;
        padding: 20px 8%;
        font-size: 16px;
        line-height: 30px;
    }
    .funding1 .p1{
        margin-top: 20px;
        line-height: 58px;
        font-size: 34px;
    }
    .funding1 .btn{
        margin: 14px auto;
    }
    .funding-new-public-desc{
        width: 90%;
        font-size: 15px;
        line-height: 24px;
    }
    .funding-new-public-title{
        font-size: 24px;
    }
    .funding-new-public-title2{
        line-height: 26px;
    }
    .public-slogan {
        padding: 16px 0px;
        line-height: 28px;
        font-size: 13px;
    }
    .funding1 .p1 br{
        display: block;
    }
    .en-style .funding-new-public-title{
        font-size: 23px;
    }
    .en-style .funding1 .p1{
        line-height: 46px;
        font-size: 30px;
        margin-top: 55px;
    }
    .accordion-body{
        padding: 0;
    }
    .accordion-body{
        font-size: 12px;
    }
    .funding-new-page-screen-3 .problem-box .accordion .accordion-body{
        padding: 1rem 1.25rem;
    }
    .funding-new-page-screen-3 .problem-box .accordion{
        padding-top: 0px;
    }
    .product .table-list .flex-1{
        font-size: 13px;
    }
    .funding-new-page-screen-3 .problem-box .accordion{
        margin-bottom: 50px;
    }
    .product .accordion-button:after{
        display: none;
    }
    .product-box__accordion-item .accordion-button{
        padding: 8px 0;
    }
    .funding-new-page-screen-3{
        padding-bottom: 100px;
    }
    .en-style2 .public-link{
        float: unset;
    }
    .en-style2 .t-sub3 .row .title{
        line-height: 24px;
    }
    .en-style2 .t-sub3 .row .desc{
        margin-top: 14px;
    }
    .en-style2 .product{
        margin-top: 30px;
    }
    .en-style2 .funding-new-public-title{
        padding: 0 8%;
        line-height: 32px;
    }
    .en-style2 .funding-new-page-screen-3{
        padding-bottom: 70px;
    }
    .en-style2 .product{
        margin-bottom: 30px;
    }
    .funding1 .btn{
        display: block;
    }
    .funding1 {
        background: url(../assets/image/CashInAndOut/funding-bg-m.jpg) 100%/auto 100% no-repeat;
        height: 470px;
        margin-top: 0px;
        text-align: center;
        padding-top: 110px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
    .funding1 .p1{
        font-size: 26px;
        letter-spacing: 0px;
    }

    .funding-new-page-screen-3{
        background: #ffffff;
    }
    .funding-new-page-screen-3 .problem-box .accordion-button{
        background: #ffffff;
        font-size: 16px;
    }
    .funding-new-page-screen-3 .problem-box .accordion .accordion-body{
        background: #ffffff;
    }
    .funding-new-page-screen-3{
        padding-top: 60px;
        padding-bottom: 0;
    }
    .public-line{
        margin: 30px auto 30px;
    }
    .en-style2 .funding1 .p2{
        padding-top: 14px;
    }
    .en-style2 .account1 .row .title{
        font-size: 16px;
    }
    .en-style2 .funding-new-public-title{
        font-size: 24px;
    }

    .funding2 .container,.funding4 .container{
        width: 100%;
    }
    .funding5 .container{
        width: 100%;
    }
    .funding2 .container .row{
        padding: 0 9%;
        margin-top: 12px;
    }
    .funding2 .row img{
        width: 50%;
        padding: 0 2%;
        margin-top: 18px;
    }
    .funding-new-public-desc{
        font-size: 14px;
        width: 97%;
    }
    .funding2,.funding3,.funding4,.funding5{
        padding: 60px 0;
    }
    .funding3 .row img{
        width: 78%;
    }
    .funding3 .row p{
        width: 95%;
        font-size: 14px;
    }
    .funding4{
        display: none;
    }
    .funding5 .row{
        overflow-y: auto;
    }
    .funding5 tbody{
        width: max-content;
        display: block;
    }
    .funding5 table th:last-child{
        padding-right: 30px;
    }
    .funding5 table td:last-child{
        padding-right: 30px;
    }
}
</style>
