<template>
  <div>
    <ss-header mode="Home" bg="rgb(38, 5, 5)"></ss-header>
    <div :class="$t('common.lang')">
      <div :class="$t('common.fontFamily')">
        <!-- :class="$t('common.fontFamilyTitle')" -->

        <div>
          <div class="dividend-session1-wrapper">
            <div class="dividend-session1">
              <div class="dividend-session1-left">
                <div class="dividend-session1-title " :class="$t('common.fontFamilyTitle')">
                  {{ $t('Dividend.指数差价合约与股息调整') }}
                </div>
                <div class="dividend-session1-info">
                  {{ $t('Dividend.了解您在股票和指数差价合约交易中的股息调整和分配机制') }}
                </div>
                <a class="btn" :href="$store.state.khUrl" rel="nofollow">{{ $t('Dividend.了解指数差价合约') }}<span
                    class="iconfont icon-right"></span></a>
              </div>
            </div>
          </div>
        </div>
        <div class="wrapper">
          <div class="dividend-nav-list-box">
            <div class="dividend-nav-list">
              <div class="dividend-nav-list-inner">
                <a class="dividend-nav-link dividend-nav-link-active" href="#dividendSession2"><span
                    class="iconfont icon-Vector"></span>{{ $t('Dividend.指数差价合约') }}</a>
                <a class="dividend-nav-link" href="#dividendSession3"><span class="iconfont icon-Vector"></span>{{
                  $t('Dividend.股息调整') }}</a>
                <a class="dividend-nav-link" href="#dividendSession4"><span class="iconfont icon-Vector"></span>{{
                  $t('Dividend.除息日与交易优化') }}</a>
                <a class="dividend-nav-link" href="#dividendSession5"><span class="iconfont icon-Vector"></span>{{
                  $t('Dividend.股息调整明细') }}</a>
                <a class="dividend-nav-link" href="#dividendSession6"><span class="iconfont icon-Vector"></span>{{
                  $t('Dividend.股息的常见问题') }}</a>
              </div>
            </div>
          </div>

          <div id="dividend-content-box">
            <div id="dividendSession2" class="dividend-session2">
              <div class="dividend-session-inner">
                <div class="inner-text">
                  <div class="dividend-session-index">01</div>
                  <div class="dividend-session-title " :class="$t('common.fontFamilyTitle')">
                    {{ $t('Dividend.指数差价合约') }}
                  </div>
                  <div class="dividend-session-info">
                    {{ $t('Dividend.指数差价合约允许投资者通过股票市场指数的价格波动进行交易') }}
                  </div>
                </div>
                <img class="inner-img" src="../assets/image/dividend/session2.png" alt="" />
              </div>
            </div>
            <div id="dividendSession3" class="dividend-session3">
              <div class="dividend-session-inner">
                <div class="inner-text">
                  <div class="dividend-session-index">02</div>
                  <div class="dividend-session-title " :class="$t('common.fontFamilyTitle')">
                    {{ $t('Dividend.股息调整') }}
                  </div>
                  <div class="dividend-session-info">
                    {{ $t('Dividend.股息是上市公司从利润或保留资金中定期分配给股东的资金') }}
                  </div>
                </div>
                <img class="inner-img" src="../assets/image/dividend/session3.png" alt="" />
              </div>
            </div>
            <div id="dividendSession4" class="dividend-session4">
              <div class="dividend-session-inner">
                <div class="inner-text">
                  <div class="dividend-session-index">03</div>
                  <div class="dividend-session-title " :class="$t('common.fontFamilyTitle')">
                    {{ $t('Dividend.除息日与交易优化') }}
                  </div>
                  <div class="dividend-session-info">
                    {{ $t('Dividend.指数差价合约的除息日是由指数提供商根据成分股的除息日设定的') }}
                  </div>
                </div>
                <img class="inner-img" src="../assets/image/dividend/session4.png" alt="" />
              </div>
            </div>
            <div id="dividendSession5" class="dividend-session5">
              <div class="dividend-session5-inner">
                <div class="dividend-session5-title " :class="$t('common.fontFamilyTitle')">
                  {{ $t('Dividend.股息调整明细') }}
                </div>
                <div class="dividend-session5-info">
                  {{ $t('Dividend.差价合约交易中相关产品的股息数据') }}
                </div>
                <div class="page-screen-1">
                  <div data-wow-duration="2s" class="container-xl page-screen fadeIn wow">
                    <div class="page-screen__title flex">
                      <div class="page-screen__title__text flex-1" v-for="(itx, idx) in tab" :key="idx">
                        {{ itx }}
                      </div>
                    </div>
                    <div class="page-screen__ul">
                      <div class="page-screen__ul__li flex" v-for="(item, index) in data" :key="index">
                        <div class="text flex-1">
                          {{ item.symbol }}
                        </div>
                        <div class="text flex-1">
                          {{ item.long }}
                        </div>
                        <div class="text flex-1">
                          {{ item.short }}
                        </div>
                        <div class="text flex-1">
                          {{ item.date }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <a class="btn" rel="nofollow" :href="$store.state.khUrl">{{ $t('Dividend.即刻交易') }}</a>
              </div>
            </div>
            <div id="dividendSession6" class="dividend-session6">
              <div class="dividend-session6-inner">
                <div class="dividend-session6-title " :class="$t('common.fontFamilyTitle')">
                  {{ $t('Dividend.股息的常见问题') }}
                </div>
                <!-- <div class="dividend-session6-info">
                 
                </div> -->
                <div class="problem-box">
                  <div class="vision-item__line d-block d-md-none"></div>
                  <div id="accordionExample" class="accordion">
                    <div id="accordionFlushExample" class="accordion accordion-flush">
                      <div class="accordion-item">
                        <h2 id="flush-heading0" class="accordion-header">
                          <button class="accordion-button collapsed">
                            {{ $t('Dividend.什么是股息和除息日') }}
                          </button>
                        </h2>
                        <div class="accordion-collapse cont-info" style="display: none">
                          <div class="accordion-body">
                            <div v-html="$t('Dividend.股息是上市公司从利润或保留资金中定期分配给股东的资金answer')"></div>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 id="flush-heading0" class="accordion-header">
                          <button class="accordion-button collapsed">
                            {{ $t('Dividend.股息调整的时间') }}
                          </button>
                        </h2>
                        <div class="accordion-collapse cont-info" style="display: none">
                          <div class="accordion-body">
                            <div v-html="$t('Dividend.指数差价合约股息调整通常会在除息日的answer')"></div>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 id="flush-heading0" class="accordion-header">
                          <button class="accordion-button collapsed">
                            {{ $t('Dividend.股息对指数交易的影响') }}
                          </button>
                        </h2>
                        <div class="accordion-collapse cont-info" style="display: none">
                          <div class="accordion-body">
                            <div v-html="$t('Dividend.持有多头仓位answer')"></div>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 id="flush-heading0" class="accordion-header">
                          <button class="accordion-button collapsed">
                            {{ $t('Dividend.股息调整的计算公式') }}
                          </button>
                        </h2>
                        <div class="accordion-collapse cont-info" style="display: none">
                          <div class="accordion-body">
                            <div v-html="$t('Dividend.多头仓位answer')"></div>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 id="flush-heading0" class="accordion-header">
                          <button class="accordion-button collapsed">
                            {{ $t('Dividend.股息调整计算示例') }}
                          </button>
                        </h2>
                        <div class="accordion-collapse cont-info" style="display: none">
                          <div class="accordion-body">
                            <div v-html="$t('Dividend.以SPXUSD为例answer')"></div>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 id="flush-heading0" class="accordion-header">
                          <button class="accordion-button collapsed">
                            {{ $t('Dividend.注意事项') }}
                          </button>
                        </h2>
                        <div class="accordion-collapse cont-info" style="display: none">
                          <div class="accordion-body">
                            <div v-html="$t('Dividend.股息调整仅适用于指数和股票差价合约交易answer')"></div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <a class="btn" rel="nofollow" :href="$store.state.khUrl">{{ $t('Dividend.了解指数差价合约') }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <page-slogan id="footer-box"></page-slogan>
    <div>
      <new-footer></new-footer>
    </div>
  </div>
</template>

<script>
import WOW from 'wowjs';
import md5 from 'js-md5';
import {
  mapState
} from "vuex";
export default {
  data() {
    return {
      tab: this.$t('Dividend.list'),
      data: [],
      khUrl: 'https://client.ebccrm.com/signup',
      seoInfo: "",

    }
  },
  metaInfo() {
    return {
      title: this.seoInfo.title || '',
      meta: [ // set meta
        {
          name: 'keyWords',
          content: this.seoInfo.keyWords || ''
        },
        {
          name: 'description',
          content: this.seoInfo.description || ''
        }
      ]
    }
  },
  created() {
    this.$getSeoInfo({
      lang: this.$store.state.Lan,
      path: "/dividend"
    }).then(res => {
      this.seoInfo = res
    })
    this.$http('/ebc/api/getCfd/run').then(res => {
      this.data = res.data.data
    })
    let par = localStorage.getItem('advert')
    let lang = localStorage.getItem('language')
    let parms;
    if (lang === 'ct') {
      parms = 'tw'
    } else if (lang === 'ja') {
      parms = 'jp'
    } else if (lang === 'vi') {
      parms = 'zh'
    } else {
      parms = lang
    }
    par = JSON.parse(par)
    if (par != null) {
      this.khUrl = this.khUrl + '?utm_source=' + par.utm_source + '&utm_medium=' + par.utm_medium +
        '&utm_campaign=' + par.utm_campaign + '&utm_term=' + par.utm_term + '&utm_content=' + par.utm_content +
        '&hl=' + parms
    } else {
      this.khUrl = this.khUrl + '?hl=' + parms
    }
    // this.getPortfolio()
  },
  mounted() {
    let wow = new WOW.WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
      live: false
    });
    wow.init();

    let dividendSession2Top = 0;
    let dividendSession3Top = 0;
    let dividendSession4Top = 0;
    let dividendSession5Top = 0;
    let dividendSession6Top = 0;
    let end = 99999;
    let b = 100;
    function removeClass(els, name) {
      for (let i = 0; i < els.length; i++) {
        els[i].classList.remove(name);
      }
    }
    function throttle(func, delay) {
      var timer = null;
      return function () {
        var that = this;
        var args = arguments;
        if (!timer) {
          timer = setTimeout(function () {
            func.call(that, args);
            timer = null;
          }, delay);
        }
      };
    }
    $(document).ready(function () {
      console.log(11, $(".dividend-nav-link"), $(".dividend-nav-link")[4]);
      b = $(".dividend-nav-list-box").height();
      $(".accordion-item").click(function () {
        if ($(this).children(".cont-info").is(":hidden")) {
          $(this).children(".cont-info").css("display", "block");
          $(".cont-info")
            .not($(this).children(".cont-info"))
            .css("display", "none");
          $(".accordion-item").removeClass("rotate");
          $(this).addClass("rotate");
        } else {
          $(this).children(".cont-info").css("display", "none");
          $(this).removeClass("rotate");
        }
      });

      dividendSession2Top = $("#dividendSession2").offset().top;

      dividendSession3Top = $("#dividendSession3").offset().top;

      dividendSession4Top = $("#dividendSession4").offset().top;

      dividendSession5Top = $("#dividendSession5").offset().top;

      dividendSession6Top = $("#dividendSession6").offset().top;
      end = $("#footer-box").offset().top;
      console.log("end", end)
      jisuan();
    });

    $(window).scroll(
      throttle(function () {
        let h = $("#footer-box").height();
        let t = $(this).scrollTop();
        let m = 0;
        if (t >= dividendSession6Top - m) {
          removeClass($(".dividend-nav-link"), "dividend-nav-link-active");
          $(".dividend-nav-link")[4].classList.add("dividend-nav-link-active");
        } else if (t > dividendSession5Top - m) {
          removeClass($(".dividend-nav-link"), "dividend-nav-link-active");
          $(".dividend-nav-link")[3].classList.add("dividend-nav-link-active");
        } else if (t > dividendSession4Top - m) {
          removeClass($(".dividend-nav-link"), "dividend-nav-link-active");
          $(".dividend-nav-link")[2].classList.add("dividend-nav-link-active");
        } else if (t > dividendSession3Top - m) {
          removeClass($(".dividend-nav-link"), "dividend-nav-link-active");
          $(".dividend-nav-link")[1].classList.add("dividend-nav-link-active");
        } else {
          removeClass($(".dividend-nav-link"), "dividend-nav-link-active");
          $(".dividend-nav-link")[0].classList.add("dividend-nav-link-active");
        }
      }, 300)
    );
    function jisuan() {
      let t = $(this).scrollTop();
      console.log("t", t)
      end = $("#footer-box").offset().top;
      console.log("end", end)
      if (end - b < t) {
        $(".dividend-nav-list").addClass("nav-hidden");
      } else {
        $(".dividend-nav-list").removeClass("nav-hidden");
      }

      let height = $(".wrapper").offset().top;
      if (t < height) {
        // $(".dividend-nav-list-box").css({"top":0})
        $(".dividend-nav-list-box").css({ position: "absolute" });
      } else {
        // $(".dividend-nav-list-box").css({"top":t-height+40})
        $(".dividend-nav-list-box").css({ position: "fixed" });
      }
    }
    $(window).scroll(jisuan);

  },
  computed: {
    ...mapState({
      Lan: (state) => state.Lan,
    }),
  },
  methods: {


  }
}
</script>

<style scoped>
@charset "UTF-8";
@import "../assets/font/lang-font-family.css";
.public-slogan{
  background-color: #f9f6f2;
}
.rotate {
  /* transition: all 0.2s ease-in-out; */
  transform: rotate(-0);
}

.rotate .accordion-button:after {
  transform: rotate(180deg);
}

a {
  text-decoration: none;
}

.dividend-session1-wrapper {
  background-color: #0b2136;
  margin-top: 30px;
}

.dividend-session1 {
  margin: 0 auto;
  max-width: 1440px;
  padding: 80px 144px 5px;
  display: flex;
  justify-content: space-between;
  background-image: url(../assets/image/dividend/session1_bg.png);
  background-size: auto 85%;
  background-position: right bottom;
  background-repeat: no-repeat;
}

.dividend-session1-left {
  max-width: 650px;
}

.dividend-session1-bg {
  width: 660px;
}

.dividend-session1-title {
  margin-top: 30px;
  color: #fff;
  font-size: 50px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%;
}

.dividend-session1-info {
  margin-top: 24px;
  min-height: 88px;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: 140%;
}

.dividend-session1 .btn {
  margin-top: 24px;
  margin-bottom: 52px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  min-height: 48px;
  gap: 4px;
  border-radius: 4px;
  background: #fff;
  color: #af8147;
  font-size: 16px;
  font-weight: 350;
  line-height: 150%;
  min-width: 198px;
  transition: color 0.3s, background-color 0.3s;
}

.dividend-session5 .btn,
.dividend-session6 .btn {
  margin-top: 34px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  min-height: 48px;
  gap: 4px;
  border-radius: 4px;
  background: #f9f6f2;
  border: 1px solid #a52933;
  color: #a52933;
  font-size: 16px;
  font-weight: 350;
  line-height: 150%;
  min-width: 198px;
  transition: color 0.3s, background-color 0.3s;
}

.dividend-session6 .btn {
  background: #fff;
}

.dividend-session1 .btn:hover {
  color: #fff;
  background: #af8147;
}

.dividend-session5 .btn:hover,
.dividend-session6 .btn:hover {
  color: #fff;
  background: #a52933;
}

.wrapper {
  position: relative;
  /* overflow: hidden; */
}

.dividend-nav-list-box {
  position: absolute;
  width: 250px;
  top: 60px;
  transform: translateX(calc(50vw - 580px));
  /* transition: top 0.42s; */
}

.dividend-nav-list {
  position: sticky;
  /* max-width: 1440px; */
  /* margin: 0 auto; */
  /* padding: 80px 144px; */
  padding: 30px 0;
}

.nav-hidden {
  display: none;
}

.dividend-nav-list-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 250px;
}

.dividend-nav-list-inner::before {
  position: absolute;
  left: 0;
  top: 10px;
  content: "";
  width: 1px;
  height: calc(100% - 20px);
  background-color: #e6ddcf;
}

.dividend-nav-link {
  position: relative;
  padding-left: 20px;
  color: #5d2333;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  z-index: 999;
}

.dividend-nav-link:hover {
  color: #a52933;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
}

.dividend-nav-link .icon-Vector {
  position: absolute;
  left: -7px;
  top: 0;
  color: #e6ddcf;
}

.dividend-nav-link:hover .icon-Vector {
  color: #ff4b4e;
}

.dividend-nav-link-active {
  color: #a52933;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
}

.dividend-nav-link-active .icon-Vector {
  color: #ff4b4e;
}

.dividend-session-inner {
  /* position: relative; */
  margin: 0 auto;
  max-width: 1440px;
  padding: 80px 144px 80px 442px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dividend-session-index {
  color: #9f734c;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}

.dividend-session-title {
  margin-top: 22px;
  color: #5d2333;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
}

.dividend-session-info {
  margin-top: 24px;
  color: #232222;
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: 140%;
}

.inner-text {
  flex-shrink: 0;
  max-width: 300px;
}

.inner-img {
  flex-shrink: 0;
  width: 400px;
}

.dividend-session5 {
  background: #f9f6f2;
}

.dividend-session5-inner,
.dividend-session6-inner {
  margin: 0 auto;
  max-width: 1440px;
  padding: 80px 144px 80px 442px;
}

.dividend-session5-title,
.dividend-session6-title {
  color: #5d2333;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
}

.dividend-session5-info,
.dividend-session6-info {
  margin-top: 24px;
  color: #666;
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: 140%;
}

.page-screen-1 {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  margin-top: 50px;
}

.page-screen-1 .page-screen__title {
  background: #b2afad;
}

.page-screen-1 .page-screen__title__text {
  font-size: 16px;
  text-align: center;
  display: flex !important;
  justify-content: center;
  align-items: center;
  color: #fff;
  line-height: normal;
  white-space:wrap;
  padding: 3px;
  height: 60px;
  font-weight: 700;
}


.page-screen-1 .page-screen__ul__li .text {
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 16px;
  text-indent: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #666;
}

.page-screen-1 .page-screen__ul__li {
  background-color: #f9f9f9;
}

.page-screen-1 .page-screen__ul__li:nth-child(2n+2) {
  background-color: #fff;
}

.flex-1+.flex-1 {
  border-left: 1px solid #f9f9f9;
}

.page-screen-1 .page-screen__ul__li .text {
  font-weight: 700;
  font-family: Gilroy;
}

.page-screen-1 .page-screen__title .flex-1:last-child {
  background: #730707;
}

.page-screen__ul__li .flex-1:last-child {
  color: #730707;
}

.accordion {
  margin-top: 40px;
}

.dividend-session6-inner .accordion-item,
.page-screen-3 .accordion-item {
  border-radius: 4px 4px 4px 4px;
  overflow: hidden;
  border: 0 solid #d9d9d9;
  margin-bottom: 25px;
}

.dividend-session6-inner .accordion-item:hover,
.page-screen-3 .accordion-item:hover {
  box-shadow: 0 12px 18px -6px rgba(34, 56, 101, 0.12);
}

.dividend-session6-inner .accordion-button,
.page-screen-3 .accordion-button {
  font-size: 18px;
  line-height: 1.5;
  color: #333 !important;
  font-weight: 400;
}

.dividend-session6-inner .accordion-button:focus,
.dividend-session6-inner .accordion-button:not(.collapsed),
.page-screen-3 .accordion-button:focus,
.page-screen-3 .accordion-button:not(.collapsed) {
  box-shadow: none;
  background-color: #fff;
}

.dividend-session6-inner .accordion .cont-info,
.page-screen-3 .accordion .cont-info {
  display: none;
}

.dividend-session6-inner .accordion .cont-info.active,
.page-screen-3 .accordion .cont-info.active {
  display: block;
}

.dividend-session6-inner .accordion .accordion-body,
.page-screen-3 .accordion .accordion-body {
  line-height: 34px;
  font-size: 16px;
  color: #585858;
  background: #fff;
  font-family: "Noto Serif SC";
}

.dividend-session6-inner .accordion .accordion-body ::v-deep p,
.page-screen-3 .accordion .accordion-body ::v-deep p {
  padding-bottom: 13px;
}

.dividend-session6-inner .accordion .accordion-body ::v-deep a,
.page-screen-3 .accordion .accordion-body ::v-deep a {
  color: #2ea3f2;
}

.dividend-session6-inner .accordion-item,
.page-screen-3 .accordion-item {
  border: none;
}

.accordion-button {
  padding-left: 8px;
  padding-right: 8px;
}

.bottom-banner-text {
  padding: 38px 0;
  background: #f9f6f2;
  color: #3d0101;
  text-align: center;
}

.text1 {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.text2 {
  margin-top: 16px;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
}

@media (max-width: 1440px) {
  .dividend-session1-bg {
    width: 560px;
    height: auto;
    object-fit: contain;
  }

  .inner-img {
    max-width: 300px;
  }
  .dividend-nav-list-box{
    transform: translateX(144px);
  }
}

@media (max-width: 1300px) {
  .dividend-session1-bg {
    width: 500px;
    height: auto;
    object-fit: contain;
  }
}

@media (max-width: 1200px) {
  .dividend-session1-bg {
    width: 440px;
    height: auto;
    object-fit: contain;
  }

  .inner-text {
    max-width: 220px;
  }

  .inner-img {
    max-width: 220px;
  }
}

@media (max-width: 995px) {
  .dividend-nav-list-box {
    display: none;
  }

  .dividend-session1 {
    padding: 70px 28px;
    background: url(../assets/image/dividend/session1_m_bg.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .dividend-session1-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .dividend-session1-bg {
    display: none;
  }

  .dividend-session1-title {
    font-size: 50px;
    text-align: center;
  }

  .dividend-session1-info {
    margin-top: 20px;
    font-size: 16px;
    text-align: center;
    min-height: none;
  }

  .dividend-session1 .btn {
    margin: 34px auto 0;
  }

  .dividend-session-inner {
    padding: 70px 28px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
  }

  .dividend-session4 {
    padding-bottom: 70px;
  }

  .dividend-session-index,
  .dividend-session-title,
  .dividend-session-info {
    text-align: center;
  }

  .inner-text,
  .inner-img {
    max-width: 100%;
  }

  .dividend-session6-inner,
  .dividend-session5-inner {
    padding: 70px 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .page-screen-1 {
    width: 100%;
  }

  .dividend-session5-title,
  .dividend-session5-info {
    text-align: center;
  }

  .problem-box {
    width: 100%;
  }

  .accordion-body {
    padding: 0 10px;
  }

  .page-screen-1 .page-screen__ul__li .text:last-child,
  .page-screen-1 .page-screen__title__text:last-child {
    display: block;
    flex-grow: 1.25;
  }

  .page-screen-1 .page-screen__title__text,
  .page-screen-1 .page-screen__ul__li .text {
    font-size: 11px;
  }
}
</style>
