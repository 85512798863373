<template>
	<div class="data-center" :class="$t('CFooters.className')">
		<ss-header mode="Home" bg="rgb(38, 5, 5)"></ss-header>
		<div class="container-xxl page-screen-0">
			<div class="flex flex-hd">
				<div class="flex-1">
					<div class="flex-hd__title" :class="$t('CFooters.className')">{{$t('DataCenter.screen0.title')}}
					</div>
					<div class="flex-hd__desc" :class="$t('CFooters.className')" v-html="$t('DataCenter.screen0.desc')">
					</div>
				</div>
				<div class="flex-right d-none d-md-block">
					<img src="../assets/image/DataCenter/img1.png" />
				</div>
			</div>
			<div class="flex flex-bd">
				<div class="flex-1">
					<ul>
						<li :class="{'active': index === 1}" :key="index"
							v-for="(item, index) in $t('DataCenter.screen0.list')"><a
								:class="$t('CFooters.className')">{{item}}</a></li>
					</ul>
				</div>
				<div class="flex-right">
					<img src="../assets/image/DataCenter/img2.png" />
					<div class="flex-bd__box d-none d-md-block">
						<div class="flex-bd__text" v-html="$t('DataCenter.screen0.text')"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-xxl page-screen-1">
			<div class="flex">
				<div class="flex-img flex-1 d-none d-md-block">
					<img src="../assets/image/DataCenter/img3.png">
				</div>
				<div class="flex-text">
					<page-title :enText="$t('DataCenter.screen1.pageEnTitle')"
						:text="$t('DataCenter.screen1.pageTitle')" />
					<img class=" d-block d-md-none full-width" src="../assets/image/DataCenter/img3.png">
					<div class="flex-text__title" v-html="$t('DataCenter.screen1.title')"></div>
					<ul class="flex-text__ul">
						<li :key="index" v-for="(item, index) in $t('DataCenter.screen1.list')">{{item}}</li>
					</ul>
				</div>
			</div>
			<div class="btn-link-box d-none d-md-block">
				<span>{{$t('DataCenter.screen4.text')}}</span>
				<button class="btn btn-primary">
					<router-link to="/LearningCenter">
						<img src="../assets/image/ManagingYourPortfolio/siteCompLabIconLight.png" alt="">
						<span>{{$t('DataCenter.screen4.btn')}}</span>
						<span>→</span>
					</router-link>
				</button>
			</div>
		</div>
		<div class="container-xxl page-screen-2">
			<div class="flex">
				<div class="flex-text">
					<page-title :enText="$t('DataCenter.screen2.pageEnTitle')"
						:text="$t('DataCenter.screen2.pageTitle')" />
					<img class=" d-block d-md-none full-width" src="../assets/image/DataCenter/img4.png">
					<div class="flex-text__title" v-html="$t('DataCenter.screen2.title')"></div>
					<ul class="flex-text__ul">
						<li :key="index" v-for="(item, index) in $t('DataCenter.screen2.list')">{{item}}</li>
					</ul>
				</div>
				<div class="flex-img flex-1 d-none d-md-block">
					<img src="../assets/image/DataCenter/img4.png">
				</div>
			</div>
			<div class="btn-link-box d-none d-md-block">
				<span>{{$t('DataCenter.screen2.text')}}</span>
				<button class="btn btn-primary">
					<router-link to="/LearningCenter">
						<img src="../assets/image/ManagingYourPortfolio/siteCompLabIconLight.png" alt="">
						<span>{{$t('DataCenter.screen2.btn')}}</span>
						<span>→</span>
					</router-link>
				</button>
			</div>
		</div>
		<div class="container-xxl page-screen-3">
			<div class="flex">
				<div class="flex-img flex-1 d-none d-md-block">
					<img src="../assets/image/DataCenter/img5.png">
				</div>
				<div class="flex-text">
					<page-title :enText="$t('DataCenter.screen3.pageEnTitle')"
						:text="$t('DataCenter.screen3.pageTitle')" />
					<img class=" d-block d-md-none full-width" src="../assets/image/DataCenter/img5.png">
					<div class="flex-text__title" v-html="$t('DataCenter.screen3.title')"></div>
				</div>
			</div>
		</div>
		<div class="container-xxl page-screen-4">
			<div class="flex">
				<div class="flex-text">
					<page-title :enText="$t('DataCenter.screen4.pageEnTitle')"
						:text="$t('DataCenter.screen4.pageTitle')" />
					<img class=" d-block d-md-none full-width" src="../assets/image/DataCenter/img6.png">
					<div class="flex-text__title" v-html="$t('DataCenter.screen4.title')"></div>
				</div>
				<div class="flex-img flex-1 d-none d-md-block">
					<img src="../assets/image/DataCenter/img6.png">
				</div>
			</div>
			<div class="btn-link-box d-none d-md-block">
				<span>{{$t('DataCenter.screen4.text')}}</span>
				<button class="btn btn-primary">
					<router-link to="/LearningCenter">
						<img src="../assets/image/ManagingYourPortfolio/siteCompLabIconLight.png" alt="">
						<span>{{$t('DataCenter.screen4.btn')}}</span>
						<span>→</span>
					</router-link>
				</button>
			</div>
		</div>
		<div class="container-xxl page-screen-5">
			<div class="flex">
				<div class="flex-text">
					<page-title :enText="$t('DataCenter.screen5.pageEnTitle')"
						:text="$t('DataCenter.screen5.pageTitle')" />
					<img class=" d-block d-md-none full-width" src="../assets/image/DataCenter/img7.png">
					<div class="flex-text__title" v-html="$t('DataCenter.screen5.title')"></div>
					<div class="flex-text__ul">
						<li :key="index" v-for="(item, index) in $t('DataCenter.screen5.list')">{{item}}</li>
					</div>
				</div>
				<div class="flex-img flex-1 d-none d-md-block">
					<img src="../assets/image/DataCenter/img7.png">
				</div>
			</div>
		</div>
		<div class="container-xxl page-screen-6">
			<div class="flex">
				<div class="flex-img flex-1 d-none d-md-block">
					<img src="../assets/image/DataCenter/img8.png">
				</div>
				<div class="flex-text">
					<page-title :enText="$t('DataCenter.screen6.pageEnTitle')"
						:text="$t('DataCenter.screen6.pageTitle')" />
					<img class=" d-block d-md-none full-width" src="../assets/image/DataCenter/img8.png">
					<div class="flex-text__title" v-html="$t('DataCenter.screen6.title')"></div>
					<div class="flex-text__ul">
						<li :key="index" v-for="(item, index) in $t('DataCenter.screen6.list')">{{item}}</li>
					</div>
				</div>
			</div>
		</div>
		<div class="container-xxl page-screen-7">
			<div class="flex">
				<div class="flex-text">
					<page-title :enText="$t('DataCenter.screen7.pageEnTitle')"
						:text="$t('DataCenter.screen7.pageTitle')" />
					<img class=" d-block d-md-none full-width" src="../assets/image/DataCenter/img9.png">
					<div class="flex-text__title" v-html="$t('DataCenter.screen7.title')"></div>
					<div class="flex-text__ul">
						<li :key="index" v-for="(item, index) in $t('DataCenter.screen7.list')">{{item}}</li>
					</div>
				</div>
				<div class="flex-img flex-1 d-none d-md-block">
					<img src="../assets/image/DataCenter/img9.png">
				</div>
			</div>
			<div class="btn-link-box d-none d-md-block">
				<span>{{$t('DataCenter.screen7.text')}}</span>
				<button class="btn btn-primary">
					<router-link to="/LearningCenter">
						<img src="../assets/image/ManagingYourPortfolio/siteCompLabIconLight.png" alt="">
						<span>{{$t('DataCenter.screen7.btn')}}</span>
						<span>→</span>
					</router-link>
				</button>
			</div>
		</div>
		<ss-contact-us mode="all"></ss-contact-us>
		<new-footer></new-footer>
	</div>
</template>


<script>
	export default {
		data() {
			return {
				seoInfo: ""
			}
		},
		metaInfo() {
			return {
				title: this.seoInfo.title || '',
				meta: [ // set meta
					{
						name: 'keyWords',
						content: this.seoInfo.keyWords || ''
					},
					{
						name: 'description',
						content: this.seoInfo.description || ''
					}
				]
			}
		},
		created() {
			this.$getSeoInfo({
				lang: this.$store.state.Lan,
				path: "/lab"
			}).then(res => {
				this.seoInfo = res
			})

		}
	}
</script>

<style lang="scss" scoped="scoped">
	.data-center {
		&.vi-box {
			font-family: "UKIJ Tuz Tom", "Open Sans", "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", Arial, Verdana, Tahoma, sans-serif;
		}

		.page-screen-1,
		.page-screen-2,
		.page-screen-3,
		.page-screen-4,
		.page-screen-5,
		.page-screen-6,
		.page-screen-7 {
			padding-top: 90px;
			padding-bottom: 90px;

			.flex-text {
				width: 600px;
				margin-right: 100px;

				&__title {
					font-size: 26px;
					font-weight: bold;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.44;
					letter-spacing: normal;
					text-align: justify;
					color: #333333;
					margin-top: 40px;
				}

				&__ul {
					margin-top: 24px;

					li {
						margin-top: 20px;
						font-size: 18px;
						line-height: 1.6;
						color: #8b8b8b;
						position: relative;
						padding-left: 36px;

						&::after {
							content: '';
							width: 36px;
							height: 28px;
							position: absolute;
							top: 3px;
							left: 0;
							background: url(../assets/image/DataCenter/right.png) center center / cover no-repeat;
						}
					}
				}
			}

			.flex-img {
				img {
					width: 100%;
					display: block;
				}

				&+.flex-text {
					margin-right: 0;
					margin-left: 100px;
				}
			}
		}

		.btn-link-box {
			margin-top: 90px;
			text-align: center;

			span {
				font-size: 16px;
				line-height: 1.75;
				color: #333333;
				padding: 0 24px;
			}

			.btn {
				padding: 0 32px;
				line-height: 40px;
				height: 40px;
				border-radius: 30px;
				background-color: #0048a9;
				font-size: 0;

				span {
					display: inline-block;
					vertical-align: middle;
					color: #fff;
				}

				img {
					display: inline-block;
					vertical-align: middle;
					width: 20px;
					width: 20px;
				}
			}
		}

		.page-screen-0 {
			padding-bottom: 90px;
			padding-top: 140px;

			.flex-bd {
				.flex-bd__box {
					width: 210px;
					position: absolute;
					right: 0;
					bottom: 0;
					border-top: 2px solid #7d1614;
					font-family: NotoSansSC;
					font-size: 16px;
					line-height: 1.75;
					text-align: right;
					color: #8b8b8b;
					padding: 14px 0;

				}

				li {
					margin-top: 20px;
					position: relative;
					padding-left: 32px;

					a {
						font-family: NotoSansSC;
						font-size: 18px;
						font-weight: normal;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.6;
						letter-spacing: normal;
						color: #8b8b8b;

						&.en-box {
							font-size: 16px;
						}

						&.th-box {
							font-size: 16px;
						}

						&.vi-box {
							font-size: 16px;
						}
					}

					&::after {
						content: '';
						width: 12px;
						height: 12px;
						position: absolute;
						top: 50%;
						left: 0;
						transform: translateY(-50%);
						background: url(../assets/image/DataCenter/invalid-name_2@3x.png) center center / cover no-repeat;
					}

					&.active {
						a {
							color: #333333;
						}

						&::after {
							background-image: url(../assets/image/DataCenter/invalid-name@3x.png);
						}
					}
				}

				.flex-right {
					position: relative;
					padding-right: 210px;

					img {
						position: relative;
						width: 538px;
						box-shadow: 5px 5px 0 0 #7d1614;
						z-index: 1;
					}
				}
			}

			.flex-hd {
				height: 200px;

				&__title {
					font-size: 64px;
					font-weight: 600;
					line-height: 1.38;
					color: #333333;

					&.en-box {
						font-size: 38px;
						font-family: "Source Serif Pro", Georgia, "Times New Roman", serif;
					}

					&.ja-box {
						font-size: 38px;
						font-family: "Source Serif Pro", Georgia, "Times New Roman", serif;
					}

					&.ko-box {
						font-size: 38px;
						font-family: "Source Serif Pro", Georgia, "Times New Roman", serif;
					}

					&.th-box {
						font-size: 38px;
						font-family: "Source Serif Pro", Georgia, "Times New Roman", serif;
					}

					&.vi-box {
						font-size: 38px;
						font-family: "UKIJ Tuz Tom", "Open Sans", "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", Arial, Verdana, Tahoma, sans-serif;
					}
				}

				&__desc {
					font-family: NotoSerifSC;
					font-size: 28px;
					font-weight: 600;
					line-height: 1.57;
					color: #333333;
					margin-top: 24px;

					&.en-box {
						font-size: 20px;
						font-family: "Source Serif Pro", Georgia, "Times New Roman", serif;
					}

					&.th-box {
						font-size: 20px;
						font-family: "Source Serif Pro", Georgia, "Times New Roman", serif;
					}

					&.vi-box {
						font-size: 20px;
						font-family: "UKIJ Tuz Tom", "Open Sans", "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", Arial, Verdana, Tahoma, sans-serif;
					}
				}

				.flex-right {
					img {
						position: relative;
						z-index: 2;
						width: 545px;
					}
				}
			}

		}

		@media screen and (max-width: 1366px) and (min-width:1025px) {
			.page-screen-0 {

				.flex-bd {
					li {
						a {
							&.en-box {
								font-size: 16px;
							}

							&.th-box {
								font-size: 16px;
							}

							&.vi-box {
								font-size: 16px;
							}
						}

					}
				}

				.flex-hd {
					height: 200px;

					&__title {
						&.en-box {
							font-size: 38px;
							font-family: "Source Serif Pro", Georgia, "Times New Roman", serif;
						}

						&.th-box {
							font-size: 38px;
							font-family: "Source Serif Pro", Georgia, "Times New Roman", serif;
						}

						&.vi-box {
							font-size: 38px;
							font-family: "UKIJ Tuz Tom", "Open Sans", "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", Arial, Verdana, Tahoma, sans-serif;
						}

						&.ko-box {
							font-size: 36px;
						}

						&.ja-box {
							font-size: 36px;
						}
					}


				}

			}

		}

		@media screen and (max-width: 1024px) and (min-width:769px) {
			.page-screen-0 {
				.flex-hd {
					&__title {
						font-size: 50px;

						&.ko-box {
							font-size: 38px;
						}

						&.ja-box {
							font-size: 38px;
						}
					}

					&__desc {
						font-size: 26px;

						&.ja-box {
							font-size: 22px;
							padding-right: 10px;
						}
					}

					.flex-right {
						img {
							width: 380px;
						}
					}
				}

				.flex-bd {
					.flex-right {
						img {
							width: 380px;
						}
					}
				}
			}

			.page-screen-1,
			.page-screen-2,
			.page-screen-3,
			.page-screen-4,
			.page-screen-5,
			.page-screen-6,
			.page-screen-7 {
				padding-top: 40px;
				padding-bottom: 40px;

				.flex-text {
					width: 520px;
					margin-right: 10px !important;
					margin-left: 20px !important;
				}
			}


		}

		@media (width: 768px) {
			.page-screen-0 {
				padding: 100px 0 0;

				.flex-hd {
					padding-left: 10px;

					&__title {
						font-size: 32px;

						&.en-box {
							font-size: 24px;
						}

						&.th-box {
							font-size: 24px;
						}

						&.vi-box {
							font-size: 24px;
						}

						&.ja-box {
							font-size: 24px;
						}
					}

					&__desc {
						font-size: 20px;
					}

					.flex-right {
						img {
							width: 300px;
						}
					}
				}

				.flex-bd {
					margin-top: -50px;

					.flex-1 {
						padding-left: 10px;
					}

					.flex-right {
						padding-right: 130px;

						img {
							width: 300px;
						}
					}

					&__box {
						width: 130px !important;
						font-size: 14px !important;
						padding: 14px 5px 14px !important;
					}
				}
			}

			.page-screen-1,
			.page-screen-2,
			.page-screen-3,
			.page-screen-4,
			.page-screen-5,
			.page-screen-6,
			.page-screen-7 {
				padding-top: 90px;
				padding-bottom: 40px;

				.flex-text {
					width: 350px;
					margin-right: 30px;
				}

				.flex-img {

					&+.flex-text {
						margin-right: 0;
						margin-left: 30px;
					}
				}
			}
		}

		@media (max-width: 767px) {
			.contact-us {
				margin-top: 100px;
			}

			.page-screen-0 {
				padding: 100px 0 0;

				.flex-hd {
					padding: 0 40px;
					height: auto;
					flex-direction: column;

					.flex-1 {
						width: 100%;
					}

					&__title {
						font-size: 51px;
						margin: 0 auto;
						text-align: center;
						line-height: 1.4;
					}

					&__desc {
						font-size: 25px;
						margin-top: 24px;
						font-weight: 500;
					}
				}

				.flex-bd {
					padding: 0 40px;
					margin-top: 175px;
					padding-bottom: 48px;
					background-color: #f7f7f7;
					flex-direction: column-reverse;

					.flex-right {
						padding: 0;

						img {
							margin-top: -168px;
							width: 100%;
							box-shadow: none;
						}
					}

					.flex-1 {
						width: 100%;
					}

					li a {
						font-size: 16px;
					}
				}
			}

			.page-screen-1,
			.page-screen-2,
			.page-screen-3,
			.page-screen-4,
			.page-screen-5,
			.page-screen-6,
			.page-screen-7 {
				padding-top: 60px;
				padding-bottom: 60px;

				.page-title {
					text-align: center;
				}

				.flex-text {
					width: 100%;
					margin: auto;

					.d-block {
						margin-top: 56px;
					}

					&__title {
						padding: 0 30px;
						font-size: 32px;
					}

					&__ul {
						padding: 0 30px;
					}
				}

				.flex-img {
					img {}

					&+.flex-text {
						margin: auto;
					}
				}
			}
		}
	}
</style>