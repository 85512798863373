<template>
	<div class="win2022">
		<div class="win2022-head">
			<div class="head-box flex">
				<div class="head-box__logo">
					<router-link to="/"><img src="@/assets/image/No1/Group-19.png"
							title=" EBC官网地址 https://www.ebcfin.com" class="img"></router-link>
				</div>
				<div class="head-box__tit flex-1">
					<router-link to="/" class="btns">www.ebcfin.com</router-link>
				</div>
				<div class="head-box__btn flex">
					<a class="left-btn"
						href="https://client.ebccrm.com/signup?utm_source=landingpagewin2022&amp;utm_medium=banner&amp;utm_campaign=ebc">免费开户</a>
					<a class="right-btn"
						href="https://client.ebccrm.com/?utm_source=landingpagewin2022&amp;utm_medium=banner&amp;utm_campaign=ebc">登录</a>
				</div>
			</div>
		</div>
		<div class="win2022-banner">
			<img src="@/assets/image/win2022/banner.png" class="pc wow fadeIn" data-wow-delay="0.25s">
			<img src="@/assets/image/win2022/top-1.jpg" class="wap wow fadeIn" data-wow-delay="0.25s">
		</div>
		<div class="win2022-gift">
			<div class="win2022-gift__li flex-1 flex wow bounceIn">
				<div class="tit">
					虎年贺岁礼一
				</div>
				<div class="tips">
					限时免息账户
				</div>
				<div class="img">
					<img src="../assets/image/win2022/card01.png">
				</div>
				<div class="cont">
					所有主要货币对及黄金免除隔夜利息
				</div>
				<div class="time">
					活动时间：2022/1/15-2022/4/15
				</div>
				<div class="btns">
					<a class="btns__img"
						href="https://client.ebccrm.com/signup?utm_source=landingpagewin2022&amp;utm_medium=banner&amp;utm_campaign=ebc"><img
							src="../assets/image/win2022/btn.png"></a>
				</div>
			</div>
			<div class="win2022-gift__li flex-1 flex wow bounceIn">
				<div class="tit">
					虎年贺岁礼二
				</div>
				<div class="tips">
					最高$18,888推荐红包
				</div>
				<div class="img">
					<img src="../assets/image/win2022/card02.png">
				</div>
				<div class="cont">
					介绍有效新户并完成入金（≥$2022）
				</div>
				<div class="time">
					活动时间：2022/1/15-2022/4/15
				</div>
				<div class="btns">
					<a class="btns__img"
						href="https://client.ebccrm.com/signup?utm_source=landingpagewin2022&amp;utm_medium=banner&amp;utm_campaign=ebc"><img
							src="../assets/image/win2022/btn.png"></a>
				</div>
			</div>
			<div class="win2022-gift__li flex-1 flex wow bounceIn">
				<div class="tit">
					虎年贺岁礼三
				</div>
				<div class="tips">
					入金即送高档贺礼
				</div>
				<div class="img">
					<img src="../assets/image/win2022/card03.png">
				</div>
				<div class="cont">
					满足入金条件可获赠相应新春贺礼
				</div>
				<div class="time">
					活动时间：2022/1/15-2022/2/15
				</div>
				<div class="btns">
					<a class="btns__img"
						href="https://client.ebccrm.com/signup?utm_source=landingpagewin2022&amp;utm_medium=banner&amp;utm_campaign=ebc"><img
							src="../assets/image/win2022/btn.png"></a>
				</div>
			</div>
		</div>
		<div class="win2022-title flex wow fadeInUp">
			<div class="win2022-title__line flex-1">

			</div>
			<div class="win2022-title__name">
				限时免息账户
			</div>
			<div class="win2022-title__line flex-1">

			</div>
		</div>
		<div class="win2022-shaky">
			<div class="win2022-shaky__time wow fadeInUp" data-wow-delay="0.25s">
				<div class="name">
					2022年1月15日 - 2022年4月15日
				</div>
			</div>
			<div class="win2022-shaky__tips wow fadeInUp" data-wow-delay="0.35s">
				所有用户交易主要货币对以及黄金免除隔夜利息
			</div>
			<div class="win2022-shaky__tit wow fadeInUp"  data-wow-delay="0.45s">
				免息产品包括
			</div>
			<div class="win2022-shaky__coin wow bounceIn">
				<img src="../assets/image/win2022/coin.png">
			</div>
		</div>
		<div class="win2022-title flex wow fadeInUp">
			<div class="win2022-title__line flex-1">

			</div>
			<div class="win2022-title__name">
				最高$18,888推荐红包
			</div>
			<div class="win2022-title__line flex-1">

			</div>
		</div>
		<div class="win2022-shaky">
			<div class="win2022-shaky__time wow fadeInUp" data-wow-delay="0.25s">
				<div class="name">
					2022年1月15日 - 2022年4月15日
				</div>
			</div>
			<div class="win2022-shaky__tips wow fadeInUp" data-wow-delay="0.5s">
				凡介绍有效新户并完成指定入金（超过$2022则视为有效），可获相应EBC迎新红包。
			</div>
			<div class="table  wow flipInX">
				<div class="pag flex">
					<div class="flex-1 page_l">
						介绍有效用户数
					</div>
					<div class="flex-1 page_c">

					</div>
					<div class="flex-1 page_r">
						红包金额
					</div>
				</div>
				<div class="pag flex">
					<div class="flex-1 page_l">
						≥2
					</div>
					<div class="flex-1 page_c">

					</div>
					<div class="flex-1 page_r">
						$88
					</div>
				</div>
				<div class="pag flex">
					<div class="flex-1 page_l">
						≥4
					</div>
					<div class="flex-1 page_c">

					</div>
					<div class="flex-1 page_r">
						$188
					</div>
				</div>
				<div class="pag flex">
					<div class="flex-1 page_l">
						≥8
					</div>
					<div class="flex-1 page_c">

					</div>
					<div class="flex-1 page_r">
						$388
					</div>
				</div>
				<div class="pag flex">
					<div class="flex-1 page_l">
						≥16
					</div>
					<div class="flex-1 page_c">

					</div>
					<div class="flex-1 page_r">
						$888
					</div>
				</div>
				<div class="pag flex">
					<div class="flex-1 page_l">
						≥32
					</div>
					<div class="flex-1 page_c">

					</div>
					<div class="flex-1 page_r">
						$1,888
					</div>
				</div>
				<div class="pag flex">
					<div class="flex-1 page_l">
						≥64
					</div>
					<div class="flex-1 page_c">

					</div>
					<div class="flex-1 page_r">
						$3,888
					</div>
				</div>
				<div class="pag flex">
					<div class="flex-1 page_l">
						≥128
					</div>
					<div class="flex-1 page_c">

					</div>
					<div class="flex-1 page_r">
						$8,888
					</div>
				</div>
				<div class="pag flex">
					<div class="flex-1 page_l">
						≥256
					</div>
					<div class="flex-1 page_c">

					</div>
					<div class="flex-1 page_r">
						$18,888
					</div>
				</div>
			</div>
		</div>
		<div class="win2022-title flex wow fadeInUp">
			<div class="win2022-title__line flex-1">

			</div>
			<div class="win2022-title__name">
				入金即送高档贺礼
			</div>
			<div class="win2022-title__line flex-1">

			</div>
		</div>
		<div class="win2022-shaky">
			<div class="win2022-shaky__time wow fadeInUp" data-wow-delay="0.25s">
				<div class="name">
					2022年1月15日 - 2022年2月15日
				</div>
			</div>
			<div class="win2022-shaky__tips wow fadeInUp" data-wow-delay="0.5s">
				满足入金条件的用户可获赠相应EBC新春贺礼
			</div>
			<div class="win2022-shaky__lp flex">
				<div class="win2022-shaky__lp__li flex-1 wow bounceIn">
					<div class="img">
						<img src="../assets/image/win2022/001.png">
					</div>
					<div class="sum">
						入金≥$2,022
					</div>
					<div class="name">
						严选辽参礼盒
					</div>
					<div class="ps">
						补气养血，固本培元。
					</div>
				</div>
				<div class="win2022-shaky__lp__li flex-1 wow bounceIn">
					<div class="img">
						<img src="../assets/image/win2022/002.png">
					</div>
					<div class="sum">
						入金≥$6,066
					</div>
					<div class="name">
						九蒸九晒高级黑参
					</div>
					<div class="ps">
						“九蒸九制”经古法反复炮制而成的黑参，<br>
						皂苷吸收率高达80%，增强免疫力，<br>
						显著提高抗疾病能力。
					</div>
				</div>
				<div class="win2022-shaky__lp__li flex-1 wow bounceIn">
					<div class="img">
						<img src="../assets/image/win2022/003.png">
					</div>
					<div class="sum">
						入金≥$8,088
					</div>
					<div class="name">
						老纵金奖水仙
					</div>
					<div class="ps">
						荣获2021首届（福茶杯）金奖<br>
						参赛编号：163460959035
					</div>
				</div>
			</div>
		</div>
		<div class="win2022-foot">
			<div class="win2022-foot__tit flex wow fadeInUp">
				<div class="img">
					<img src="@/assets/image/No1/Path-93.png">
				</div>
				<div class="name">
					活动条款：
				</div>
			</div>
			<div class="win2022-foot__li wow fadeInUp" data-wow-delay="0.15s">
				1. 新春贺礼将在2月15日之后派送。
			</div>
			<div class="win2022-foot__li wow fadeInUp" data-wow-delay="0.25s">
				2. 参与此次活动的用户不得同时参与 EBC 其他任何活动。
			</div>
			<div class="win2022-foot__li wow fadeInUp" data-wow-delay="0.35s">
				3. 本次活动最终解释权归 EBC 所有。
			</div>
			<div class="win2022-foot__flag flex wow fadeInUp" data-wow-delay="0.45s">
				<div class="img">
					<img src="../assets/image/win2022/flag1.jpg">
				</div>
				<div class="cont flex-1">
					<div class="tit">
						EBC Financial Group (UK) Ltd
					</div>
					<div class="name pad8">
						由英国金融行为监管局(FCA)授权和监管
					</div>
					<div class="name">
						监管号:927552
					</div>
				</div>
			</div>
			<div class="win2022-foot__flag flex wow fadeInUp" data-wow-delay="0.55s">
				<div class="img">
					<img src="../assets/image/win2022/flag2.jpg">
				</div>
				<div class="cont flex-1">
					<div class="tit">
						EBC Global Pty Ltd
					</div>
					<div class="name pad8">
						由澳大利亚证券和投资委员会(ASIC)授权和监管
					</div>
					<div class="name">
						监管号:500991
					</div>
				</div>
			</div>
			<div class="win2022-foot__info">
				详情请咨询官网或专属顾问
			</div>
		</div>
	</div>
</template>

<script>
	import WOW from 'wowjs';
	export default {
		metaInfo() {
			return {
				title: '2022 双赢贺岁|EBC Financial Group',
				meta: [ // set meta
					{
						name: 'keyWords',
						content: 'EBC,EBC平台,EBC官网,EBC金融官网,EBC开户,EBC金融'
					},
					{
						name: 'description',
						content: 'EBC金融官网是由EBC金融集团EBC Financial Group成立的,主要提供,EBC金融,EBC开户交易服务,只需一个EBC综合平台账户,即可交易多种差价合约（CFD）:货币、美股、数字货币、期货指数、贵金属和能源等.'
					}
				]
			}
		},
		data() {
			return {

			}
		},
		mounted() {
			let wow = new WOW.WOW({
				boxClass: 'wow',
				animateClass: 'animated',
				offset: 0,
				mobile: true,
				live: false
			});
			wow.init();
		},
		created() {

		}
	}
</script>

<style lang="scss">
	.win2022 {
		background-image: radial-gradient(circle at 18% 38%, #600004, #330002 38%);

		&-shaky {
			max-width: 1080px;
			margin: auto;
			text-align: center;
			padding: 40px 0;

			&__time {
				padding-top: 10px;

				.name {
					letter-spacing: 3.28px;
					color: #e5c07f;
					font-size: 21px;
					position: relative;
					display: inline-block;
					padding: 0 24px;
					font-weight: 100;

					&::after {
						content: '「';
						font-size: 18px;
						@include abs(0, null, null, 0)
					}

					&::before {
						content: '」';
						font-size: 18px;
						@include abs(null, 0, 0)
					}
				}
			}

			&__tips {
				font-size: 21px;
				color: #ffffff;
				margin-top: 30px;
				letter-spacing: 3.28px;
				font-weight: 100;
				line-height: 1.5;
			}

			&__tit {
				letter-spacing: 4.5px;
				color: #e5c07f;
				font-size: 24px;
				font-weight: 100;
				padding: 40px 0;
			}

			&__lp {
				padding: 60px 0 0;

				.sum {
					font-size: 21px;
					font-weight: 100;
					color: #fff;
					letter-spacing: 1.75px;
				}

				.name {
					letter-spacing: 2.33px;
					font-size: 21px;
					font-weight: 500;
					color: #e5c07f;
					padding: 18px 0;
					color: #e5c07f;
				}

				.ps {
					font-size: 18px;
					font-weight: 100;
					line-height: 1.5;
					color: #fff;
				}
			}

			.table {
				margin-top: 50px;

				.pag {
					align-items: center;
					margin-bottom: 25px;
					font-size: 24px;
					letter-spacing: 4px;
					color: #e5c07f;
					font-weight: 100;
					line-height: 1.2;

					.page_l {
						text-align: right;
					}

					.page_c {
						border-bottom: 1px dotted #e5c07f;
						margin: 0 25px;
					}

					.page_r {
						text-align: left;
					}
				}
			}
		}

		&-title {
			align-items: center;
			max-width: 1080px;
			margin: auto;

			&__line {
				height: 1px;
				border-top: 1.5px #e4bf7e dotted;
			}

			&__name {
				font-size: 28px;
				font-weight: 500;
				letter-spacing: 5.02px;
				color: #e5c07f;
				padding: 0 10px;
			}
		}

		&-gift {
			max-width: 1080px;
			padding: 50px 0;
			margin: auto;
			display: flex;

			&__li {
				justify-content: center;
				flex-direction: column;
				align-items: center;

				.tit {
					font-size: 28px;
					font-weight: 500;
					color: #e5c07f;
					letter-spacing: 5.02px;
				}

				.tips {
					color: #fff;
					font-size: 28px;
					letter-spacing: 5.02px;
					padding: 20px 0;
				}

				.img {
					margin-bottom: 27px;
				}

				.cont {
					font-size: 18px;
					color: #fff;
					margin-bottom: 13px;
					letter-spacing: 1.5px;
				}

				.time {
					font-size: 18px;
					color: #fff;
					margin-bottom: 13px;
					letter-spacing: 2px;
				}

				.btns {
					margin-top: 20px;
				}
			}
		}

		&-head {
			@include fixed(0, 0, null, 0);
			background-color: #3d0101;
			padding: 15px 0;
			z-index: 1000;

			.head-box {
				max-width: 1080px;
				margin: auto;
				align-items: center;

				&__logo {}

				&__tit {
					text-align: center;

					.btns {
						color: #a97c50;
						font-family: Times New Roman, Georgia, serif;
						font-weight: 700;
						font-size: 22px;
						line-height: 70px;
					}
				}

				&__btn {
					align-items: center;

					.left-btn {
						display: block;
						color: #c49a6c;
						border-radius: 5px;
						font-size: 20px;
						font-family: Noto Sans SC, Helvetica, Arial, Lucida, sans-serif;
						background-color: #760006;
						padding: 0.3em 1em;
						line-height: 1.7em;
					}

					.right-btn {
						margin-left: 20px;
						font-size: 20px;
						font-family: Noto Sans SC, Helvetica, Arial, Lucida, sans-serif !important;
						color: #e3e3e3;
						padding: 0.3em 1em;
						text-decoration: underline
					}
				}
			}
		}

		&-foot {
			margin: auto;
			padding: 60px 0;
			max-width: 908px;

			&__tit {
				font-size: 24px;
				color: #e5bf7e;
				padding: 0 0 24px;
				border-bottom: 1px dotted #e5bf7e;
				margin-bottom: 30px;
				display: flex;
				align-items: center;

				.img {
					padding-top: 2px;

					img {
						margin-right: 10px;
						width: 18px;
						height: 18px;
					}
				}
			}

			&__li {
				color: #f7f7f7;
				font-size: 20px;
				margin-bottom: 15px;
				font-family: HiraginoSansGB-W3;
				letter-spacing: 3px;
			}

			&__info {
				color: #e5c07f;
				font-size: 20px;
				text-align: center;
				font-family: HiraginoSansGB-W3;
				letter-spacing: 3px;
				margin-top: 40px;
			}

			&__flag {
				align-items: center;
				margin-top: 50px;

				.img {
					margin-right: 50px;

					img {
						width: 129px;
						height: 86px;
					}
				}

				.cont {
					.tit {
						color: #e5bf7e;
						font-weight: bold;
						font-size: 18px;
					}

					.name {
						font-size: 18px;
						color: #fff;
						line-height: 28px;
					}

					.pad8 {
						padding: 8px 0;
					}
				}
			}
		}

		&-banner {
			margin-top: 120px;
			padding-top: 280px;
			text-align: center;
			background: url(../assets/image/win2022/head.jpg) repeat-x;
			.wap {
				display: none;
			}

			img {}
		}

	}

	@media (max-width: 500px) {
		.win2022 {
			&-gift {
				flex-direction: column;
				padding: 50px 10px;

				&__li {
					width: 100%;
					padding: 30px 0;
					border-bottom: 1px dotted #e5bf7e;
					&:last-child{
						border: none;
					}
				}
			}

			&-title {
				padding: 0 10px;

				&__name {
					letter-spacing: 2.02px;
					font-size: 26px;
				}
			}

			&-shaky {
				padding: 40px 10px;

				&__time {
					.name {
						letter-spacing: 1.28px;
						font-size: 16px;
					}
				}
				&__tips{
					font-size: 15px;
				}
				&__lp {
					flex-direction: column;

					&__li {
						width: 100%;
						margin-bottom: 20px;
					}
				}
			}

			&-foot {
				margin: auto;
				padding: 40px 20px;
				max-width: 908px;

				&__tit {
					font-size: 24px;
					color: #e5bf7e;
					padding: 0 0 24px;
					border-bottom: 1px dotted #e5bf7e;
					margin-bottom: 30px;
					display: flex;
					align-items: center;

					.img {
						padding-top: 2px;

						img {
							margin-right: 10px;
							width: 18px;
							height: 18px;
						}
					}
				}

				&__li {
					color: #f7f7f7;
					font-size: 16px;
					margin-bottom: 15px;
					font-family: HiraginoSansGB-W3;
					letter-spacing: 1px;
					line-height: 26px;
				}

				&__info {
					color: #e5c07f;
					font-size: 20px;
					text-align: center;
					font-family: HiraginoSansGB-W3;
					letter-spacing: 3px;
					margin-top: 40px;
				}

				&__flag {
					.img {
						margin-right: 20px;

						img {
							width: inherit;
							height: inherit;
						}
					}
					.cont{
						.pad8{
							padding: 6px 0;
						}
					}
				}
			}

			&-head {
				.head-box {
					padding: 0 10px;

					&__logo {
						flex: 1;
					}

					&__tit {
						display: none;
					}

					&__btn {
						flex: 1;
						justify-content: right;

						.right-btn {
							margin-left: 0;
						}

						.left-btn {
							padding: 0.3em 0.6em;
							font-size: 18px;
						}
					}
				}
			}
			.table{
				.pag{
					font-size: 18px;
					margin-bottom: 15px;
				}
			}
			&-banner {
				padding-top: 110px;
				margin-top: 0;
				background: none;
				.pc {
					display: none;
				}

				.wap {
					display: block;
				}
			}
		}
	}
</style>
