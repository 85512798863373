<template>
	<div class="cooperative-partner" :class="$t('CFooters.className')">
		<ss-header></ss-header>
		<div class="page-screen-0">
			<div class="container-xxl">
				<img class="full-width d-md-none" src="../assets/image/CooperativePartner/img1.png" />
				<div class="page-screen-0__en-title wow animate__fadeInDown" :class="$t('CFooters.className')">
					{{$t('CooperativePartner.screen0.enTitle1')}}
				</div>
				<div :class="$t('CFooters.className')" class="page-screen-0__en-title  wow animate__fadeInDown"
					v-html="$t('CooperativePartner.screen0.enTitle2')">
				</div>
				<div :class="$t('CFooters.className')" class="page-screen-0__title  wow animate__fadeInDown">
					{{$t('CooperativePartner.screen0.title1')}}
				</div>
				<div :class="$t('CFooters.className')" class="page-screen-0__title  wow animate__fadeInDown">
					{{$t('CooperativePartner.screen0.title2')}}
				</div>
				<ul class="page-screen-0__list right-icon-list wow animate__bounceIn">
					<li v-for="(item, index) in $t('CooperativePartner.screen0.list')" :key="index">{{item}}
					</li>
				</ul>
				<div class="page-screen-0__desc wow animate__bounceIn">{{$t('CooperativePartner.screen0.desc')}}
				</div>
				<div class="page-screen-0__links  d-md-block">
					<a href="/about-us" v-for="(item, index) in $t('CooperativePartner.screen0.links')"
						:key="index">{{item.text}}</a>
				</div>
				<img class="img1 d-none d-md-block wow animate__fadeInRight"
					src="../assets/image/CooperativePartner/img1.png" />
			</div>
		</div>
		<div class="page-screen-1">
			<div class="container-xxl">
				<div class="flex dzhfw-box">
					<div class="flex-1">
						<page-title :enText="$t('CooperativePartner.screen1.pageEnTitle')"
							:text="$t('CooperativePartner.screen1.pageTitle')" />
						<div class="dzhfw__title  wow animate__fadeInDown"
							v-html="$t('CooperativePartner.screen1.title')">
						</div>
						<div class="dzhfw__desc">{{$t('CooperativePartner.screen1.desc')}}</div>
						<ul class="dzhfw__list right-icon-list wow animate__fadeIn">
							<li v-for="(item, index) in $t('CooperativePartner.screen1.list')" :key="index"
								:class="$t('CFooters.className')">
								{{item}}
							</li>
						</ul>
					</div>
					<div class="w590 wow animate__fadeInRight">
						<img src="../assets/image/CooperativePartner/img2.png" />
						<div class="w590__text" v-html="$t('CooperativePartner.screen1.text')"
							:class="$t('CFooters.className')"></div>
					</div>
				</div>
				<a class="more-serve d-none d-md-block"><span>></span>{{$t('CooperativePartner.screen1.more')}}</a>
			</div>
		</div>
		<div class="page-screen-2">
			<div class="container-xxl">
				<page-title class="text-center" :enText="$t('CooperativePartner.screen2.pageEnTitle')"
					:text="$t('CooperativePartner.screen2.pageTitle')" />
				<div class="page-screen-2__title wow animate__fadeInDown">
					{{$t('CooperativePartner.screen2.title')}}
				</div>
				<div class="page-screen-2__desc wow animate__fadeInDown">{{$t('CooperativePartner.screen2.desc')}}
				</div>
				<ul class="page-screen-2__list clearfix wow animate__fadeInDown">
					<li v-for="(item, index) in $t('CooperativePartner.screen2.list')" :key="index">
						<img :src="require(`../assets/image/CooperativePartner/icon${ index + 1}.png`)" />{{item}}
					</li>
				</ul>
				<a href="https://client.ebccrm.com/"
					class="btn btn-outline-primary d-none d-md-block">{{$t('CooperativePartner.screen2.btn')}}
				</a>
			</div>
		</div>
		<div class="page-screen-3">
			<div class="container-xxl">
				<div class="flex ldx-box">
					<div class="flex-1 wow animate__fadeInLeft">
						<img class="full-width" src="../assets/image/CooperativePartner/img3.png" />
					</div>
					<div class="ldx__text">
						<page-title :enText="$t('CooperativePartner.screen3.pageEnTitle')"
							:text="$t('CooperativePartner.screen3.pageTitle')" />
						<div class="ldx__title wow animate__fadeInDown">{{$t('CooperativePartner.screen3.title')}}
						</div>
						<div class="ldx__desc wow animate__fadeInDown">{{$t('CooperativePartner.screen3.desc')}}
						</div>
						<div class="ldx__remark d-none d-md-block wow animate__fadeInRight"
							v-html="$t('CooperativePartner.screen3.remark')">
						</div>
					</div>
				</div>
				<img class="decorate d-none d-md-block"
					src="../assets/image/ManagingYourPortfolio/gif-diverse-combination.gif" />
			</div>
		</div>
		<div class="page-screen-4">
			<div class="container-xxl">
				<div class="w690">
					<img class="full-width wow animate__fadeInRight"
						src="../assets/image/CooperativePartner/img4.png" />
				</div>
				<div class="xxyt__text">
					<page-title :enText="$t('CooperativePartner.screen4.pageEnTitle')"
						:text="$t('CooperativePartner.screen4.pageTitle')" />
					<div class="xxyt__title wow animate__fadeInDown">{{$t('CooperativePartner.screen4.title')}}
					</div>
					<div class="xxyt__desc wow animate__fadeInDown">{{$t('CooperativePartner.screen4.desc')}}
					</div>
					<div class="quick-understanding-box d-none d-md-block">
						<ul class="right-icon-list">
							<li v-for="(item, index) in $t('CooperativePartner.screen4.list')" :key="index">
								{{item}}
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<contact-us></contact-us>
		<c-footer></c-footer>
	</div>
</template>

<script>
	import WOW from 'wowjs';
	export default {
		data() {
			return {
				seoInfo: ""
			}
		},
		metaInfo() {
			return {
				title: this.seoInfo.title || '',
				meta: [ // set meta
					{
						name: 'keyWords',
						content: this.seoInfo.keyWords || ''
					},
					{
						name: 'description',
						content: this.seoInfo.description || ''
					}
				]
			}
		},
		created() {
			this.$getSeoInfo({
				lang: this.$store.state.Lan,
				path: "/partner"
			}).then(res => {
				this.seoInfo = res
			})
		},
		mounted() {
			let wow = new WOW.WOW({
				boxClass: 'wow',
				animateClass: 'animated',
				offset: 0,
				mobile: true,
				live: false
			});
			wow.init();
		},
		methods: {

		}
	}
</script>

<style lang="scss">
	.cooperative-partner {
		&.vi-box {
			font-family: "UKIJ Tuz Tom", "Open Sans", "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", Arial, Verdana, Tahoma, sans-serif;
		}

		.page-screen-4 {
			position: relative;
			padding-bottom: 190px;

			.w690 {
				width: 690px;
				margin-left: auto;
			}

			.xxyt {
				&__text {
					width: 976px;
					position: absolute;
					top: 76px;
					left: 0;
					padding: 64px 304px 55px 193px;
					background-color: rgba(227, 227, 227, 0.2);

					.quick-understanding-box {
						position: absolute;
						right: 220px;
						bottom: -100px;
						border-top: 2px solid #7d1614;
						padding: 25px 0;

						.right-icon-list {
							li {
								display: inline-block;
								margin-right: 60px;
								font-size: 16px;
								font-weight: bold;

								&::before {
									background-image: url(../assets/image/DataCenter/invalid-name@3x.png);
									width: 16px;
									height: 16px;
									top: 6px;
								}
							}
						}
					}
				}

				&__title {
					font-size: 28px;
					font-weight: bold;
					line-height: 1.57;
					color: #333333;
					margin-top: 65px;
				}

				&__desc {
					font-size: 16px;
					line-height: 1.75;
					color: #333333;
					margin-top: 25px;
				}
			}
		}

		.page-screen-3 {
			padding-top: 105px;
			position: relative;
			background: url(../assets/image/TradingInstruments/bg.png) center center / 100% auto no-repeat;

			.container-xxl {
				position: relative;
			}

			.decorate {
				position: absolute;
				top: 513px;
				right: 50px;
				width: 152px;
				display: block;
			}

			.ldx {
				&-box {
					position: relative;
					padding-bottom: 177px;
				}

				&__desc {
					font-size: 20px;
					line-height: 1.6;
					color: #8b8b8b;
					margin-top: 20px;
				}

				&__remark {
					width: 320px;
					position: absolute;
					top: 450px;
					bottom: 0;
					left: 0;
					border-top: 4px solid #e3e3e3;
					font-size: 16px;
					line-height: 1.75;
					color: #333333;
					text-align: right;
					white-space: nowrap;

					span {
						color: #7d1614;
					}
				}

				&__title {
					font-size: 28px;
					font-weight: bold;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.57;
					letter-spacing: normal;
					color: #333333;
					margin-top: 65px;
				}

				&__text {
					position: relative;
					width: 600px;
					padding-left: 80px;
				}
			}
		}

		.page-screen-2 {
			padding-top: 90px;
			padding-bottom: 90px;

			.btn-outline-primary {
				padding: 0;
				font-size: 16px;
				font-weight: bold;
				line-height: 1.75;
				text-align: center;
				color: #008aed;
				line-height: 38px;
				border-radius: 4px;
				border: solid 1px #d1d1d1;
				min-width: 128px;
				max-width: 250px;
				display: block;
				margin: 58px auto 0;
			}

			&__list {
				margin-top: 58px;

				li {
					float: left;
					width: 33.33333333%;
					text-align: center;
					font-size: 16px;
					line-height: 1.75;
					color: #333333;

					img {
						width: 70px;
						height: 70px;
						display: inline-block;
						vertical-align: middle;
					}
				}
			}

			&__title {
				font-size: 28px;
				font-weight: bold;
				line-height: 1.57;
				color: #333333;
				text-align: center;
				margin-top: 65px;
			}

			&__desc {
				font-size: 20px;
				max-width: 690px;
				margin: 0 auto;
				line-height: 1.6;
				text-align: center;
				color: #8b8b8b;
				margin-top: 20px;
			}
		}

		.page-screen-1 {
			.more-serve {
				display: block;
				width: 150px;
				text-align: center;
				font-size: 12px;
				line-height: 1.67;
				text-align: center;
				color: #a0a0a0;
				margin: 120px auto 0;

				span {
					display: block;
					transform: rotate(90deg);
				}
			}

			.dzhfw {
				&__list {
					margin-top: 58px;

					li {
						margin-top: 20px;
						min-width: 50%;
						display: block;
						float: left;
					}

					.en-box {
						width: 100%;
					}

					.th-box {
						width: 100%;
					}

					.vi-box {
						width: 100%;
					}
				}

				&-box {
					.flex-1 {
						padding-right: 160px;
					}
				}

				&__desc {
					font-size: 20px;
					line-height: 1.6;
					color: #8b8b8b;
					margin-top: 20px;
				}

				&__title {
					font-size: 28px;
					font-weight: bold;
					line-height: 1.57;
					color: #424242;
					margin-top: 65px;

					span {
						color: #9d2421;
					}
				}
			}

			.w590 {
				width: 590px;
				position: relative;

				img {
					width: 100%;
					display: block;
				}

				&__text {
					position: absolute;
					right: 500px;
					bottom: 16px;
					border-top: 2px solid #7d1614;
					width: 400px;
					font-size: 16px;
					line-height: 44px;
					color: #333333;

					span {
						color: #7d1614;
					}
				}
			}
		}

		.page-screen-0 {
			padding-top: 124px;
			padding-bottom: 140px;
			position: relative;

			.img1 {
				position: absolute;
				top: 120px;
				right: 0;
				z-index: -1;
				width: 50%;
			}

			&__en-title {
				white-space: nowrap;
				font-family: 'Source Serif Pro', Georgia, "Times New Roman", serif;
				font-size: 45px;
				color: #333333;
				margin-top: 20px;
				line-height: 1.38;
				font-weight: 600;

				&.en-box {
					font-family: 'Source Serif Pro', Georgia, "Times New Roman", serif;
				}

				&.th-box {
					font-family: 'Source Serif Pro', Georgia, "Times New Roman", serif;
				}

				&.vi-box {
					font-family: 'Source Serif Pro', Georgia, "Times New Roman", serif;
				}
			}

			&__title {
				white-space: nowrap;
				margin-top: 20px;
				font-size: 50px;
				font-weight: 600;
				line-height: 1.38;
				color: #333333;

				&.en-box {
					font-family: 'Source Serif Pro', Georgia, "Times New Roman", serif;
					font-size: 32px;
					font-weight: 100;
				}

				&.th-box {
					font-family: 'Source Serif Pro', Georgia, "Times New Roman", serif;
					font-size: 32px;
					font-weight: 100;
				}

				&.vi-box {
					font-family: 'Source Serif Pro', Georgia, "Times New Roman", serif;
					font-size: 32px;
					font-weight: 100;
				}
			}

			&__list {
				margin-top: 60px;

				li {
					display: inline-block;
					margin-right: 20px;
				}
			}

			&__desc {
				margin-top: 40px;
				font-size: 16px;
				line-height: 1.75;
				color: #333333;
				max-width: 500px
			}

			&__links {
				margin-top: 32px;

				a {
					margin-right: 24px;
					color: #3595f3;
					font-size: 12px;
					font-weight: 600;
					line-height: 1.67;
				}
			}
		}

		@media screen and (max-width: 1366px) and (min-width:1025px) {
			.page-screen-0 {
				padding-bottom: 80px;

				&__list {
					li {
						display: block;
						margin-bottom: 10px;
					}
				}

				&__desc {
					margin-top: 20px;
				}
			}

			.page-screen-4 {
				.w690 {
					width: 650px;
				}
			}

		}

		@media screen and (max-width: 1024px) and (min-width:769px) {
			.page-screen-1 {
				.w590 {
					width: 390px;

					&__text {
						position: initial;
						margin-top: 20px;
						border: none;
					}
				}
			}

			.page-screen-4 {
				padding-bottom: 220px;

				.w690 {
					width: 450px;
				}

				.xxyt__text {
					width: 794px;
					padding: 64px 304px 55px 20px;
				}
			}
		}

		@media (width: 768px) {
			.page-screen-0 {
				&__title {
					font-size: 40px;
				}

				&__en-title {
					font-size: 36px;
				}
			}

			.page-screen-1 {
				.dzhfw-box {
					.flex-1 {
						padding-right: 20px;

						.dzhfw__title {
							font-size: 24px;
						}
					}

					.w590 {
						width: 300px;

						&__text {
							right: -10px;
							bottom: -66px;
							width: 300px;

							&.en-box {
								width: 400px;
							}

							&.th-box {
								width: 400px;
							}

							&.vi-box {
								width: 400px;
							}
						}
					}
				}

				.more-serve {
					margin: 60px auto 0;
				}
			}

			.page-screen-3 {
				padding-top: 10px;

				.ldx__text {
					width: 400px;
					padding-left: 40px;
				}

				.ldx__remark {
					top: 270px;
					left: -356px;
					white-space: pre-wrap;
				}

				.ldx-box {
					padding-bottom: 50px;
				}
			}

			.page-screen-4 {
				.xxyt {
					&__text {
						width: initial;
						padding: 64px 360px 55px 20px;
					}

					&__title {
						font-size: 24px;
					}
				}

				.w690 {
					height: 450px;
					width: 340px;
					padding-top: 30px;
				}
			}
		}

		@media (max-width: 767px) {
			.page-screen-4 {
				padding-bottom: 100px;

				.w690 {
					width: 100%;
				}

				.xxyt__text {
					margin-top: 50px;
					padding: 0;
					width: 100%;
					position: relative;
					top: 0;
					background-color: transparent;

					.xxyt__title {
						font-size: 28px;
						margin-top: 57px;
					}

					.xxyt__desc {
						margin-top: 20px;
					}
				}
			}

			.page-screen-3 {
				background: none;
				padding-top: 50px;

				.flex-1 {
					width: 100%;
				}

				.page-title {
					margin-top: 50px;
				}

				.ldx-box {
					flex-direction: column;
					padding-bottom: 80px;

					.ldx__text {
						padding-left: 0;
						width: 100%;
					}

					.ldx__title {
						margin-top: 57px;
						font-size: 28px;
					}

					.ldx__desc {
						font-size: 20px;
						margin-top: 20px;
					}
				}
			}

			.page-screen-2 {
				padding-top: 50px;
				padding-bottom: 0;

				&__title {
					text-align: left;
					font-size: 28px;
					font-weight: 500;
					margin-top: 57px;
				}

				&__list {
					li {
						width: 100%;
						text-align: left;
						text-indent: 50px;

						&+li {
							margin-top: 50px;
						}
					}
				}

				.page-title {
					text-align: left !important;
				}
			}

			.page-screen-1 {
				.flex {
					flex-direction: column-reverse;
				}

				.dzhfw-box {
					.flex-1 {
						width: 100%;
						padding-right: 0;
					}

					.dzhfw__title {
						font-size: 28px;
						margin-top: 57px;
					}

					.dzhfw__list {
						margin-top: 0;
					}
				}

				.w590 {
					width: 100%;
				}

				.page-title {
					margin-top: 40px;
				}
			}

			.page-screen-0 {
				padding-top: 100px;
				padding-bottom: 40px;

				&__en-title {
					font-size: 32px;
					margin-top: 10px;
					white-space: normal
				}

				&__title {
					font-size: 32px;
					margin-top: 10px;
					white-space: normal;
				}

				&__list {
					margin-top: 15px;

					li {
						margin-top: 15px;
						margin-right: 0;
					}
				}

				&__desc {
					margin-top: 30px;
				}
			}
		}
	}
</style>