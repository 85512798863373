<template>
	<div class="video">
		<video class="video__box" muted="muted" autoplay="autoplay" loop="loop" controls id="video" playsinline="true" webkit-playsinline="true">
			<source src="../assets/image/Home/EBCfilm.mp4" type="video/mp4" v-if="lang === 'zh-box' || lang === 'ct-box' || lang === 'vi-box'">
			</source>
			<source src="../assets/image/Home/EBC-background-4M-en.mp4" v-else></source>
			<object width="" height="" type="application/x-shockwave-flash" data="myvideo.swf">
				<param name="movie" value="myvideo.swf" />
				<param name="flashvars" value="autostart=true&amp;file=myvideo.swf" />
			</object>
		</video>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				lang:'zh-box'
			}

		},
		created() {
			this.lang = this.$route.query.lang
		},
		mounted() {
			let m = document.querySelector("#video");
			m.muted = false;
		},
		methods: {

		}
	}
</script>

<style lang="scss" scoped="scoped">
	.video {
		height: 100%;
		background-color: #000;
		text-align: center;
		height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;

		&__box {
			width: 1024px;
		}
	}
</style>
