<template>
	<div class="AllProducts">
		<ss-header mode="Home" bg="rgba(38, 5, 5, 1)"></ss-header>

		<div class="page-screen-1">
			<h1 class="public-title" style="margin-top: 40px;letter-spacing: 0px;">{{$t('LeverageAndMargin.srceen1.title')}}</h1>
			<div class="container-xxl">
				<c-products></c-products>
			</div>
		</div>
		<ss-contact-us mode="all"></ss-contact-us>
		<new-footer></new-footer>
	</div>
</template>

<script>
export default {
	data() {
		return {

		}
	},
	created() { },
	methods: {

	}
}
</script>

<style lang="scss" scoped>
.AllProducts {
	.page-screen-1 {
		padding-top: 100px;
	}
}

.public-title {
	color: #000000;
	text-align: center;
	font-size: 34px;
	font-weight: 700;
	letter-spacing: 2px;
}
</style>
