import { render, staticRenderFns } from "./FreeSwap-en.vue?vue&type=template&id=64bd8f36&scoped=true&"
import script from "./FreeSwap-en.vue?vue&type=script&lang=js&"
export * from "./FreeSwap-en.vue?vue&type=script&lang=js&"
import style0 from "./FreeSwap-en.vue?vue&type=style&index=0&id=64bd8f36&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64bd8f36",
  null
  
)

export default component.exports