<template>
<div :class="$t('OfficialVerification.official-class-name')">
	<ss-header mode="Home" bg="rgb(38, 5, 5)"></ss-header>

  <div class="official1">
      <h1 class="p1">{{$t('OfficialVerification.official-page1.text1')}}</h1>
      <p class="p2">{{$t('OfficialVerification.official-page1.text2')}}</p>
      <img src="../assets/image/OfficialVerification/fth1-line.png" alt="">
    </div>

    <div class="official2">
      <div class="container">
        <div class="official-search">
          <input class="form-control" type="text" v-model="url" :placeholder="$t('OfficialVerification.official-page1.text3')">
          <button @click="verifyUrl">{{$t('OfficialVerification.official-page1.text4')}}</button>
        </div>

        <div class="official-notice">
          <p class="p1">{{$t('OfficialVerification.official-page2.text1')}}</p>
          <p class="p2">{{$t('OfficialVerification.official-page2.text2')}}</p>
          <p class="p2">{{$t('OfficialVerification.official-page2.text3')}}</p>
          <p class="p2">{{$t('OfficialVerification.official-page2.text4')}}</p>
          <p class="p2">{{$t('OfficialVerification.official-page2.text5')}}</p>
          <p class="p2" v-html="$t('OfficialVerification.official-page2.text6')"></p>
        </div>
      </div>
    </div>

    <div class="official-result-box" v-show="isOfficialResultBoxVisible">
      <input id="messageStatus" type="hidden" v-model="messageStatus">
      <div class="official-result" v-show="isOfficialResultVisible">
        <div class="result-true" v-show="isResultTrue">
          <img src="../assets/image/OfficialVerification/official-verification-icon2.svg" alt="">
          <p class="url real">{{ resultUrl }}</p>
          <p class="tips real">{{$t('OfficialVerification.official-page3.text1')}}</p>
          <div class="buttons">
            <a href="javascript:void(0);" @click="resetSearch" class="close">{{$t('OfficialVerification.official-page3.text2')}}</a>
            <a href="javascript:void(0);" @click="showReportForm" class="report real"></a>
          </div>
          <p class="tips2 real"></p>
        </div>
        <div class="result-false" v-show="!isResultTrue">
          <img src="../assets/image/OfficialVerification/official-verification-icon1.svg" alt="">
          <p class="url">{{ resultUrl }}</p>
          <p class="tips">{{$t('OfficialVerification.official-page3.text3')}}</p>
          <div class="buttons">
            <a href="javascript:void(0);" @click="resetSearch" class="close">{{$t('OfficialVerification.official-page3.text2')}}</a>
            <a href="javascript:void(0);" @click="showReportForm" class="report">{{$t('OfficialVerification.official-page3.text4')}}</a>
          </div>
          <p class="tips2">{{$t('OfficialVerification.official-page3.text5')}}</p>
        </div>
      </div>

      <div class="official-report" v-show="isOfficialReportVisible">
        <p class="title"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 26 22" fill="none">
                <path d="M13.408 6.90161C13.4289 6.71956 13.4123 6.53515 13.3592 6.35976C13.3061 6.18437 13.2177 6.0217 13.0993 5.88178C12.981 5.74186 12.8353 5.62765 12.6711 5.54618C12.507 5.46472 12.3279 5.41772 12.1449 5.40809C11.9619 5.39846 11.7788 5.42641 11.6071 5.49019C11.4353 5.55398 11.2784 5.65227 11.146 5.779C11.0136 5.90573 10.9086 6.05822 10.8374 6.22707C10.7662 6.39593 10.7303 6.57757 10.732 6.76081H10.728L11.0312 13.1568V13.1672C11.0279 13.3075 11.053 13.447 11.1048 13.5774C11.1567 13.7078 11.2343 13.8264 11.333 13.9261C11.4318 14.0259 11.5496 14.1047 11.6795 14.1578C11.8093 14.211 11.9486 14.2374 12.0889 14.2355C12.2292 14.2336 12.3677 14.2035 12.4961 14.1468C12.6245 14.0902 12.7402 14.0083 12.8362 13.906C12.9323 13.8036 13.0067 13.683 13.055 13.5512C13.1034 13.4195 13.1246 13.2794 13.1176 13.1392L13.4088 6.90161H13.408ZM8.792 2.42001C10.5768 -0.627986 13.4752 -0.621586 15.256 2.42001L23.2336 16.0392C25.0176 19.0872 23.6032 21.5568 20.064 21.5568H3.984C0.448796 21.5568 -0.966404 19.08 0.815196 16.0392L8.792 2.42001ZM12.0744 18.5352C12.2534 18.5396 12.4314 18.5081 12.5981 18.4426C12.7647 18.3771 12.9166 18.279 13.0447 18.1539C13.1729 18.0289 13.2747 17.8795 13.3442 17.7145C13.4137 17.5495 13.4496 17.3723 13.4496 17.1932C13.4496 17.0142 13.4137 16.8369 13.3442 16.6719C13.2747 16.5069 13.1729 16.3575 13.0447 16.2325C12.9166 16.1074 12.7647 16.0093 12.5981 15.9438C12.4314 15.8783 12.2534 15.8468 12.0744 15.8512C11.7241 15.8598 11.3911 16.0049 11.1464 16.2556C10.9017 16.5064 10.7648 16.8429 10.7648 17.1932C10.7648 17.5436 10.9017 17.88 11.1464 18.1308C11.3911 18.3815 11.7241 18.5267 12.0744 18.5352Z" fill="#CE0606"/>
                </svg>
                {{$t('OfficialVerification.official-page3.text6')}}</p>
        <div class="form-box-head">
          <p class="form-label mt110">{{$t('OfficialVerification.official-page3.text7')}}</p>
          <div class="row">
            <div class="col-md-12"><input id="report-url" name="report-url" type="text" v-model="reportUrl" class="form-control"></div>
          </div>
          <p class="message-tips message-open-tips" v-show="!isFormBoxVisible">{{$t('OfficialVerification.official-page3.text8')}}<a href="javascript:void(0);" @click="toggleFormBox">{{$t('OfficialVerification.official-page3.text8-1')}}</a>{{$t('OfficialVerification.official-page3.text8-2')}}</p>
          <p class="message-tips message-close-tips" v-show="isFormBoxVisible"><a href="javascript:void(0);" @click="toggleFormBox">{{$t('OfficialVerification.official-page3.text9')}}</a></p>
        </div>
        <div class="form-box" v-show="isFormBoxVisible">
          <p class="form-label mt110">{{$t('OfficialVerification.official-page3.text10')}}</p>
          <div class="row">
            <div class="col-md-12"><input id="name" name="name" type="text" v-model="name" class="form-control" :placeholder="$t('OfficialVerification.official-page3.text11')"></div>
          </div>
          <p class="form-label">{{$t('OfficialVerification.official-page3.text12')}}</p>
          <div class="row">
            <div class="col-md-12"><input id="phone-email" name="email" type="text" v-model="email" class="form-control" :placeholder="$t('OfficialVerification.official-page3.text13')"></div>
          </div>
          <p class="form-label">{{$t('OfficialVerification.official-page3.text14')}}</p>
          <div class="row">
            <div class="col-md-12"><input id="phone" name="phone" type="text" v-model="phone" class="form-control" :placeholder="$t('OfficialVerification.official-page3.text15')"></div>
          </div>
          <p class="form-label">{{$t('OfficialVerification.official-page3.text16')}}</p>
          <div class="row">
            <div class="col-md-12"><input id="message_content" name="message_content" type="text" v-model="messageContent" class="form-control" :placeholder="$t('OfficialVerification.official-page3.text17')"></div>
          </div>
        </div>

        <p class="form-label">{{$t('OfficialVerification.official-page3.text18')}}</p>
        <div class="row">
          <div class="col-md-12">
            <div class="col-md-7 verify-input" style="float: left;">
              <input id="verify" style="width: 100%;" type="text" v-model="code" class="form-control" :placeholder="$t('OfficialVerification.official-page3.text19')" required>
            </div>
            <div class="col-md-3 verify-code" style="float: left;">
            <div @click="refreshCode">
              <ss-code :identifyCode="identifyCode"></ss-code>
            </div>
            </div>
          </div>
        </div>
        <div class="buttons">
          <a href="javascript:void(0);" @click="resetSearch" class="close">{{$t('OfficialVerification.official-page3.text20')}}</a>
          <a href="javascript:void(0);" @click="handleSubmit" class="contact-submit">{{$t('OfficialVerification.official-page3.text21')}}</a>
        </div>
      </div>

      <div class="official-report-success" v-show="isOfficialReportSuccessVisible">
        <img src="../assets/image/OfficialVerification/official-verification-icon3.svg" alt="">
        <p>{{$t('OfficialVerification.official-page3.text22')}}</p>
        <a href="javascript:void(0);" @click="resetSearch" class="close">{{$t('OfficialVerification.official-page3.text2')}}</a>
      </div>
    </div>
      <new-footer></new-footer>
</div>
</template>

<script>
import 'swiper/css/swiper.css'
import axios from 'axios'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      url: '',
      lang: this.$t('OfficialVerification.lang'),
      messageStatus: 0,
      isOfficialResultBoxVisible: false,
      isOfficialResultVisible: false,
      isResultTrue: false,
      resultUrl: '',
      isOfficialReportVisible: false,
      isFormBoxVisible: false,
      reportUrl: '',
      name: '',
      email: '',
      phone: '',
      messageContent: '',
      isOfficialReportSuccessVisible: false,
      code: null,
			identifyCodes: "1234567890abcdefjhijklinopqrsduvwxyz", //随机串内容,从这里随机抽几个显示验证码
			identifyCode: "", //验证码图片内容
    };
  },
  mounted() {
			// 初始化验证码
			this.identifyCode = "";
			//参数：（1）随机串内容。（2）验证码显示位数
			this.makeCode(this.identifyCodes, 4);
		},
  methods: {
    // 重置验证码
			refreshCode() {
				this.identifyCode = "";
				this.makeCode(this.identifyCodes, 4);
			},
			//获取验证码的值
			makeCode(o, l) {
				for (let i = 0; i < l; i++) {
					//通过循环获取字符串内随机几位
					this.identifyCode +=
						this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
				}
			},
			//随机数字：用于当角标拿字符串的值
			randomNum(min, max) {
				return Math.floor(Math.random() * (max - min) + min);
			},
    verifyUrl() {
      const langMessages = {
        cn: "请输入完整网站链接",
        en: "Please enter the full website URL",
        tw: "請輸入完整網站鏈接",
        ja: "完全なウェブサイトのURLを入力してください",
        ko: "전체 웹사이트 URL을 입력하십시오",
        th: "กรุณาใส่ URL ของเว็บไซต์ให้ครบถ้วน",
        vn: "Vui lòng nhập URL đầy đủ của trang web",
        ug: "تولۇق تور بەت ئادرېسىنى كىرگۈزۈڭ",
      };

      const langMessages2 = {
          cn: "请输入有效的网站链接",
          en: "Please enter a valid website URL",
          tw: "請輸入有效的網站鏈接",
          ja: "有効なウェブサイトのリンクを入力してください",
          ko: "유효한 웹사이트 링크를 입력하십시오",
          th: "กรุณาใส่ลิงก์ของเว็บไซต์ที่ถูกต้อง",
          vn: "Vui lòng nhập liên kết hợp lệ của trang web",
          ug: "ئىناۋەتلىك تور بەت ئۇلىنىشىنى كىرگۈزۈڭ",
      };

      if (this.url === '') {
        this.showAlert(langMessages[this.lang] || "Please enter the full website URL")
        return;
      }
      if (!this.isValidDomain(this.url)) {
          this.showAlert(langMessages2[this.lang] || "Please enter a valid website URL")
          return;
      }
      this.$http('https://www.ebccfd.com/VerificationAuthenticity', {
        url: this.url,
        lang: this.lang,
      })
      .then((response) => {
        const da = response.data;
        this.showResultBox = true; // 显示结果盒子
        this.showReport = false; // 隐藏报告
        this.showReportSuccess = false; // 隐藏报告成功信息
        this.showOfficialResult = true; // 显示官方结果
        if (da.code === 1) {
          this.isOfficialResultBoxVisible = true;
          this.isOfficialResultVisible = true;
          this.isResultTrue = true;
          this.resultUrl = da.url;
          this.reportUrl = da.url;
        } else {
          this.isOfficialResultBoxVisible = true;
          this.isOfficialResultVisible = true;
          this.isResultTrue = false;
          this.resultUrl = da.url;
          this.reportUrl = da.url;
        }
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });
    },
    resetSearch() {
      this.url = '';
      this.isOfficialResultBoxVisible = false;
      this.isOfficialResultVisible = false;
      this.isOfficialReportVisible = false;
      this.isFormBoxVisible = false;
      this.isOfficialReportSuccessVisible = false;
    },
    showReportForm() {
      this.isOfficialReportVisible = true;
    },
    toggleFormBox() {
      this.isFormBoxVisible = !this.isFormBoxVisible;
      this.messageStatus = this.isFormBoxVisible ? 1 : 0;
    },
    setMessages() {
      const langMessages = {
        cn: {
          msg_report_url: "请输入网站地址",
          msg_name: "请输入您的称呼!",
          msg_email: "请输入您的邮箱!",
          msg_phone: "请输入您的手机号!",
          msg_verify: "请输入验证码!",
          msg_message_content: "请输入信息反馈"
        },
        en: {
          msg_report_url: "Please enter the website address",
          msg_name: "Please enter your name",
          msg_email: "Please enter your email",
          msg_phone: "Please enter your phone number",
          msg_message_content: "Please enter your feedback",
          msg_verify: "Please enter the verification code",
          msg_verify_error: "Verification code error, please re-enter"
        },
        tw: {
          "msg_report_url": "請輸入網站地址",
          "msg_name": "請輸入您的稱呼!",
          "msg_email": "請輸入您的郵箱!",
          "msg_phone": "請輸入您的手機號!",
          "msg_verify": "請輸入驗證碼!",
          "msg_message_content": "請輸入信息反饋"
        },
        ja: {
          "msg_report_url": "ウェブサイトのアドレスを入力してください",
          "msg_name": "お名前を入力してください!",
          "msg_email": "メールアドレスを入力してください!",
          "msg_phone": "電話番号を入力してください!",
          "msg_verify": "確認コードを入力してください!",
          "msg_message_content": "フィードバックを入力してください"
        },
        ko: {
          "msg_report_url": "웹사이트 주소를 입력하세요",
          "msg_name": "이름을 입력하세요!",
          "msg_email": "이메일을 입력하세요!",
          "msg_phone": "전화번호를 입력하세요!",
          "msg_verify": "인증 코드를 입력하세요!",
          "msg_message_content": "피드백을 입력하세요"
        },
        th: {
          "msg_report_url": "กรุณาใส่ที่อยู่เว็บไซต์",
          "msg_name": "กรุณาใส่ชื่อของคุณ!",
          "msg_email": "กรุณาใส่อีเมลของคุณ!",
          "msg_phone": "กรุณาใส่หมายเลขโทรศัพท์ของคุณ!",
          "msg_verify": "กรุณาใส่รหัสยืนยัน!",
          "msg_message_content": "กรุณาใส่ข้อความของคุณ"
        },
        vn: {
          "msg_report_url": "Vui lòng nhập địa chỉ trang web",
          "msg_name": "Vui lòng nhập tên của bạn!",
          "msg_email": "Vui lòng nhập email của bạn!",
          "msg_phone": "Vui lòng nhập số điện thoại của bạn!",
          "msg_verify": "Vui lòng nhập mã xác minh!",
          "msg_message_content": "Vui lòng nhập phản hồi của bạn"
        },
        ug: {
          "msg_report_url": "تور بېتى ئادىرسىنى كىرگۈزۈڭ",
          "msg_name": "ئىسمىڭىزنى كىرگۈزۈڭ!",
          "msg_email": "ئېلخەت ئادرېسىڭىزنى كىرگۈزۈڭ!",
          "msg_phone": "يانفون نومۇرىڭىزنى كىرگۈزۈڭ!",
          "msg_verify": "دەلىللەش كودىنى كىرگۈزۈڭ!",
          "msg_message_content": "پىكىر-بايانىڭىزنى كىرگۈزۈڭ"
        },
      };
      this.messages = langMessages[this.lang] || langMessages.en;
    },
    showAlert(msg) {
      const alertDiv = document.createElement('div');
      alertDiv.id = "contact-alert";
      alertDiv.style.cssText = 'font-family: siyuan; padding: 6px 20px; color: rgb(255, 255, 255); text-align: center; border-radius: 4px; position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 999999; background: rgba(0, 0, 0, 0.7); font-size: 16px; transition: -webkit-transform 0.5s ease-in 0s, opacity 0.5s ease-in 0s;';
      alertDiv.textContent = msg;
      document.body.appendChild(alertDiv);
      setTimeout(() => document.body.removeChild(alertDiv), 2500);
    },
    clearForm() {
      this.reportUrl = '';
      this.name = '';
      this.email = '';
      this.phone = '';
      this.messageContent = '';
      this.code = '';
    },
    isValidDomain(domain) {
        const regex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/;
        return regex.test(domain);
    },
    async handleSubmit() {
      this.setMessages();
      const {
        msg_report_url,
        msg_name,
        msg_email,
        msg_phone,
        msg_verify,
        msg_message_content
      } = this.messages;

      if (this.reportUrl === '') {
        this.showAlert(msg_report_url);
        return;
      }

      if (this.identifyCode != this.code) {
					this.code = null
					return this.$toast(this.$t('CFooters.contact.tips4'));
				}

      if (this.messageStatus === 0) {

        try {
          this.$http('https://www.ebccfd.com/VerificationAuthenticity/Report/', {
            report_url: this.reportUrl,
            lang: this.lang,
          }).then((response) => {
              const da = response.data;
              if (da.code === 1) {
                this.isOfficialReportVisible = false;
                this.isOfficialReportSuccessVisible = true;
                this.clearForm();
                this.refreshCode();
              } else {
                this.showAlert(da.msg);
              }
          })
        } catch (error) {
          this.showAlert('Error');
        }
      } else {
        if (this.name === '') {
          this.showAlert(msg_name);
          return;
        }
        if (this.email === '') {
          this.showAlert(msg_email);
          return;
        }
        if (this.phone === '') {
          this.showAlert(msg_phone);
          return;
        }
        if (this.messageContent === '') {
          this.showAlert(msg_message_content);
          return;
        }

        try {
          this.$http('https://www.ebccfd.com/VerificationAuthenticity/ReportMessage/', {
            report_url: this.reportUrl,
            name: this.name,
            email: this.email,
            lang: this.lang,
            phone: this.phone,
            message_content: this.messageContent
          }).then((response) => {
              const da = response.data;
              if (da.code === 1) {
                this.isOfficialReportVisible = false;
                this.isOfficialReportSuccessVisible = true;
                this.clearForm();
                this.refreshCode();
              } else {
                this.showAlert(da.msg);
              }
          })
        } catch (error) {
          this.showAlert('Error');
        }
      }
    }
  }
}
</script>

<style>
@charset "UTF-8";
.c-footer .fp-overflow{
		display: unset !important;
	}
.official1{
  background: url(../assets/image/OfficialVerification/official-verification-bg.jpg) 100%/auto 100% no-repeat;
  height: 300px;
  margin-top: 0px;
  text-align: center;
  padding-top: 110px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.official1 img {
    margin-top: 26px;
    width: 330px;
    margin-bottom: 14px;
}
.official1 .p1{
    color: #FFF;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    margin-top: 19px;
    letter-spacing: 2px;
}
.official1 .p2{
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1px;
    width: 76%;
    margin: 0 auto;
    line-height: 36px;
    opacity: 0.9;
    margin-top: 20px;
    font-family: auto;
}

.official2{
  margin-top: 60px;
  margin-bottom: 80px;
}
.official2 .container{
  width: 900px;
}
.official-search{
  text-align: center;
}
.official-search input{
  width: 74%;
  border: 1.5px solid #E2E2E2;
  padding: 16px 26px;
  font-family: auto;
  display: inline-block;
  color: #000 !important;
}
.official-search input:focus{
  background: none;
  box-shadow: none;
  border: 1.5px solid #E2E2E2;
}

.official-search button{
  padding: 21px 34px;
  border-radius: 4px;
  border: 1px solid #000;
  background: #4D0101;
  color: #FFF;
  font-size: 16px;
  font-family: auto;
  display: inline-block;
  margin-left: 3%;
}

.official-notice{
  width: 92%;
  margin: 60px auto 0;
}
.official-notice .p1{
  color: #333;
  font-family: auto;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 30px;
}
.official-notice .p2{
  color: rgba(102, 102, 102, 0.70);
  font-family: auto;
  font-size: 14px;
  margin-top: 16px;
  line-height: 20px;
}
.official-notice .p2 i{
  font-weight: bold; 
  font-size: 16px;
  margin: 0 8px;
}

.official-result-box{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
.official-result{
  width: 560px;
  padding: 60px;
  background-color: #ffffff;
  border-radius: 5px;
  text-align: center;
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.official-result img{
  width: 26%;
}
.official-result .url{
  border: 1px solid #CE0606;
  display: block;
  padding: 15px;
  color: #CE0606;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-size: 16px;
  font-family: auto;
  font-weight: bold;
  margin-top: 40px;
  overflow: auto;
}
.official-result .url.real{
  border: 1px solid #66B54E;
  color: #66B54E;
}
.official-result .tips{
  color: #900404;
  text-align: center;
  font-size: 14px;
  font-family: auto;
  font-weight: 600;
  margin-top: 14px;
  line-height: 20px;
}
.official-result .tips.real{
  color: #66B54E;
}
.official-result .close{
  display:  inline-block;
  width: 32%;
  padding: 18px;
  border-radius: 4px;
  background: #EFEFF0;
  color: #3D0101;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  font-family: auto;
  margin-top: 40px;
}
.official-result .close.real{
  width: 100%;
}
.official-result .report{
  display: inline-block;
  width: 60%;
  padding: 18px;
  border-radius: 4px;
  border: 1px solid #000;
  background: #4D0101;
  color: #FFF;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  font-family: auto;
  margin-top: 40px;
  margin-left: 6%;
}
.official-result .report.real{
  display: none;
}
.official-result .tips2{
  color: #949699;
  text-align: center;
  font-size: 14px;
  font-family: auto;
  margin-top: 14px;
  line-height: 20px;
}
.official-result .tips2.real{
  display: none;
}


.official-report{
  width: 560px;
  max-height: 600px;
  padding: 60px;
  background-color: #ffffff;
  border-radius: 5px;
  text-align: center;
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
}
.official-report svg{
  margin-right: 8px;
}
.official-report .title{
  color: #333;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: auto;
  font-size: 22px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 40px;
}
.official-report .close{
  display:  inline-block;
  width: 47%;
  padding: 18px;
  border-radius: 4px;
  background: #EFEFF0;
  color: #3D0101;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  font-family: auto;
  margin-top: 40px;
}
.official-report .contact-submit{
  display: inline-block;
  width: 47%;
  padding: 18px;
  border-radius: 4px;
  border: 1px solid #000;
  background: #4D0101;
  color: #FFF;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  font-family: auto;
  margin-top: 40px;
  margin-left: 4%;
}
.official-report .form-label{
  color: #666;
  font-size: 16px;
  text-align: left;
  font-family: auto;
  margin-top: 30px;
  margin-bottom: 12px;
}
.official-report input{
  border: 1px solid #E2E2E2;
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #000 !important;
  font-family: auto;
  font-size: 14px;
}
.official-report input:focus{
  background: none;
  box-shadow: none;
  border: 1px solid #E2E2E2;
}
.official-report .message-tips{
  color: #900404;
  font-family: auto;
  font-size: 14px;
  margin-top: 20px;
  line-height: 20px;
}
.official-report .message-tips a{
  font-weight: bold;
  color: #900404;
  text-decoration: underline;
}
.official-report .message-close-tips a{
  font-weight: bold;
  color: #900404;
  background: linear-gradient(180deg, rgba(115, 115, 115, 0.10) 0%, rgba(217, 217, 217, 0.00) 100%);
  width: 100%;
  display: block;
  padding: 16px 10px;
  text-decoration: underline;
}

.official-report-success{
  width: 560px;
  padding: 60px;
  background-color: #ffffff;
  border-radius: 5px;
  text-align: center;
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.official-report-success p{
  color: #66B54E;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: auto;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-top: 30px;
}
.official-report-success .close{
  display:  inline-block;
  width: 47%;
  padding: 18px;
  border-radius: 4px;
  background: #EFEFF0;
  color: #3D0101;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  font-family: auto;
  margin-top: 40px;
}

.official-en-box .official1 .p1{
  letter-spacing: 0px;
  font-size: 30px;
}

.official-en-box .official1 .p2{
  letter-spacing: 0px;
  font-size: 16px;
  width: 66%;
  opacity: 0.8;
  line-height: 28px;
}
.official-en-box .official1{
  height: auto;
  padding-bottom: 36px;
}




@media(max-width:995px) {
  .official1 {
      height: auto;
      margin-top: 0px;
      text-align: center;
      padding-top: 110px;
      padding-bottom: 60px;
  }
  .official1 .p1{
      font-size: 26px;
  }
  .official2 .container{
    width: 100%;
  }
  .official1 .p2{
    line-height: 30px;
    font-size: 14px;
  }
  .official-search input{
    width: 92%;
    padding: 12px 22px;
  }
  .official-search button{
    padding: 16px 40px;
    margin-left: 0;
    margin-top: 30px;
  }
  .official2{
    margin-top: 40px;
    margin-bottom: 50px;
  }
  .official-notice .p1{
    margin-bottom: 22px;
  }
  .official-notice .p2 i{
    display: none;
  }
  .official-result,.official-report,.official-report-success{
    width: 95%;
    padding: 60px 5%;
  }
  .official-result .tips{
    line-height: 20px;
  }
  .official-result .close,.official-result .report{
    display: block;
    width: 100%;
    margin-left: 0px;
  }
  .official-result .report{
    margin-top: 20px;
  }
  .official-report .verify-input{
    width: 58%;
    margin-right: 2%;
  }
  .official-report .message-tips{
    font-size: 13px;
  }
  .official-report-success p{
    font-size: 20px;
  }
  .official-en-box .official1 .p1{
    font-size: 22px;
  }
  .official-en-box .official1 .p2{
    width: 93%;
    font-size: 14px;
    line-height: 28px;
  }
  .official-en-box .official-report-success p{
    font-size: 16px;
    line-height: 22px;
  }
}
</style>

