<template>
<div :class="$t('malaria.malaria-class-name')">
	<ss-header mode="Home" bg="rgb(38, 5, 5)"></ss-header>

	<div class="ubm-1">
          <div class="container">
              <img src="../assets/image/malaria/ubm-logo.png" alt="">
              <p v-html="$t('malaria.malaria-page1.text1')"></p>
              <p v-if="$t('malaria.malaria-page1.text2')">{{$t('malaria.malaria-page1.text2')}}</p>
          </div>
      </div>

      <div class="ubm-2">
          <div class="container">
              <p class="p1" v-html="$t('malaria.malaria-page2.text1')"></p>
              <p class="p2" v-html="$t('malaria.malaria-page2.text2')"></p>
              <ul>
                <li>
                  <p v-html="$t('malaria.malaria-page2.text3')"></p>
                  <span>{{$t('malaria.malaria-page2.text4')}}</span>
                </li>
                <li>
                  <p v-html="$t('malaria.malaria-page2.text5')"></p>
                  <span>{{$t('malaria.malaria-page2.text6')}}</span>
                </li>
                <li>
                  <p v-html="$t('malaria.malaria-page2.text7')"></p>
                  <span>{{$t('malaria.malaria-page2.text8')}}</span>
                </li>
              </ul>
              <p class="p3">{{$t('malaria.malaria-page2.text9')}}</p>
          </div>
      </div>

      <div class="ubm-3">
          <div class="container">
              <img src="../assets/image/malaria/ubm-logo.png" alt="">
              <p class="p1" v-html="$t('malaria.malaria-page3.text1')"></p>
              <p class="p2" v-html="$t('malaria.malaria-page3.text2')"></p>
              <ul>
                <li>
                  <p v-html="$t('malaria.malaria-page3.text3')"></p>
                  <span>{{$t('malaria.malaria-page3.text4')}}</span>
                </li>
                <li>
                  <p v-html="$t('malaria.malaria-page3.text5')"></p>
                  <span>{{$t('malaria.malaria-page3.text6')}}</span>
                </li>
                <li>
                  <p v-html="$t('malaria.malaria-page3.text7')"></p>
                  <span>{{$t('malaria.malaria-page3.text8')}}</span>
                </li>
              </ul>
          </div>
      </div>

      <div class="ubm-4">
          <div class="container">
              <img src="../assets/image/malaria/ubm-pc4.png" alt="">
              <p class="p1">{{$t('malaria.malaria-page4.text1')}}</p>
              <p class="p2" v-html="$t('malaria.malaria-page4.text2')"></p>
              <a href="https://fundraise.unfoundation.org/team/566921" target="_blank" rel="nofollow">{{$t('malaria.malaria-page4.text3')}}</a>
          </div>
      </div>

      <div class="ubm-5">
          <div class="container">
              <p class="p1">{{$t('malaria.malaria-page5.text1')}}</p>
              <div class="row">
                  <div class="col-md-6" v-for="(item, index) in newData" :key="index" v-if="item.tips != '' && index < 6"
						@click="jumPage(item)">
                    <a @click="jumPage(item)">
                      <img :src="item.logo" :alt="item.tit">
                    </a>
                    <p class="time">{{item.time}}</p>
                    <a @click="jumPage(item)" class="title">{{item.tit}}</a>
                  </div>
              </div>
          </div>
      </div>
      <new-footer></new-footer>
</div>
</template>

<script>
    import 'swiper/css/swiper.css'
	import {
		mapState,
	} from 'vuex'
	export default {
		head() {
			return {
				title: this.title1,
				meta: [ // set meta
					{
						name: 'keyWords',
						content: 'EBC,EBC平台,EBC官网,EBC金融官网,EBC开户,EBC金融'
					},
					{
						name: 'description',
						content: 'EBC金融官网是由EBC金融集团EBC Financial Group成立的,主要提供,EBC金融,EBC开户交易服务,只需一个EBC综合平台账户,即可交易多种差价合约（CFD）:衍生品、美股、数字货币、期货指数、贵金属和能源等.'
					}
				]
			}
		},
		data() {
			return {
				infoData: "",
				newData: "",
                pagIndex: 0,
                num: 3,
                newsdisplay: "none"
			}
		},
		computed: {
			...mapState({
				lang: state => state.Lan,
			})
		},
        destroyed() {
			window.removeEventListener('scroll', this.scroll, false)
		},
		created() {
			this.getNewData()
            $('html,body').animate({
				scrollTop: 0
			}, 0);
		},
        mounted(){
        },
		methods: {
			getNewData() {
				let parms;
				if (this.lang === 'zh_CN') {
					parms = 'zh_CN'
				} else {
					parms = 'en_US'
				}
				this.$http("/ebc/api/getLearnCategory/run", {
					lang: parms,
				}).then(res => {
					this.newData = res.data.data[0].info
				})
			},
			jumPage(idx) {
				this.$router.push({
					name: 'LearningCenterInfo',
					query: {
						id: idx.id,
						lang: this.$store.state.Lan
					}
				})
			},
            isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				);
				return flag;
			}
		}
	}

</script>

<style>
@charset "UTF-8";
.c-footer .fp-overflow{
		display: unset !important;
	}
  .ubm-1{
  background: url(../assets/image/malaria/ubm-pc1.jpg) 100% / auto 100% no-repeat;
  margin-top: 0px;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ubm-1 img{
  margin-bottom: 90px;
  width: 62%;
}
.ubm-1 p{
  color: #FFF;
  text-align: center;
  font-family: "Source Han Sans CN";
  font-size: 47px;
  font-weight: 700;
  line-height: 150%;
}
.ubm-1 p br{
  display: none;
}

.ubm-2{
  background: url(../assets/image/malaria/ubm-pc2.jpg) 100% / auto 100% no-repeat;
  margin-top: 0px;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ubm-2 .p1{
  color: #FFF;
  text-align: center;
  font-family: "Source Han Sans CN";
  font-size: 47px;
  font-weight: 700;
  line-height: 150%;
}
.ubm-2 .p2{
  color: #FFF;
  text-align: center;
  font-family: "Source Han Sans CN";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 200%;
  margin-top: 30px;
}
.ubm-2 ul{
  display: block;
  margin: 100px auto 0;
  width: max-content;
}
.ubm-2 ul li{
  display: flex;
  padding: 36px 72px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border-radius: 170px;
  border: 1px solid #FFF;
  width: max-content;
  float: left;
  margin: 0 40px 100px;

}
.ubm-2 ul li p{
  color: #FFF;
  font-family: "Source Han Sans CN";
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
}
.ubm-2 ul li i{
  font-size: 26px;
}
.ubm-2 ul li span{
  color: #FFF;
  font-family: "Source Han Sans CN";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.ubm-2 .p3{
  clear: both;
  color: #FFF;
  text-align: center;
  font-family: "Source Han Sans CN";
  font-size: 14px;
  opacity: 0.6;
  width: 100%;
  display: block;
}


.ubm-3{
  background: url(../assets/image/malaria/ubm-pc3.jpg) 100% / auto 100% no-repeat;
  margin-top: 0px;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ubm-3 .p1{
  color: #FFF;
  text-align: center;
  font-family: "Source Han Sans CN";
  font-size: 47px;
  font-weight: 700;
  line-height: 150%;
  margin-top: 40px;
}
.ubm-3 img{
  width: 62%;
}
.ubm-3 .p2{
  color: #FFF;
  text-align: center;
  font-family: "Source Han Sans CN";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 200%;
  margin-top: 30px;
}
.ubm-3 ul{
  display: block;
  margin: 80px auto 0;
  width: max-content;
}
.ubm-3 ul li{
  display: flex;
  padding: 36px 72px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border-radius: 170px;
  border: 1px solid #FFF;
  width: max-content;
  float: left;
  margin: 0 40px;

}
.ubm-3 ul li p{
  color: #FFF;
  font-family: "Source Han Sans CN";
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
}
.ubm-3 ul li i{
  font-size: 26px;
}
.ubm-3 ul li span{
  color: #FFF;
  font-family: "Source Han Sans CN";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}

.ubm-4{
  background: #FEFAED;
  height: auto;
  padding-bottom: 170px;
}
.ubm-4 .container{
  width: 1100px;
  position: relative;
}
.ubm-4 .p1{
  position: absolute;
  color: #163C93;
  font-family: "Source Han Sans CN";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  bottom: 100px;
  left: 52px;
}
.ubm-4 .p2{
  position: absolute;
  color: #003B99;
  font-family: "Source Han Sans CN";
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: 150%;
  width: 58%;
  bottom: 0px;
  left: 52px;
}
.ubm-4 a{
  color: #003B99;
  font-family: "Source Han Sans CN";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.8px;
  padding: 20px 40px;
  position: absolute;
  bottom: -90px;
  left: 52px;
  border-radius: 40px;
  border: 1px solid #003B99;
}
.ubm-4 a:hover{
  background: #003B99;
  color: #fff;
}


.ubm-5{
  background: url(../assets/image/malaria/ubm-pc5.jpg) 100% / auto 100% no-repeat;
  margin-top: 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ubm-5 .p1{
  color: #1C1C1C;
  text-align: center;
  font-family: "Source Han Sans CN";
  font-size: 47px;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 80px;
}
.ubm-5 .row div{
  margin-bottom: 24px;
}
.ubm-5 a img{
  width: 40%;
  height: auto;
  border-radius: 5px;
  float: left;
}
.ubm-5 .time{
  color: #1C1C1C;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 5px;
  border: 1px solid #CECECE;
  width: max-content;
  float: left;
  padding: 5px 14px;
  margin-top: 23px;
  margin-left: 20px;
}
.ubm-5 .title{
  float: left;
  width: 52%;
  color: #1C1C1C;
  font-family: "Source Han Sans CN";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  margin-top: 20px;
  margin-left: 20px;
  overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
.ubm-5 .title:hover{
  color: #003B99;
}

.en-ubm-box .ubm-1 p,.en-ubm-box .ubm-2 .p1,.en-ubm-box .ubm-3 .p1,.en-ubm-box .ubm-5 .p1{
  font-size: 32px;
}
.en-ubm-box .ubm-1 p{
  font-size: 34px;
}
.en-ubm-box .ubm-2 .p2,.en-ubm-box .ubm-3 .p2{
  opacity: 0.9;
  font-size: 16px;
  padding: 0 3%;
}
.en-ubm-box .ubm-2 ul li p,.en-ubm-box .ubm-3 ul li p{
  font-size: 40px;
}
.en-ubm-box .ubm-2 ul li span,.en-ubm-box .ubm-3 ul li span{
  font-size: 16px;
}
.en-ubm-box .ubm-4{
  height: auto;
  padding-bottom: 170px;
}
.en-ubm-box .ubm-4 .p1{
  width: 58%;
  font-size: 20px;
}
.en-ubm-box .ubm-4 .p2{
  font-size: 14px;
}
.id-ubm-box .ubm-4 .p1{
  width: 70%;
}
.id-ubm-box .ubm-4 .p2{
  width: 70%;
}


@media(max-width:1700px) {
    .ubm-1{
      padding-top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .ubm-1 img{
      margin-bottom: 50px;
    }
    .ubm-1 p{
      font-size: 40px;
    }
    .ubm-2 .p1{
      font-size: 40px;
    }
    .ubm-3 .p1{
      font-size: 40px;
    }
    .ubm-5 .p1{
      font-size: 40px;
    }
    .ubm-2 ul li p{
      font-size: 40px;
    }
    .ubm-2 ul li i{
      font-size: 20px;
    }
    .ubm-2 ul li span{
      font-size: 20px;
    }
    .ubm-2 ul{
      margin-top: 60px;
    }
    .ubm-2{
      height: auto;
      padding: 110px 0;
    }
    .ubm-3{
      height: auto;
      padding: 110px 0;
    }
    .ubm-3 ul li p{
      font-size: 40px;
    }
    .ubm-3 ul li i{
      font-size: 20px;
    }
    .ubm-3 ul li span{
      font-size: 20px;
    }
    .ubm-3 ul{
      margin-top: 60px;
    }
    .ubm-2 .p2,.ubm-3 .p2{
      font-size: 17px;
    }
    .ubm-4{
      height: auto;
      padding: 0px 0 110px;
    }
    .ubm-4 .container{
      width: 950px;
    }
    .ubm-4 .p1{
      font-size: 26px;
      bottom: 97px;
    }
    .ubm-4 .p2{
      font-size: 14px;
      bottom: 20px;
    }
    .ubm-4 a{
      bottom: -60px;
      font-size: 14px;
    }
    .ubm-5{
      height: auto;
      padding: 90px 0;
    }
    .en-ubm-box .ubm-2 ul li,.en-ubm-box .ubm-3 ul li{
      margin: 0 20px;
      padding: 28px 56px;
    }
    .en-ubm-box .ubm-2 ul li{
      margin-bottom: 60px;
    }
    .en-ubm-box .ubm-2 ul li p,.en-ubm-box .ubm-3 ul li p{
      font-size: 36px;
    }
    .en-ubm-box .ubm-2 ul li span,.en-ubm-box .ubm-3 ul li span{
      font-size: 14px;
    }
    .en-ubm-box .ubm-4 .container{
      width: 1100px;
    }
    .en-ubm-box .ubm-4 .p2{
      bottom: -3px;
    }
    .en-ubm-box .ubm-4 a{
      bottom: -86px;
    }
}

@media(max-width:995px) {
  .ubm-4 .container{
    width: 100%;
  }
  .ubm-1{
    background: url(../assets/image/malaria/ubm-m1.jpg) 100% / auto 100% no-repeat;
    margin-top: 0px;
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 100vh;
  }
  .ubm-1 img{
    margin-bottom: 60px;
  }
  .ubm-1 p{
    font-size: 32px;
  }
  .ubm-1 p br{
    display: block;
  }
  .ubm-1 p:nth-of-type(2){
    display: none;
  }

  .ubm-2{
    background: url(../assets/image/malaria/ubm-m2.jpg) 100% / auto 100% no-repeat;
    margin-top: 0px;
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 100vh;
    padding: 0 2%;
  }
  .ubm-2 .p1{
    font-size: 26px;
  }
  .ubm-2 .p2, .ubm-3 .p2{
    font-size: 14px;
    font-weight: normal;
  }
  .ubm-2 ul{
    width: 100%;
  }
  .ubm-2 ul li{
    width: 30%;
    margin: 0 1.5% 100px;
    padding: 36px 0px;
  }
  .ubm-2 ul li p{
    font-size: 30px;
  }
  .ubm-2 ul li span{
    font-size: 16px;
  }
  .ubm-2 ul li i{
    font-size: 14px;
  }


  .ubm-3{
    background: url(../assets/image/malaria/ubm-m3.jpg) 100% / auto 100% no-repeat;
    margin-top: 0px;
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 100vh;
    padding: 0 2%;
  }
  .ubm-3 .p1{
    font-size: 26px;
  }
  .ubm-3 ul{
    width: 100%;
  }
  .ubm-3 ul li{
    width: 47%;
    margin: 0 1.5% 20px;
    padding: 20px 0px;
  }
  .ubm-3 ul li:nth-of-type(3){
    width: 100%;
  }
  .ubm-3 ul li p{
    font-size: 30px;
  }
  .ubm-3 ul li span{
    font-size: 16px;
  }
  .ubm-3 ul li i{
    font-size: 14px;
  }
  .ubm-4{
    padding-top: 30px;
    padding-bottom: 80px;
  }
  .ubm-4 .p1{
    position: unset;
    padding: 3%;
    font-size: 24px;
  }
  .ubm-4 .p2{
    position: unset;
    padding: 3%;
    width: 100%;
    margin-bottom: 30px;
  }
  .ubm-4 a{
    position: unset;
    margin-left: 3%;
    background: #003B99;
    color: #fff;
  }
  .ubm-5{
    padding-top: 60px;
    padding-bottom: 40px;
  }
  .ubm-5 .p1{
    font-size: 26px;
    margin-bottom: 40px;
  }
  .ubm-5 a img{
    width: 34%;
  }
  .ubm-5 .time{
    font-size: 12px;
    letter-spacing: 0px;
    margin-top: 3px;
  }
  .ubm-5 .title{
    font-size: 14px;
    width: 60%;
    margin-top: 6px;
  }

  .en-ubm-box .ubm-4 .container{
    width: 100%;
  }
  .en-ubm-box .ubm-1 p, .en-ubm-box .ubm-2 .p1, .en-ubm-box .ubm-3 .p1, .en-ubm-box .ubm-5 .p1{
    font-size: 22px;
    padding: 0 3%;
  }
  .en-ubm-box .ubm-2 .p2, .en-ubm-box .ubm-3 .p2{
    font-size: 12px;
    padding: 0;
  }
  .en-ubm-box .ubm-2 ul li p, .en-ubm-box .ubm-3 ul li p{
    font-size: 26px;
  }
  .en-ubm-box .ubm-2 ul li{
    width: 30%;
    margin: 0 1.5% 60px;
    padding: 36px 0px;
  }
  .en-ubm-box .ubm-2 ul li i{
    font-size: 12px;
  }
  .en-ubm-box .ubm-2 ul li span, .en-ubm-box .ubm-3 ul li span{
    font-size: 12px;
  }
  .en-ubm-box .ubm-2 .p3{
    line-height: 150%;
    font-size: 12px;
  }


  .en-ubm-box .ubm-3 ul li{
    width: 47%;
    margin: 0 1.5% 20px;
    padding: 20px 0px;
  }
  .en-ubm-box .ubm-3 ul li i{
    font-size: 14px;
  }
  .en-ubm-box .ubm-3 ul li:nth-of-type(3){
    width: 100%;
  }
  .en-ubm-box .ubm-4{
    padding-bottom: 80px;
  }
  .en-ubm-box .ubm-4 .p1{
    width: 100%;
    font-size: 18px;
  }
  .en-ubm-box .ubm-4 .p2{
    font-size: 12px;
  }
  .ubm-2 ul li,.ubm-3 ul li{
    gap: 14px;
  }
  .ubm-1 img,.ubm-3 img{
    width: 100%;
  }
}


@media(max-width:420px) {
  .en-ubm-box .ubm-2 ul li p, .en-ubm-box .ubm-3 ul li p{
    font-size: 22px;
  }
  .en-ubm-box .ubm-2 ul li i{
    font-size: 12px;
  }
}

@media(max-width:380px) {
  .en-ubm-box .ubm-2 ul li p, .en-ubm-box .ubm-3 ul li p{
    font-size: 20px;
  }
}
</style>

