<template>
  <div class="Songkran2022">
    <div class="Songkran2022-head">
      <div class="head-box flex">
        <div class="head-box__logo">
          <router-link to="/"><img src="@/assets/image/No1/Group-19.png" title=" EBC官网地址 https://www.ebc.com"
              class="img"></router-link>
        </div>
        <div class="head-box__tit flex-1">
          <router-link to="/" class="btns">www.ebc.com</router-link>
        </div>
        <div class="head-box__btn flex">
          <a class="left-btn" href="https://client.ebccrm.com/signup?hl=th">เปิดบัญชี</a>
          <a class="right-btn" href="https://client.ebccrm.com/?hl=th">เข้าสู่ระบบ</a>
        </div>
      </div>
    </div>
    <div class="Songkran2022-banner">
      <div class="container-xxl">
        <div class="Songkran2022-h1__img">
          <img src="@/assets/image/Songkran2022/h1.svg" alt="">
        </div>
        <div class="Songkran2022-h1__btn">
          <a class="btn1" href="https://client.ebccrm.com/signup?hl=th">
            เปิดบัญชีทันที
          </a>
        </div>
      </div>
    </div>
    <div class="Songkran2022-bot">
      <div class="Songkran2022-bot__title flex">
        <div class="Songkran2022-bot__title__line flex-1">

        </div>
        <div class="Songkran2022-bot__title__name">
          ติดตามเราได้ที่ช่องทางด้านล่าง
        </div>
        <div class="Songkran2022-bot__title__line flex-1">

        </div>
      </div>
      <div class="Songkran2022-bot__btns">
        <div class="Songkran2022-bot__btns__ul">
          <a href="https://bit.ly/3LMi0ZD"><img src="@/assets/image/Songkran2022/bot1.svg" alt="" srcset=""></a>
          <a href="https://bit.ly/38ygmge"><img src="@/assets/image/Songkran2022/bot2.svg" alt="" srcset=""></a>
          <a href="https://bit.ly/3y2cgb4"><img src="@/assets/image/Songkran2022/bot3.svg" alt="" srcset=""></a>
          <a href="https://bit.ly/3KsG6rR"><img src="@/assets/image/Songkran2022/bot4.svg" alt="" srcset=""></a>
          <a href="https://lin.ee/Hs5RYxI"><img src="@/assets/image/Songkran2022/bot5.svg" alt="" srcset=""></a>
          <a href="https://bit.ly/3F2jnBQ"><img src="@/assets/image/Songkran2022/bot6.svg" alt="" srcset=""></a>
        </div>
      </div>
    </div>
    <c-footer :footerInfo="footerInfo"></c-footer>
  </div>
</template>

<script>
  if (process.browser) {
    var {
      WOW
    } = require('wowjs')
  }
  import md5 from 'js-md5';
  export default {
    async asyncData(that) {
      let [data] = await Promise.all([that.$axios.get("/ebc/api/getJson/run?name=CFooters&lang=th_TH")])
      return {
        footerInfo: JSON.parse(data.data.data)
      }
    },
    metaInfo() {
      return {
        title: 'ปีเสือทอง 2022 | EBC Financial Group',
        meta: [ // set meta
          {
            name: 'keyWords',
            content: 'EBC, แพลตฟอร์ม EBC, เว็บไซต์อย่างเป็นทางการของ EBC, เว็บไซต์ทางการทางการเงินของ EBC, การเปิดบัญชี EBC, การเงิน EBC, โบรกเกอร์ EBC'
          },
          {
            name: 'description',
            content: 'เว็บไซต์ทางการทางการเงินของ EBC ก่อตั้งโดย EBC Financial Group, EBC Financial Group ให้บริการ, การเงิน EBC, การเปิดบัญชี EBC และการซื้อขาย, บัญชีแพลตฟอร์มรวม EBC เพียงบัญชีเดียว คุณสามารถซื้อขายสัญญาส่วนต่าง (CFD): อนุพันธ์ หุ้นสหรัฐ สกุลเงินดิจิทัล ดัชนีฟิวเจอร์ส โลหะมีค่าและพลังงาน ฯลฯ'
          }
        ]
      }
    },
    data() {
      return {
        infoData: {
          call: '',
          name: '',
          email: ''
        },
        kind: null,
        num1: 0,
        num2: 0,
      }
    },
    mounted() {
      this.$store.commit('setPosition', "th_TH")
      this.$store.commit('setTitle', "ภาษาไทย")
      this.$nextTick(() => {
        if (process.browser) { // 在页面mounted生命周期里面 根据环境实例化WOW
          new WOW({
            animateClass: 'animate__animated'
          }).init()
        }
      });
    },
    created() {

    },
    methods: {}
  }
</script>

<style lang="scss">
  .Songkran2022 {
    font-family: "Thai" !important;
    background-image: radial-gradient(circle at 18% 38%, #600004, #330002 38%);

    &-head {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      background-color: #3d0101;
      padding: 15px 0;
      z-index: 1000;

      .head-box {
        max-width: 1080px;
        margin: auto;
        align-items: center;

        &__logo {}

        &__tit {
          text-align: center;

          .btns {
            color: #a97c50;
            font-family: Times New Roman, Georgia, serif;
            font-weight: 700;
            font-size: 22px;
            line-height: 70px;
          }
        }

        &__btn {
          align-items: center;

          .left-btn {
            display: block;
            color: #c49a6c;
            border-radius: 5px;
            font-size: 20px;
            font-family: Noto Sans SC, Helvetica, Arial, Lucida, sans-serif;
            background-color: #760006;
            padding: 0.3em 1em;
            line-height: 1.7em;
          }

          .right-btn {
            margin-left: 20px;
            font-size: 20px;
            font-family: Noto Sans SC, Helvetica, Arial, Lucida, sans-serif !important;
            color: #e3e3e3;
            padding: 0.3em 1em;
            text-decoration: underline
          }
        }
      }
    }

    &-banner {
      margin-top: 120px;
      padding-top: 280px;
      text-align: center;
      background: url(../assets/image/win2022/head.jpg) repeat-x;
    }

    &-h1 {
      &__img {
        margin-top: 20px;
      }

      &__btn {
        padding: 180px 0;
        text-align: center;
        .btn1 {
          text-align: center;
          line-height: 108px;
          width: 476px;
          height: 108px;
          background: linear-gradient(147.66deg, rgba(221, 171, 93, 1.00) 0%, rgba(249, 229, 161, 1.00) 32.28%, rgba(251, 202, 91, 1.00) 60.19%, rgba(205, 155, 82, 1.00) 100%);
          box-shadow: 0px 11px 22px rgba(135, 45, 31, 1);
          border-radius: 10px;
          letter-spacing: 2px;
          font-size: 48px;
          color: #000;
          display: inline-block;
        }
      }
    }

    &-bot {
      &__title {
        padding: 0 40px;
        align-items: center;

        &__line {
          height: 1px;
          background-color: #c49a6c;
        }

        &__name {
          padding: 0 20px;
          color: rgba(247, 191, 131, 1);
          letter-spacing: 2px;
          font-size: 58px;
        }
      }
      &__btns{
        padding: 40px 0 100px;
        &__ul{
          text-align: center;
          a{
            display: inline-block;
            width: 70px;
            height: 70px;
            margin-right: 20px;
            &:last-child{
              margin: 0;
            }
          }
        }
      }
    }
  }

  @media (max-width: 500px) {
    .Songkran2022 {
      &-head {
        .head-box {
          padding: 0 10px;

          &__logo {
            flex: 1;

            img {
              width: 70%;
            }
          }

          &__tit {
            display: none;
          }

          &__btn {
            flex: 1;
            justify-content: right;

            .right-btn {
              margin-left: 0;
              white-space: nowrap;
              padding: 0.3em 0 0.3em 0.5em;
            }

            .left-btn {
              padding: 0.3em 0.6em;
              font-size: 18px;
              white-space: nowrap;
            }
          }
        }
      }
      &-banner {
        margin-top: 0;
      }
      &-h1 {
        &__img {
          margin-top: 20px;
        }

        &__btn {
          padding: 30px 0;
          text-align: center;
          .btn1 {
            text-align: center;
            line-height: 56px;
            width: 100%;
            height: 52px;
            background: linear-gradient(147.66deg, rgba(221, 171, 93, 1.00) 0%, rgba(249, 229, 161, 1.00) 32.28%, rgba(251, 202, 91, 1.00) 60.19%, rgba(205, 155, 82, 1.00) 100%);
            box-shadow: 0px 11px 22px rgba(135, 45, 31, 1);
            border-radius: 10px;
            letter-spacing: 2px;
            font-size: 28px;
            color: #000;
            display: inline-block;
          }
        }
      }
      &-bot {
        padding-bottom: 100px;
          &__title {
            padding: 0 5px;
            align-items: center;

            &__line {
              height: 1px;
              background-color: #c49a6c;
            }

            &__name {
              padding: 0 5px;
              color: rgba(247, 191, 131, 1);
              letter-spacing: 2px;
              font-size: 24px;
            }
          }
          &__btns{
            padding: 20px 0 50px;
            &__ul{
              text-align: center;
              a{
                display: inline-block;
                width: 40px;
                height: 40px;
                margin-right: 10px;
                &:last-child{
                  margin: 0;
                }
              }
            }
          }
        }
    }
  }
</style>
