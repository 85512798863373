<template>
	<div>
		<ss-header mode="Home" bg="rgb(38, 5, 5)" :langList="['en', 'ct', 'zh']"></ss-header>
		<div :class="$t('common.lang')">
			<div class="pay-wrapper " :class="$t('common.fontFamily')">
				<div class="pay-session1-box">
					<div class="pay-session1">
						<div class="pay-session1-title " :class="$t('common.fontFamilyTitle')">
							{{ $t('pay.交易领薪计划') }}

						</div>
						<div class="pay-session1-detail">
							<div v-html="$t('pay.为了鼓励交易者提升交易水平')"></div>

							<div v-html="$t('pay.加入交易社区')"></div>
						</div>
						<div class="pay-session1-list">
							<div class="pay-session1-li">
								<span class="iconfont icon-Vector"></span><span>{{ $t('pay.即刻发起交易信号') }} </span>
							</div>
							<div class="pay-session1-li">
								<span class="iconfont icon-Vector"></span><span>{{ $t('pay.无限奖励月月拿') }} </span>
							</div>
							<div class="pay-session1-li">
								<span class="iconfont icon-Vector"></span><span>{{ $t('pay.优享12个专属大礼') }} </span>
							</div>
						</div>
						<a :href="$store.state.khUrl" class="btn-go">
							{{ $t('pay.即刻加入') }}<span class="iconfont icon-right"></span>
						</a>
					</div>
				</div>
				<div class="pay-session2">
					<div class="pay-session2-title " :class="$t('common.fontFamilyTitle')" v-html="$t('pay.交易员等级奖励机制')">

					</div>
					<div class="pay-session2-subtitle">
						{{ $t('pay.EBC基于跟随人数和资金提供五档奖励') }}
					</div>
					<div class="pc">
						<div class="pay-session2-list">
							<div class="pay-session2-li">
								<div class="pay-session2-li-top">
									<div>
										$100<span class="pay-session2-li-top-small">/{{ $t('pay.月') }}</span>
									</div>
								</div>
								<div class="pay-session2-li-content">
									<div class="pay-session2-li-content-name">
										<span class="iconfont icon-Vector"></span>{{ $t('pay.新锐交易员') }}
										<span class="iconfont icon-Vector"></span>
									</div>
									<div class="pay-session2-li-content-icon">
										<img class="icon-img" width="68px" src="../assets/image/pay/session2_icon1.png"
											alt="" />
									</div>
								</div>
								<div class="pay-session2-li-bottom">
									<div class="flex-bewteen">
										<span>{{ $t('pay.管理资金量') }} </span>
										<span>>$20,000 </span>
									</div>
									<div class="flex-bewteen">
										<span>{{ $t('pay.跟随用户数') }} </span>
										<span>≥10 </span>
									</div>
								</div>
							</div>

							<div class="pay-session2-li">
								<div class="pay-session2-li-top">
									<div>
										$300<span class="pay-session2-li-top-small">/{{ $t('pay.月') }}</span>
									</div>
								</div>
								<div class="pay-session2-li-content">
									<div class="pay-session2-li-content-name">
										<span class="iconfont icon-Vector"></span>{{ $t('pay.精英交易员') }}
										<span class="iconfont icon-Vector"></span>
									</div>
									<div class="pay-session2-li-content-icon">
										<img class="icon-img" width="68px" src="../assets/image/pay/session2_icon2.png"
											alt="" />
									</div>
								</div>
								<div class="pay-session2-li-bottom">
									<div class="flex-bewteen">
										<span>{{ $t('pay.管理资金量') }} </span>
										<span>>$50,000 </span>
									</div>
									<div class="flex-bewteen">
										<span>{{ $t('pay.跟随用户数') }} </span>
										<span>≥30 </span>
									</div>
								</div>
							</div>

							<div class="pay-session2-li">
								<div class="pay-session2-li-top">
									<div>
										$1000<span class="pay-session2-li-top-small">/{{ $t('pay.月') }}</span>
									</div>
								</div>
								<div class="pay-session2-li-content">
									<div class="pay-session2-li-content-name">
										<span class="iconfont icon-Vector"></span>{{ $t('pay.冠军交易员') }}
										<span class="iconfont icon-Vector"></span>
									</div>
									<div class="pay-session2-li-content-icon">
										<img class="icon-img" width="98px" src="../assets/image/pay/session2_icon3.png"
											alt="" />
									</div>
								</div>
								<div class="pay-session2-li-bottom">
									<div class="flex-bewteen">
										<span>{{ $t('pay.管理资金量') }} </span>
										<span>>$100,000 </span>
									</div>
									<div class="flex-bewteen">
										<span>{{ $t('pay.跟随用户数') }} </span>
										<span>≥50 </span>
									</div>
								</div>
							</div>

							<div class="pay-session2-li">
								<div class="pay-session2-li-top">
									<div>
										$5000<span class="pay-session2-li-top-small">/{{ $t('pay.月') }}</span>
									</div>
								</div>
								<div class="pay-session2-li-content">
									<div class="pay-session2-li-content-name">
										<span class="iconfont icon-Vector"></span>{{ $t('pay.明星交易员') }}
										<span class="iconfont icon-Vector"></span>
									</div>
									<div class="pay-session2-li-content-icon">
										<img class="icon-img" width="92px" src="../assets/image/pay/session2_icon4.png"
											alt="" />
									</div>
								</div>
								<div class="pay-session2-li-bottom">
									<div class="flex-bewteen">
										<span>{{ $t('pay.管理资金量') }} </span>
										<span>>$500,000 </span>
									</div>
									<div class="flex-bewteen">
										<span>{{ $t('pay.跟随用户数') }} </span>
										<span>≥100 </span>
									</div>
								</div>
							</div>

							<div class="pay-session2-li">
								<div class="pay-session2-li-top">
									<div>
										$10000<span class="pay-session2-li-top-small">/{{ $t('pay.月') }}</span>
									</div>
									<span class="pay-session2-li-top-mini">{{ $t('pay.管理资金') }}</span>
								</div>
								<div class="pay-session2-li-content">
									<div class="pay-session2-li-content-name">
										<span class="iconfont icon-Vector"></span>{{ $t('pay.传奇交易员') }}
										<span class="iconfont icon-Vector"></span>
									</div>
									<div class="pay-session2-li-content-icon">
										<img class="icon-img" width="78px" src="../assets/image/pay/session2_icon5.png"
											alt="" />
									</div>
								</div>
								<div class="pay-session2-li-bottom">
									<div class="flex-bewteen">
										<span>{{ $t('pay.管理资金量') }} </span>
										<span>>$1,000,000 </span>
									</div>
									<div class="flex-bewteen">
										<span>{{ $t('pay.跟随用户数') }} </span>
										<span>≥200 </span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="mobile">
						<div class="pay-session2-list">
							<div class="swiper">
								<div class="swiper-wrapper">
									<div class="swiper-slide">
										<div class="pay-session2-li">
											<div class="pay-session2-li-top">
												<div>
													$100<span class="pay-session2-li-top-small">/{{ $t('pay.月')
														}}</span>
												</div>
											</div>
											<div class="pay-session2-li-content">
												<div class="pay-session2-li-content-name">
													<span class="iconfont icon-Vector"></span>{{ $t('pay.新锐交易员') }}
													<span class="iconfont icon-Vector"></span>
												</div>
												<div class="pay-session2-li-content-icon">
													<img class="icon-img" width="68px"
														src="../assets/image/pay/session2_icon1.png" alt="" />
												</div>
											</div>
											<div class="pay-session2-li-bottom">
												<div class="flex-bewteen">
													<span>{{ $t('pay.管理资金量') }} </span>
													<span>>$20,000 </span>
												</div>
												<div class="flex-bewteen">
													<span>{{ $t('pay.跟随用户数') }} </span>
													<span>≥10 </span>
												</div>
											</div>
										</div>
									</div>
									<div class="swiper-slide">
										<div class="pay-session2-li">
											<div class="pay-session2-li-top">
												<div>
													$300<span class="pay-session2-li-top-small">/{{ $t('pay.月')
														}}</span>
												</div>
											</div>
											<div class="pay-session2-li-content">
												<div class="pay-session2-li-content-name">
													<span class="iconfont icon-Vector"></span>{{ $t('pay.精英交易员') }}
													<span class="iconfont icon-Vector"></span>
												</div>
												<div class="pay-session2-li-content-icon">
													<img class="icon-img" width="68px"
														src="../assets/image/pay/session2_icon2.png" alt="" />
												</div>
											</div>
											<div class="pay-session2-li-bottom">
												<div class="flex-bewteen">
													<span>{{ $t('pay.管理资金量') }} </span>
													<span>>$50,000 </span>
												</div>
												<div class="flex-bewteen">
													<span>{{ $t('pay.跟随用户数') }} </span>
													<span>≥30 </span>
												</div>
											</div>
										</div>
									</div>
									<div class="swiper-slide">
										<div class="pay-session2-li">
											<div class="pay-session2-li-top">
												<div>
													$1000<span class="pay-session2-li-top-small">/{{ $t('pay.月')
														}}</span>
												</div>
											</div>
											<div class="pay-session2-li-content">
												<div class="pay-session2-li-content-name">
													<span class="iconfont icon-Vector"></span>{{ $t('pay.冠军交易员') }}
													<span class="iconfont icon-Vector"></span>
												</div>
												<div class="pay-session2-li-content-icon">
													<img class="icon-img" width="98px"
														src="../assets/image/pay/session2_icon3.png" alt="" />
												</div>
											</div>
											<div class="pay-session2-li-bottom">
												<div class="flex-bewteen">
													<span>{{ $t('pay.管理资金量') }} </span>
													<span>>$100,000 </span>
												</div>
												<div class="flex-bewteen">
													<span>{{ $t('pay.跟随用户数') }} </span>
													<span>≥50 </span>
												</div>
											</div>
										</div>
									</div>
									<div class="swiper-slide">
										<div class="pay-session2-li">
											<div class="pay-session2-li-top">
												<div>
													$5000<span class="pay-session2-li-top-small">/{{ $t('pay.月')
														}}</span>
												</div>
											</div>
											<div class="pay-session2-li-content">
												<div class="pay-session2-li-content-name">
													<span class="iconfont icon-Vector"></span>{{ $t('pay.明星交易员') }}
													<span class="iconfont icon-Vector"></span>
												</div>
												<div class="pay-session2-li-content-icon">
													<img class="icon-img" width="92px"
														src="../assets/image/pay/session2_icon4.png" alt="" />
												</div>
											</div>
											<div class="pay-session2-li-bottom">
												<div class="flex-bewteen">
													<span>{{ $t('pay.管理资金量') }} </span>
													<span>>$500,000 </span>
												</div>
												<div class="flex-bewteen">
													<span>{{ $t('pay.跟随用户数') }} </span>
													<span>≥100 </span>
												</div>
											</div>
										</div>
									</div>
									<div class="swiper-slide">
										<div class="pay-session2-li">
											<div class="pay-session2-li-top">
												<div>
													$10000<span class="pay-session2-li-top-small">/{{ $t('pay.月')
														}}</span>
												</div>
												<span class="pay-session2-li-top-mini">{{ $t('pay.管理资金') }}</span>
											</div>
											<div class="pay-session2-li-content">
												<div class="pay-session2-li-content-name">
													<span class="iconfont icon-Vector"></span>{{ $t('pay.传奇交易员') }}
													<span class="iconfont icon-Vector"></span>
												</div>
												<div class="pay-session2-li-content-icon">
													<img class="icon-img" width="78px"
														src="../assets/image/pay/session2_icon5.png" alt="" />
												</div>
											</div>
											<div class="pay-session2-li-bottom">
												<div class="flex-bewteen">
													<span>{{ $t('pay.管理资金量') }} </span>
													<span>>$1,000,000 </span>
												</div>
												<div class="flex-bewteen">
													<span>{{ $t('pay.跟随用户数') }} </span>
													<span>≥200 </span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="swiper-pagination my-pagination"></div>
							</div>
						</div>
					</div>
				</div>
				<div class="pay-session3">
					<div class="pay-session3-title " :class="$t('common.fontFamilyTitle')">
						{{ $t('pay.多层次的增值服务') }}
					</div>
					<div class="pay-session3-subtitle">
						{{ $t('pay.EBC提供多达11项增值服务') }}
					</div>
					<div class="pc">
						<div class="pay-session3-list">
							<div class="pay-session3-li">
								<div class="pay-session3-li-top">{{ $t('pay.精英交易员') }}</div>
								<div class="pay-session3-li-content">
									<div class="pay-session3-li-content-bg1">
										<p class="pay-session3-li-content-text">{{ $t('pay["7×24小时优先客服"]') }}</p>
										<p class="pay-session3-li-content-text">
											{{ $t('pay.参与EBC品牌营销互动') }}
										</p>
									</div>
								</div>
							</div>
							<div class="pay-session3-li">
								<div class="pay-session3-li-top pay-session3-li-top2">
									{{ $t('pay.冠军交易员') }}
								</div>
								<div class="pay-session3-li-content">
									<div class="pay-session3-li-content-bg2">
										<p class="pay-session3-li-content-text">{{ $t('pay["7×24小时优先客服"]') }}</p>
										<p class="pay-session3-li-content-text">
											{{ $t('pay.参与EBC品牌营销互动') }}
										</p>
										<p class="pay-session3-li-content-text pay-session3-li-content-text-light">
											{{ $t('pay.VPS赞助') }}
										</p>
										<p class="pay-session3-li-content-text pay-session3-li-content-text-light">
											{{ $t('pay.金融投资类书籍赞助') }}
										</p>
										<p class="pay-session3-li-content-text pay-session3-li-content-text-light">
											{{ $t('pay.订单流软件一年使用权') }}
										</p>
										<p class="pay-session3-li-content-text pay-session3-li-content-text-light">
											{{ $t('pay.EBC定制大礼包') }}
										</p>
									</div>
								</div>
							</div>
							<div class="pay-session3-li">
								<div class="pay-session3-li-top pay-session3-li-top3">
									{{ $t('pay.明星交易员') }}
								</div>
								<div class="pay-session3-li-content">
									<div class="pay-session3-li-content-bg3">
										<p class="pay-session3-li-content-text">{{ $t('pay["7×24小时优先客服"]') }}</p>
										<p class="pay-session3-li-content-text">
											{{ $t('pay.参与EBC品牌营销互动') }}
										</p>
										<p class="pay-session3-li-content-text">{{ $t('pay.VPS赞助') }}</p>
										<p class="pay-session3-li-content-text">{{ $t('pay.金融投资类书籍赞助') }}</p>
										<p class="pay-session3-li-content-text">
											{{ $t('pay.订单流软件一年使用权') }}
										</p>
										<p class="pay-session3-li-content-text">{{ $t('pay.EBC定制大礼包') }}</p>
										<p class="pay-session3-li-content-text pay-session3-li-content-text-light">
											{{ $t('pay.信号置顶展示') }}
										</p>

										<p class="pay-session3-li-content-text pay-session3-li-content-text-light">
											{{ $t('pay.受邀参观海外办公室') }}
										</p>
									</div>
								</div>
							</div>
							<div class="pay-session3-li">
								<div class="pay-session3-li-top pay-session3-li-top4">
									{{ $t('pay.传奇交易员') }}
								</div>
								<div class="pay-session3-li-content">
									<div class="pay-session3-li-content-bg4">
										<p class="pay-session3-li-content-text">{{ $t('pay["7×24小时优先客服"]') }}</p>
										<p class="pay-session3-li-content-text">
											{{ $t('pay.参与EBC品牌营销互动') }}
										</p>
										<p class="pay-session3-li-content-text">{{ $t('pay.VPS赞助') }}</p>
										<p class="pay-session3-li-content-text">{{ $t('pay.金融投资类书籍赞助') }}</p>
										<p class="pay-session3-li-content-text">
											{{ $t('pay.订单流软件一年使用权') }}
										</p>
										<p class="pay-session3-li-content-text">{{ $t('pay.EBC定制大礼包') }}</p>
										<p class="pay-session3-li-content-text">{{ $t('pay.信号置顶展示') }}</p>
										<p class="pay-session3-li-content-text">{{ $t('pay.受邀参观海外办公室') }}</p>
										<p class="pay-session3-li-content-text pay-session3-li-content-text-light">
											{{ $t('pay.媒体资源推送') }}
										</p>
										<p class="pay-session3-li-content-text pay-session3-li-content-text-light">
											{{ $t('pay.品牌战略合作') }}
										</p>
										<p class="pay-session3-li-content-text pay-session3-li-content-text-light">
											{{ $t('pay.海外基金产品发行顾问服务') }}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="mobile">
						<div class="pay-session3-list">
							<div class="swiper">
								<div class="swiper-wrapper">
									<div class="swiper-slide">
										<div class="pay-session3-li">
											<div class="pay-session3-li-top">{{ $t('pay.精英交易员') }}</div>
											<div class="pay-session3-li-content">
												<div class="pay-session3-li-content-bg1">
													<p class="pay-session3-li-content-text">
														{{ $t('pay["7×24小时优先客服"]') }}
													</p>
													<p class="pay-session3-li-content-text">
														{{ $t('pay.参与EBC品牌营销互动') }}
													</p>
												</div>
											</div>
										</div>
									</div>
									<div class="swiper-slide">
										<div class="pay-session3-li">
											<div class="pay-session3-li-top pay-session3-li-top2">
												{{ $t('pay.冠军交易员') }}
											</div>
											<div class="pay-session3-li-content">
												<div class="pay-session3-li-content-bg2">
													<p class="pay-session3-li-content-text">
														{{ $t('pay["7×24小时优先客服"]') }}
													</p>
													<p class="pay-session3-li-content-text">
														{{ $t('pay.参与EBC品牌营销互动') }}
													</p>
													<p
														class="pay-session3-li-content-text pay-session3-li-content-text-light">
														{{ $t('pay.VPS赞助') }}
													</p>
													<p
														class="pay-session3-li-content-text pay-session3-li-content-text-light">
														{{ $t('pay.金融投资类书籍赞助') }}
													</p>
													<p
														class="pay-session3-li-content-text pay-session3-li-content-text-light">
														{{ $t('pay.订单流软件一年使用权') }}
													</p>
													<p
														class="pay-session3-li-content-text pay-session3-li-content-text-light">
														{{ $t('pay.EBC定制大礼包') }}
													</p>
												</div>
											</div>
										</div>
									</div>
									<div class="swiper-slide">
										<div class="pay-session3-li">
											<div class="pay-session3-li-top pay-session3-li-top3">
												{{ $t('pay.明星交易员') }}
											</div>
											<div class="pay-session3-li-content">
												<div class="pay-session3-li-content-bg3">
													<p class="pay-session3-li-content-text">
														{{ $t('pay["7×24小时优先客服"]') }}
													</p>
													<p class="pay-session3-li-content-text">
														{{ $t('pay.参与EBC品牌营销互动') }}
													</p>
													<p class="pay-session3-li-content-text">{{ $t('pay.VPS赞助') }}</p>
													<p class="pay-session3-li-content-text">
														{{ $t('pay.金融投资类书籍赞助') }}
													</p>
													<p class="pay-session3-li-content-text">
														{{ $t('pay.订单流软件一年使用权') }}
													</p>
													<p class="pay-session3-li-content-text">
														{{ $t('pay.EBC定制大礼包') }}
													</p>
													<p
														class="pay-session3-li-content-text pay-session3-li-content-text-light">
														{{ $t('pay.信号置顶展示') }}
													</p>

													<p
														class="pay-session3-li-content-text pay-session3-li-content-text-light">
														{{ $t('pay.受邀参观海外办公室') }}
													</p>
												</div>
											</div>
										</div>
									</div>
									<div class="swiper-slide">
										<div class="pay-session3-li">
											<div class="pay-session3-li-top pay-session3-li-top4">
												{{ $t('pay.传奇交易员') }}
											</div>
											<div class="pay-session3-li-content">
												<div class="pay-session3-li-content-bg4">
													<p class="pay-session3-li-content-text">
														{{ $t('pay["7×24小时优先客服"]') }}
													</p>
													<p class="pay-session3-li-content-text">
														{{ $t('pay.参与EBC品牌营销互动') }}
													</p>
													<p class="pay-session3-li-content-text">{{ $t('pay.VPS赞助') }}</p>
													<p class="pay-session3-li-content-text">
														{{ $t('pay.金融投资类书籍赞助') }}
													</p>
													<p class="pay-session3-li-content-text">
														{{ $t('pay.订单流软件一年使用权') }}
													</p>
													<p class="pay-session3-li-content-text">
														{{ $t('pay.EBC定制大礼包') }}
													</p>
													<p class="pay-session3-li-content-text">{{ $t('pay.信号置顶展示') }}</p>
													<p class="pay-session3-li-content-text">
														{{ $t('pay.受邀参观海外办公室') }}
													</p>
													<p
														class="pay-session3-li-content-text pay-session3-li-content-text-light">
														{{ $t('pay.媒体资源推送') }}
													</p>
													<p
														class="pay-session3-li-content-text pay-session3-li-content-text-light">
														{{ $t('pay.品牌战略合作') }}
													</p>
													<p
														class="pay-session3-li-content-text pay-session3-li-content-text-light">
														{{ $t('pay.海外基金产品发行顾问服务') }}
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- 如果需要分页器 -->
								<div class="swiper-pagination my-pagination"></div>
							</div>
						</div>
					</div>
				</div>

				<div class="pay-session4">
					<div class="pay-session4-title " :class="$t('common.fontFamilyTitle')">
						{{ $t('pay.六维指标评价系统') }}
					</div>
					<div class="pay-session4-subtitle">
						{{ $t('pay.为交易员信号提供公开透明的评估') }}
					</div>
					<div class="pc">
						<div class="pay-session4-content">
							<div class="pay-session4-content-left">
								<div class="pay-session4-content-item">
									<div class="pay-session4-content-item-label">{{ $t('pay["最大回撤6.39%"]') }}</div>
									<div class="pay-session4-content-item-info">
										{{ $t('pay.衡量风控能力') }}
									</div>
								</div>
								<div class="pay-session4-content-item">
									<div class="pay-session4-content-item-label">{{ $t('pay["标准差1.59"]') }}</div>
									<div class="pay-session4-content-item-info">
										{{ $t('pay.收益波动率') }}
									</div>
								</div>
								<div class="pay-session4-content-item">
									<div class="pay-session4-content-item-label">
										{{ $t('pay["盈利交易(胜率)75.82%"]') }}
									</div>
									<div class="pay-session4-content-item-info">
										{{ $t('pay.盈利订单占比') }}
									</div>
								</div>
							</div>
							<img height="428px" src="../assets/image/pay/session4_bg.png" alt="" />
							<div class="pay-session4-content-right">
								<div class="pay-session4-content-item">
									<div class="pay-session4-content-item-label">{{ $t('pay["收益率406.06%"]') }}</div>
									<div class="pay-session4-content-item-info">
										{{ $t('pay.历史收益率') }}
									</div>
								</div>
								<div class="pay-session4-content-item">
									<div class="pay-session4-content-item-label">{{ $t('pay["获利系数6.19"]') }}</div>
									<div class="pay-session4-content-item-info">
										{{ $t('pay.利润因子') }}
									</div>
								</div>
								<div class="pay-session4-content-item">
									<div class="pay-session4-content-item-label">{{ $t('pay["夏普比率44.46"]') }}</div>
									<div class="pay-session4-content-item-info">
										{{ $t('pay.每份风险的收益') }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="mobile">
						<div class="pay-session4-content">
							<div class="pay-session4-content-item-m">
								<div class="pay-session4-content-item-m-label">{{ $t('pay.收益率') }}</div>
								<div class="pay-session4-content-item-m-info">406.06%</div>
							</div>
							<div class="pay-session4-content-center">
								<div class="pay-session4-content-item-m-left">
									<div class="pay-session4-content-item-m">
										<div class="pay-session4-content-item-m-label">{{ $t('pay.最大回撤') }}</div>
										<div class="pay-session4-content-item-m-info">6.39%</div>
									</div>
									<div class="pay-session4-content-item-m">
										<div class="pay-session4-content-item-m-label">{{ $t('pay.标准差') }}</div>
										<div class="pay-session4-content-item-m-info">1.59</div>
									</div>
								</div>
								<img src="../assets/image/pay/session4_bg_m.png" alt="" />
								<div class="pay-session4-content-item-m-right">
									<div class="pay-session4-content-item-m">
										<div class="pay-session4-content-item-m-label">{{ $t('pay.获利系数') }}</div>
										<div class="pay-session4-content-item-m-info">6.19</div>
									</div>
									<div class="pay-session4-content-item-m">
										<div class="pay-session4-content-item-m-label">{{ $t('pay.夏普比率') }}</div>
										<div class="pay-session4-content-item-m-info">44.46</div>
									</div>
								</div>
							</div>
							<div class="pay-session4-content-item-m">
								<div class="pay-session4-content-item-m-label">
									{{ $t('pay.盈利交易(胜率)') }}
								</div>
								<div class="pay-session4-content-item-m-info">75.82%</div>
							</div>
							<div class="pay-session4-content-card">
								<div class="pay-session4-content-card-item">
									<div class="pay-session4-content-card-item-label">
										{{ $t('pay["收益率406.06%"]') }}
									</div>
									<div class="pay-session4-content-card-item-info">
										{{ $t('pay.历史收益率') }}
									</div>
								</div>
								<div class="pay-session4-content-card-item">
									<div class="pay-session4-content-card-item-label">
										{{ $t('pay["获利系数6.19"]') }}
									</div>
									<div class="pay-session4-content-card-item-info">
										{{ $t('pay.利润因子') }}
									</div>
								</div>
								<div class="pay-session4-content-card-item">
									<div class="pay-session4-content-card-item-label">
										{{ $t('pay["夏普比率44.46"]') }}
									</div>
									<div class="pay-session4-content-card-item-info">
										{{ $t('pay.每份风险的收益') }}
									</div>
								</div>
								<div class="pay-session4-content-card-item">
									<div class="pay-session4-content-card-item-label">
										{{ $t('pay["盈利交易(胜率)75.82%"]') }}
									</div>
									<div class="pay-session4-content-card-item-info">
										{{ $t('pay.盈利订单占比') }}
									</div>
								</div>

								<div class="pay-session4-content-card-item">
									<div class="pay-session4-content-card-item-label">
										{{ $t('pay["标准差1.59"]') }}
									</div>
									<div class="pay-session4-content-card-item-info">
										{{ $t('pay.收益波动率') }}
									</div>
								</div>
								<div class="pay-session4-content-card-item">
									<div class="pay-session4-content-card-item-label">
										{{ $t('pay["最大回撤6.39%"]') }}
									</div>
									<div class="pay-session4-content-card-item-info">
										{{ $t('pay.衡量风控能力') }}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="pay-session4-tips">{{ $t('pay.以上数据仅供参考') }}</div>
				</div>

				<div class="pay-session5">
					<div class="pay-session5-title " :class="$t('common.fontFamilyTitle')">
						<img height="44" src="../assets/image/No1/img/Icon-star-left.svg" alt="" class="pc" /><img
							height="34" src="../assets/image/No1/img/Icon-star-left.svg" alt="" class="mobile" />{{
								$t('pay.活动须知') }}<img height="44" src="../assets/image/No1/img/Icon-star-right.svg" alt=""
							class="pc" />
						<img height="34" src="../assets/image/No1/img/Icon-star-right.svg" alt="" class="mobile" />
					</div>

					<div class="pay-session5-content">
						<div class="tip-list">
							<div class="tip-item">
								<div class="tip-title">{{ $t('pay.交易员等级计算') }}</div>
								<div class="tip-content">
									<div class="tip-content-li">
										{{ $t('pay.管理资金量跟随资金量') }}
									</div>
									<div class="tip-content-li">
										{{ $t('pay.等级计算以自然月为周期') }}
									</div>
								</div>
								<div class="tip-title">{{ $t('pay.有效跟随用户') }}</div>
								<div class="tip-content">
									<div class="tip-content-li">
										{{ $t('pay.起始跟随金额') }}
									</div>
									<div class="tip-content-li">
										{{ $t('pay.每个MT4账号最多跟随10个交易员') }}
									</div>
									<div class="tip-content-li">
										{{ $t('pay.每个MT4账号跟随金额') }}
									</div>
								</div>
								<div class="tip-title">{{ $t('pay.奖励结算') }}</div>
								<div class="tip-content">
									<div class="tip-content-li">
										{{ $t('pay.次月第一周计算并发放上月奖励') }}
									</div>
									<div class="tip-content-li">
										{{ $t('pay.所有奖励发到EBC后台钱包') }}
									</div>
									<div class="tip-content-li">
										{{ $t('pay.活动最终解释权归主办方EBC所有') }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<new-footer></new-footer>
	</div>

</template>

<script>
import 'swiper/css/swiper.css'
import Swiper from 'swiper'
import {
	mapState,
} from 'vuex'
export default {
	head() {
		return {
			title: this.lang === 'en_US' ? "EBC Financial Group|Trade & get paid" : 'EBC Financial Group|英国澳洲双持牌券商',
			meta: [ // set meta
				{
					name: 'keyWords',
					content: 'EBC,EBC平台,EBC官网,EBC金融官网,EBC开户,EBC金融'
				},
				{
					name: 'description',
					content: 'EBC金融官网是由EBC金融集团EBC Financial Group成立的,主要提供,EBC金融,EBC开户交易服务,只需一个EBC综合平台账户,即可交易多种差价合约（CFD）:衍生品、美股、数字货币、期货指数、贵金属和能源等.'
				}
			]
		}
	},
	data() {
		return {

		}
	},
	computed: {
		...mapState({
			lang: state => state.Lan,
		}),
	},
	mounted() {
		window.dataLayer = window.dataLayer || [];

		// function gtag() {
		// 	dataLayer.push(arguments);
		// }
		// gtag('js', new Date());
		// gtag('config', 'G-TJ431PDHZX');
		window.__lc = window.__lc || {};
		window.__lc.license = 12340599;
		(function (n, t, c) {
			function i(n) {
				return e._h ? e._h.apply(null, n) : e._q.push(n)
			}
			var e = {
				_q: [],
				_h: null,
				_v: "2.0",
				on: function () {
					i(["on", c.call(arguments)])
				},
				once: function () {
					i(["once", c.call(arguments)])
				},
				off: function () {
					i(["off", c.call(arguments)])
				},
				get: function () {
					if (!e._h) throw new Error("[LiveChatWidget] You can't use getters before load.");
					return i(["get", c.call(arguments)])
				},
				call: function () {
					i(["call", c.call(arguments)])
				},
				init: function () {
					var n = t.createElement("script");
					n.async = !0, n.type = "text/javascript", n.src =
						"https://cdn.livechatinc.com/tracking.js", t.head.appendChild(n)
				}
			};
			!n.__lc.asyncInit && e.init(), n.LiveChatWidget = n.LiveChatWidget || e
		}(window, document, [].slice))
		let nowDate = new Date();
		setTimeout(() => {
			var mySwiper = new Swiper(".swiper", {
				// direction: "vertical", // 垂直切换选项
				loop: true, // 循环模式选项

				// 如果需要分页器
				pagination: {
					el: ".swiper-pagination",
					bulletActiveClass: "my-bullet-active",
					clickable: true,
				},

				// 如果需要前进后退按钮
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				},
			});
			// var mySwiper1 = new Swiper(".swiper1", {
			// 	// direction: "vertical", // 垂直切换选项
			// 	loop: true, // 循环模式选项

			// 	// 如果需要分页器
			// 	pagination: {
			// 		el: ".swiper1-pagination",
			// 		bulletActiveClass: "my-bullet-active",
			// 		clickable: true,
			// 	},

			// 	// 如果需要前进后退按钮
			// 	navigation: {
			// 		nextEl: ".swiper-button-next",
			// 		prevEl: ".swiper-button-prev",
			// 	},
			// });
		})

	},
	created() { },
	methods: {

	}
}
</script>
<style>

</style>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import "../assets/font/lang-font-family.css";
::v-deep .swiper-pagination-bullet{
	background-color: #ddd;
}
::v-deep .my-bullet-active {
  background: #666 !important;
  opacity: 1 !important;
}
::v-deep .swiper-pagination{
	bottom: 0 !important;
}
::v-deep .swiper-wrapper{
	height: auto;
}
::v-deep .swiper-pagination-bullet{
	margin: 20px 8px 20px !important;
}
.flex-bewteen {
	display: flex;
	justify-content: space-between;
}

.pc {
	display: block;
}

.mobile {
	display: none;
}

.swiper {
	position: relative;
  width: 100%;
  height: 100%;
}
.pay-session1-box {
	margin-top: 30px;
	background: #0a0202;
}

.pay-session1 {
	margin: 0 auto;
	min-height: 656px;
	max-width: 1440px;
	background-image: url(../assets/image/pay/session1_bg.png);
	background-size: auto 100%;
	background-position: right;
	background-repeat: no-repeat;
	padding: 150px 0 150px 64px;
}

.pay-session1-title {
	font-size: 48px;
	font-style: normal;
	font-weight: 900;
	line-height: 150%;
	letter-spacing: 1.44px;
	background: linear-gradient(90deg, #e8d6b0 0%, #d1ad77 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.en .pay-session1-title {
	font-size: 60px;
}

.pay-session1-detail {
	margin-top: 8px;
	color: #fff;
	font-size: 16px;
	font-style: normal;
	font-weight: 350;
	line-height: 150%;
}

.pay-session1-list {
	margin-top: 30px;
	color: #fff;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 235%;
}

.pay-session1-li {
	display: flex;
	align-items: center;
}

.pay-session1-li>span+span {
	margin-left: 16px;
}

.pay-session1-li :first-child {
	font-size: 14px;
	color: #af8147;
	flex-shrink: 0;
}

.btn-go {
	margin-top: 35px;
	display: inline-flex;
	padding: 12px 24px;
	justify-content: center;
	align-items: center;
	gap: 4px;
	flex-shrink: 0;
	border-radius: 4px;
	background: linear-gradient(90deg, #e8d7b1 0%, #d0ab75 100%);
	min-width: 230px;
	color: #010101;
	/* font-family: Roboto; */
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	/* 30px */
	transition: all 0.3s;
}

.btn-go:hover {
	color: #010101;
	opacity: 0.9;
}

.en .btn-go {
	font-weight: 700;
}

.pay-session2,
.pay-session3,
.pay-session4,
.pay-session5 {
	margin: 0 auto;
	padding: 80px 64px;
	max-width: 1440px;
}

.pay-session2-title,
.pay-session3-title,
.pay-session4-title,
.pay-session5-title {
	margin: 0 auto;
	color: #333;
	text-align: center;
	font-size: 48px;
	font-style: normal;
	font-weight: 800;
	line-height: 120%;
}
.en .pay-session2-title,
.en .pay-session3-title,
.en .pay-session4-title,
.en .pay-session5-title {
  font-weight: 400;
}

.pay-session2-subtitle,
.pay-session3-subtitle,
.pay-session4-subtitle {
	margin-top: 16px;
	color: #666;
	text-align: center;
	font-size: 18px;
	font-style: normal;
	font-weight: 350;
	line-height: 150%;
}

.pay-session2-list {
	display: flex;
	/* gap: 38px; */
	justify-content: space-between;
	align-items: flex-end;
	margin-top: 120px;
}

.pay-session2-li {
	width: calc(20% - 30.4px);
}

.pay-session2-li-top {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 67px;
	color: #fff;
	font-size: 23px;
	font-style: normal;
	font-weight: 700;
	line-height: 100%;
	background: linear-gradient(180deg, #cea57c 0%, #d5b18d 100%);
}

.pay-session2-li-top {
	font-family: Roboto;
}

.pay-session2-li-top-small {
	font-weight: 400;
	font-size: 16px;
}

.pay-session2-li-top-mini {
	font-weight: 400;
	font-size: 10px;
}

.pay-session2-li-content {
	background: #fafafa;
	padding: 18px 0 0 0;
}

.pay-session2-li-content-name {
	display: flex;
	justify-content: center;
	gap: 8px;

	color: #af8147;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	/* 24.943px */
}

.pay-session2-li-content-name .icon-Vector {
	font-size: 8px;
}

.pay-session2-li-bottom {
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 64px;
	padding: 14px 16px;
	border: 1px solid #e7dcce;
	color: #000;
	font-size: 12px;
	font-style: normal;
	font-weight: 350;
	line-height: 150%;
}

.pay-session2-li-content-icon {
	display: flex;
	justify-content: center;
	padding-top: 56px;
	padding-bottom: 36px;
}

.pay-session3-list {
	position: relative;
	margin-top: 80px;
	display: flex;
}

.pay-session3-li {
	width: 25%;
	display: flex;
	flex-direction: column;
}

.pay-session3-li-top {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 67px;
	background-color: rgba(210, 172, 133, 0.5);
	color: #8d622c;
	font-size: 21px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
}

.pay-session3-li-top2 {
	background-color: rgba(210, 172, 133, 0.6);
}

.pay-session3-li-top3 {
	background-color: rgba(210, 172, 133, 0.8);
}

.pay-session3-li-top4 {
	background-color: rgba(210, 172, 133, 1);
}

.pay-session3-li-content {
	position: relative;
	min-height: 598px;
	flex: 1;
	padding: 30px 56px;
}

.en .pay-session3-li-content {
	padding: 30px;
}

.pay-session3-li:nth-child(2n) .pay-session3-li-content {
	background: #fafafa;
}

.pay-session3-li:nth-child(2n + 1) .pay-session3-li-content {
	background: #f8f8f8;
}

.pay-session3-li-content-text {
	position: relative;
	color: #000;
	font-size: 16px;
	font-style: normal;
	font-weight: 350;
	line-height: 293%;
	padding-left: 14px;
}

.pay-session3-li-content-text-light {
	color: #af8147;
}

.pay-session3-li-content-text::before {
	content: "";
	width: 4px;
	height: 4px;
	position: absolute;
	left: 0;
	top: 21px;
	background-color: #000;
	border-radius: 50%;
}

.pay-session3-li-content-bg1 {
	height: 100%;
	background-image: url(../assets/image/pay/session3_bg1.png);
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: auto 243px;
}

.pay-session3-li-content-bg2 {
	height: 100%;
	background-image: url(../assets/image/pay/session3_bg2.png);
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: auto 315px;
}

.pay-session3-li-content-bg3 {
	height: 100%;
	background-image: url(../assets/image/pay/session3_bg3.png);
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: auto 350px;
}

.pay-session3-li-content-bg4 {
	height: 100%;
	background-image: url(../assets/image/pay/session3_bg4.png);
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: auto 386px;
}

.pay-session4-content {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 526px;
	margin-top: 106px;
}

.pay-session4-content-item {
	border-radius: 4px;
	border: 1px solid #e3e3e3;
	opacity: 0.65;
	background: #fff;
	box-shadow: 0px 9px 15px 0px rgba(0, 0, 0, 0.14);
	padding: 20px 30px;
}

.pay-session4-content-item-label {
	color: #af8147;
	font-size: 21px;
	font-style: normal;
	font-weight: 700;
	line-height: 138%;
}

.pay-session4-content-item-info {
	color: #666;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 138%;
}

.pay-session4-content-left,
.pay-session4-content-right {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}

.pay-session4-tips {
	margin-top: 106px;
	color: #999;
	text-align: center;
	font-size: 18px;
	font-style: normal;
	font-weight: 350;
	line-height: 150%;
	/* 27px */
}

.pay-session5-title {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 24px;
}

.pay-session5-content {
	margin: 80px auto 0;
	max-width: 768px;
}

.tip-title {
	padding: 8px;
	background: #f8f8f8;
	color: #333;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
}

.tip-content {
	margin: 16px 0 48px 0;
	color: #666;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	padding: 0 8px;
}

.tip-content:last-child {
	margin-bottom: 60px;
}

.tip-content-li {
	position: relative;
	padding-left: 15px;
	margin-bottom: 6px;
}

.tip-content-li::before {
	content: "";
	display: block;
	width: 4px;
	height: 4px;
	background-color: #666;
	position: absolute;
	left: 3px;
	top: 10px;
	border-radius: 50%;
}

.no-dot::before {
	width: 0;
	height: 0;
}

@media (max-width: 1300px) {
	.en .pay-session2-li-content-name {
		font-size: 14px;
	}

	.en .pay-session2-li-bottom {
		padding: 10px;
		font-size: 10px;
	}
}

@media (max-width: 1200px) {
	.pay-session2-li {
		width: calc(20% - 20px);
	}

	.pay-session3-li-content {
		padding: 30px;
	}
}

@media (max-width: 1100px) {
	.pay-session2-li {
		width: calc(20% - 8px);
	}

	.en .pay-session2-li-content-name {
		font-size: 12px;
	}

	.en .pay-session2-li-top-mini {
		font-size: 9px;
	}
}

@media (max-width: 995px) {
	.mobile {
		display: block;
	}

	.pc {
		display: none;
	}

	.pay-session2-title,
	.pay-session3-title,
	.pay-session4-title,
	.pay-session5-title {
		font-size: 40px;
		max-width: 700px;
	}

	.pay-session2-subtitle,
	.pay-session3-subtitle,
	.pay-session4-subtitle {
		margin-top: 19px;
	}

	.en .pay-session2-subtitle,
	.en .pay-session3-subtitle,
	.en .pay-session4-subtitle {
		font-size: 16px;
	}

	.pay-session1 {
		padding: 66px 25px 370px;
		background-image: url(../assets/image/pay/session1_bg_m.png);
		background-size: 100% auto;
		background-position: bottom;
		background-repeat: no-repeat;
	}

	.pay-session1-title {
		/* text-align: center; */
	}

	.en .pay-session1-title {
		font-size: 42px;
	}

	.btn-go {
		display: flex;
	}

	.pay-session2,
	.pay-session3,
	.pay-session4,
	.pay-session5 {
		padding: 100px 25px 0;
	}

	.pay-session2-list,
	.pay-session3-list {
		margin: 70px auto 0;
		max-width: 700px;
		overflow: hidden;
	}

	.pay-session2-li {
		width: 100%;
		padding-bottom: 40px;
	}

	.pay-session2-li-content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		min-height: 340px;
	}

	.pay-session2-li-content-name {
		font-size: 20px;
	}

	.pay-session2-li-content-icon {
		/* flex-grow: 1; */
	}

	.en .pay-session2-li-content-name {
		font-size: 16px;
	}

	.en .pay-session2-li-bottom {
		font-size: 12px;
	}

	.en .pay-session2-li-top-mini {
		font-size: 10px;
	}

	.pay-session3-li {
		width: 100%;
		padding-bottom: 40px;
		height: 100%;
	}

	.pay-session3-li-content {
		display: flex;
		flex-direction: column;
	}

	.pay-session3-li-content>div {
		flex: 1;
	}

	.en .pay-session3-li-content-text {
		font-size: 14px;
	}

	.pay-session4-content {
		margin: 70px auto 0;
		height: auto;
		flex-direction: column;
		max-width: 700px;
	}

	.pay-session4-content-item-m {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.pay-session4-content-item-m-label {
		color: #af8147;
		text-align: center;
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: 138%;
	}

	.pay-session4-content-item-m-info {
		color: #000;
		font-size: 14px;
		font-style: normal;
		font-weight: 350;
		line-height: 138%;
	}

	.pay-session4-content-center {
		display: flex;
		flex-wrap: nowrap;
		width: 100%;
	}

	.pay-session4-content-center img {
		width: 40%;
		flex: 1;
	}

	.pay-session4-content-item-m-left,
	.pay-session4-content-item-m-right {
		flex-shrink: 0;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		min-width: 74px;
	}

	.pay-session4-content-card {
		margin: 32px auto;
		width: 100%;
		padding: 14px 24px;
		max-width: 700px;
		border-radius: 4px;
		border: 1px solid #e3e3e3;
		opacity: 0.65;
		background: #fff;
		box-shadow: 0px 9px 15px 0px rgba(0, 0, 0, 0.14);
	}

	.pay-session4-content-card-item {
		padding: 19px 0;
	}

	.pay-session4-content-card-item+.pay-session4-content-card-item {
		border-top: 1px solid #e3e3e3;
	}

	.pay-session4-content-card-item-label {
		color: #af8147;
		text-align: center;
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: 138%;
	}

	.pay-session4-content-card-item-info {
		margin-top: 6px;
		color: #666;
		text-align: center;
		font-size: 12px;
		font-style: normal;
		font-weight: 350;
		line-height: 138%;
	}

	.pay-session4-tips {
		margin-top: 32px;
	}

	.pay-session5-content {
		max-width: 700px;
	}
}

.my-bullet-active {
	background: #666 !important;
	opacity: 1 !important;
}
</style>