<template>
	<div class="cooperative-partner" :class="$t('CFooters.className')">
		<ss-header mode="Home" bg="rgb(38, 5, 5)"></ss-header>
		<div :class="$t('CooperativePartner.box-class')">
			<div class="par1">
                    <p class="p1" v-html="$t('CooperativePartner.screen0.title1') + $t('CooperativePartner.screen0.title2')"></p>
                    <p class="line"></p>
                    <p class="p2">{{$t('CooperativePartner.screen0.desc')}}</p>
					<router-link to="/about-us" class="btn">{{$t('CooperativePartner.screen0.more')}}</router-link>
                </div>

                <div class="par2">
                    <div class="container">
                        <p class="partner-public-title">{{$t('CooperativePartner.screen1.pageTitle')}}</p>
                        <p class="partner-public-line"></p>
                        <p class="partner-public-title2" v-html="$t('CooperativePartner.screen1.title')"></p>
                        <p class="partner-public-desc">{{$t('CooperativePartner.screen1.desc')}}</p>
                        <div class="row">
                            <div class="col-md-6" v-for="(item, index) in $t('CooperativePartner.screen1.list')" :key="index">
								<img src="../assets/image/partner/partner-icon.png" alt="">
								<p>{{item}}</p>
							</div>
                        </div>
                        <router-link to="/about-us" class="partner-public-btn">{{$t('CooperativePartner.screen0.more')}}</router-link>
                    </div>
                </div>


                <div class="par3">
                    <div class="container">
                        <p class="partner-public-title">{{$t('CooperativePartner.screen2.pageTitle')}}</p>
                        <p class="partner-public-line"></p>
                        <p class="partner-public-title2">{{$t('CooperativePartner.screen2.title')}}</p>
                        <p class="partner-public-desc">{{$t('CooperativePartner.screen2.desc')}}</p>
                        <div class="row">
                            <div class="col-md-4" v-for="(item, index) in $t('CooperativePartner.screen2.list')" :key="index">
								<img :src="require(`../assets/image/partner/icon${ index + 1}.png`)" alt="">
								<p>{{item}}</p>
							</div>
                        </div>
                        <a href="https://www.ebcfinsns.co/login" class="partner-public-btn">{{$t('CooperativePartner.screen0.more')}}</a>
                    </div>
                </div>


                <div class="par4">
                    <div class="container">
                        <p class="partner-public-title">{{$t('CooperativePartner.screen3.pageTitle')}}</p>
                        <p class="partner-public-line"></p>
                        <p class="partner-public-title2">{{$t('CooperativePartner.screen3.title')}}</p>
                        <p class="partner-public-desc">{{$t('CooperativePartner.screen3.desc')}}</p>
                        <img src="../assets/image/partner/partner2.jpg" alt="">
						<router-link to="/service" class="partner-public-btn">{{$t('CooperativePartner.screen0.more')}}</router-link>
                    </div>
                </div>


                <div class="par5">
                    <div class="container">
                        <p class="partner-public-title">{{$t('CooperativePartner.screen4.pageTitle')}}</p>
                        <p class="partner-public-line"></p>
                        <p class="partner-public-title2">{{$t('CooperativePartner.screen4.title')}}</p>
                        <p class="partner-public-desc">{{$t('CooperativePartner.screen4.desc')}}</p>
                        <div class="par5-content">
                            <img class="par5-img" src="../assets/image/partner/partner3.jpg" alt="">
                            <div class="par5-bottom">
                                <p v-for="(item, index) in $t('CooperativePartner.screen4.list')" :key="index"><em v-html="item"></em><img src="../assets/image/partner/fca12.png" alt=""></p>
                            </div>
                        </div>
						<router-link to="/service" class="partner-public-btn">{{$t('CooperativePartner.screen0.more')}}</router-link>
                    </div>
                </div>


				<page-slogan></page-slogan>
		</div>
		<ss-contact-us mode="all"></ss-contact-us>
		<new-footer></new-footer>
	</div>
</template>

<script>
	import WOW from 'wowjs';
	export default {
		data() {
			return {
				seoInfo: ""
			}
		},
		metaInfo() {
			return {
				title: this.seoInfo.title || '',
				meta: [ // set meta
					{
						name: 'keyWords',
						content: this.seoInfo.keyWords || ''
					},
					{
						name: 'description',
						content: this.seoInfo.description || ''
					}
				]
			}
		},
		created() {
			this.$getSeoInfo({
				lang: this.$store.state.Lan,
				path: "/partner"
			}).then(res => {
				this.seoInfo = res
			})
		},
		mounted() {
			let wow = new WOW.WOW({
				boxClass: 'wow',
				animateClass: 'animated',
				offset: 0,
				mobile: true,
				live: false
			});
			wow.init();
		},
		methods: {

		}
	}
</script>

<style>
	.c-footer .fp-overflow{
		display: unset !important;
	}
	.par1{
		background: url(../assets/image/partner/partner1-bg.jpg) 100%/auto 100% no-repeat;
		height: 750px;
		margin-top: 0px;
		text-align: center;
		padding-top: 126px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
	}

	.par1 .p1{
		color: #FFF;
		text-align: center;
		font-size: 42px;
		font-style: normal;
		font-weight: 700;
		margin-top: 86px;
	}
	.line{
		width: 80px;
		border: 1px solid #D9D9D9;
		margin: 60px auto 50px;
	}

	.par1 .p2{
		color: #FFF;
		text-align: center;
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		letter-spacing: 1px;
		width: 644px;
		margin: 0 auto;
		line-height: 36px;
		opacity: 0.9;
	}
	.par1 .btn{
		display: block;
		width: 170px;
		height: 46px;
		line-height: 32px;
		font-size: 16px;
		border-radius: 2px;
		background: #3D0101;
		color: #AF8147;
		margin: 50px auto;
	}
	.par1 .btn:hover{
		color: #3D0101;
		background: #AF8147;
	}

	.partner-public-title{
		color: #000;
		text-align: center;
		font-size: 34px;
		font-weight: 700;
	}
	.partner-public-line{
		width: 80px;
		border: 1px solid #AF8147;
		margin: 38px auto 36px;
	}
	.partner-public-title2{
		color: #3D0101;
		text-align: center;
		font-size: 18px;
		font-weight: 600;
	}
	.partner-public-desc{
		width: 436px;
		color: #999;
		text-align: center;
		font-size: 16px;
		font-weight: 400;
		line-height: 28px;
		margin: 20px auto 0;
	}


	.par2{
		background: #F8F8F8;
		padding: 80px 0;
	}
	.par2 .row{
		background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
		margin: 46px auto;
		padding: 50px 10% 50px 17%;
		width: 1000px;
	}
	.par2 .row div{
		margin-top: 10px;
	}
	.par2 .row img{
		width: 30px;
		position: relative;
		top: 7px;
	}
	.par2 .row p{
		color: #080E1C;
		font-size: 16px;
		font-weight: 400;
		letter-spacing: 1px;
		display: inline;
		line-height: 46px;
		margin-left: 16px;
	}


	.partner-public-btn{
		display: block;
    	width: max-content;
		height: 46px;
		line-height: 44px;
		font-size: 16px;
		border-radius: 2px;
		color: #3D0101;
		margin: 50px auto 0px;
		text-align: center;
		border: 1px solid #3D0101;
		border-radius: 2px;
		line-height: 16px;
		padding: 13px 30px;
	}
	.partner-public-btn:hover{
		color: #3D0101;
		background: #AF8147;
		border: 1px solid #AF8147;
	}


	.par3{
		background: #ffffff;
		padding: 80px 0;
	}
	.par3 .row{
		background: linear-gradient(270deg, rgba(248, 248, 248, 0.00) 0%, #F8F8F8 100%);
		margin: 54px auto;
		padding: 50px 7% 80px 5%;
		width: 1000px;
	}
	.par3 .row div{
		text-align: center;
	}
	.par3 .row img{
		width: 65px;
		position: relative;
		top: 26px;
	}
	.par3 .row p{
		color: #080E1C;
		font-size: 16px;
		font-weight: 400;
		letter-spacing: 1px;
		display: inline;
		line-height: 46px;
		margin-left: 20px;
	}


	.par4{
		background: #F8F8F8;
		padding: 80px 0;
	}
	.par4 img{
		display: block;
		margin: 40px auto;
		width: 1000px;
	}
	.par4 .partner-public-desc{
		width: 500px;
	}


	.par5{
		background: #FFFFFF;
		padding: 80px 0;
	}
	.par5 .par5-img{
		display: block;
		margin: 40px auto;
		width: 1000px;
	}
	.par5 .partner-public-desc{
		width: 500px;
	}
	.par5-content{
		position: relative;
		width: 1000px;
		margin: 0 auto;
	}
	.par5-bottom{
		position: absolute;
		bottom: 0px;
		height: 80px;
		background: rgba(29, 1, 1, 0.75);
		width: 1000px;
		text-align: center;
	}
	.par5-bottom p{
		color: #ffffff;
		font-size: 16px;
		letter-spacing: 1px;
		display: inline;
		padding: 0 60px;
		line-height: 74px;
	}
	.par5-bottom img{
		width: 7px;
		margin-left: 10px;
	}
	.par5-bottom span{
		font-size: 26px;
		font-weight: bold;
	}
	.public-slogan{
		padding: 28px 0px;
		background: #F8F8F8;
		color: #3D0101;
		font-size: 16px;
		text-align: center;
		line-height: 30px;
		font-weight: 600;
	}

	.partner-box-en .par2 .row{
		padding: 50px 0% 26px 22%;
	}
	.partner-box-en .par2 .row div{
		width: 100%;
	}
	.partner-box-en .par1 .p1{
		line-height: 52px;
		margin-top: 60px;
	}
	.partner-box-en .partner-public-desc{
		width: 750px;
	}

	.partner-box-en .par3 .row p{
		margin-left: 10px;
		display: block;
		line-height: 26px;
		margin-top: 30px;
	}
	.partner-box-en .par1 .p2{
		letter-spacing: 0px;
		width: 718px;
		line-height: 30px;
	}
	.partner-box-en .par2 .row p{
		letter-spacing: 0px;
	}
	.par1 .btn-ug{
		width: 190px;
	}


	@media(max-width:1400px) {
		.par1 .p1{
			font-size: 34px;
		}
		.par1 .p2{
			line-height: 32px;
			font-size: 16px;
			width: 600px;
		}
		.par1{
			height: 600px;
			padding-top: 80px;
		}
		.par4 img{
			width: 700px;
		}
		.par5 .par5-img{
			width: 700px;
		}
		.par5-bottom{
			width: 700px;
			height: 68px;
		}
		.par5-content{
			width: 700px;
		}
		.par5-bottom p{
			line-height: 60px;
		}
		.partner-box-en .par1{
			height: 650px;
		}
	}



	@media(max-width:995px) {
		.par1{
			background: url(../assets/image/partner/partner-m1.jpg) 100%/auto 100% no-repeat;
			height: 750px;
			margin-top: 0px;
			text-align: center;
			padding-top: 126px;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
		}
		.par1 .p1 br{
			display: block !important;
		}
		.par2 .row{
			width: 90%;
			padding: 20px 0% 0px 13%;
			margin: 30px auto;
		}
		.par2 .row p{
			font-size: 14px;
		}
		.par2 .row div{
			margin-top: 0px;
		}
		.par3 .row{
			width: 100%;
			padding: 20px 0% 0px 0%;
			margin: 30px auto;
			background: #ffffff;
		}
		.par3 .row div{
			width: 33.3%;
		}
		.par3 .row img{
			width: 54px;
			position: relative;
			top: 0px;
			left: 5%;
		}
		.par3 .row p{
			display: block;
			font-size: 14px;
			margin-left: 9%;
			line-height: 20px;
		}
		.par5-content{
			width: 100%;
		}
		.par5 .par5-img{
			width: 100%;
		}
		.par5-bottom{
			width: 100%;
			height: 46px;
		}
		.par5-bottom p{
			line-height: 42px;
			font-size: 14px;
			padding: 0px 20px;
		}
		.par5-bottom span{
			font-size: 18px;
		}
		.par5-bottom img{
			width: 6px;
			margin-left: 6px;
		}
		.par1 .p2{
			width: 100%;
			padding: 8%;
			font-size: 14px;
			line-height: 28px;
		}
		.line{
			width: 62px;
			border: 1px solid #D9D9D9;
			margin: 50px auto 10px;
		}
		.par1 .p1{
			margin-top: 58px;
			line-height: 58px;
			font-size: 34px;
		}
		.par1 .btn{
			width: 210px;
			margin: 14px auto;
		}
		.partner-public-desc{
			width: 90%;
			font-size: 14px;
			line-height: 22px;
		}
		.par4 .partner-public-desc,.par5 .partner-public-desc{
			width: 90%;
		}
		.partner-public-btn{
			width: max-content;
		}
		.par2,.par3,.par4,.par5{
			padding: 56px 0;
		}
		.partner-public-line{
			width: 50px;
			margin: 26px auto 26px;
		}
		.partner-public-title{
			font-size: 28px;
		}
		.par2 .row img{
			width: 28px;
		}
		.par4 img{
			width: 90%;
		}
		.public-slogan{
			padding: 20px 20px;
			line-height: 28px;
		}
		.partner-box-en .par2 .row{
			padding: 20px 0% 0px 3%;
		}
		.partner-box-en .par2 .row div{
			width: 100%;
			margin-top: 14px;
		}
		.partner-box-en .par2 .row div p{
			line-height: 30px;
		}
		.partner-box-en .par1 .p1{
			line-height: 44px;
			font-size: 28px;
			width: 90%;
			margin: 20px auto 0;
		}
		.partner-public-title2{
			line-height: 26px;
		}
		.partner-box-en .par5-bottom p{
			font-size: 12px;
			letter-spacing: 0px;
			padding: 0px 8px;
		}
		.partner-box-en .par5-bottom span{
			font-size: 16px;
		}
		.partner-box-en .partner-public-desc{
			width: 90%;
		}
		.partner-box-en .par1 .p2{
			width: 100%;
			font-size: 14px;
			line-height: 28px;
		}
		.partner-box-en .par2 .row{
			width: 100%;
		}
		.partner-box-en .par2 .row img{
			width: 24px;
		}
		.partner-box-en .par1 .p1{
			font-size: 26px;
			line-height: 34px;
		}
		.partner-box-en .partner-public-title{
			font-size: 22px;
			line-height: 32px;
		}
		.partner-box-en .public-slogan{
			padding: 16px 0px;
			line-height: 28px;
			font-size: 13px;
		}
		.partner-box-en .par2 .row div p{
			line-height: 30px;
			margin-left: 6px;
		}
		.partner-box-en .par2 .row div{
			text-align: center;
		}
		.partner-box-en .par3 .row p {
			margin-left: 8px;
			display: block;
			line-height: 18px;
			margin-top: 8px;
			font-size: 12px;
		}
	}
</style>