<template>
	<div class="mam-content" :class="$t('CFooters.className')">
		<ss-header mode="Home" bg="rgb(38, 5, 5)"></ss-header>
		<div :class="$t('Mam.styleName')">
			<div class="mam1">
				<p class="p1" v-html="$t('Mam.screen0.title')"></p>
				<p class="line"></p>
				<p class="p2">{{$t('Mam.screen0.content')}}</p>
				<a :href="$store.state.khUrl" class="btn">{{$t('Mam.screen0.btn')}}</a>
			</div>


			<div class="mam3">
				<div class="container">
					<img src="../assets/image/Mam/mam-mt4logo.png" alt="">
					<p class="public-title">{{$t('Mam.screen1.title')}}</p>
					<p class="public-line"></p>
					<p class="public-desc">{{$t('Mam.screen1.desc')}}</p>
				</div>
			</div>


			<div class="mam4">
				<div class="container">
					<p class="public-title">{{$t('Mam.screen2.pageTitle')}}</p>
					<p class="public-line"></p>
					<img :src="require(`../assets/image/Mam/7@2x-${$t('Mam.screen1.imgUrl')}.png`)" alt="">
					<p class="public-desc">{{$t('Mam.screen2.desc')}}</p>
				</div>
			</div>


			<div class="mam5">
				<div class="container">
					<p class="public-title">{{$t('Mam.screen3.pageTitle')}}</p>
					<p class="public-line"></p>
					<div class="row">
						<div class="col-md-4"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAZQAAADCAgMAAAATC9xtAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAMUExURUdwTP///5eXl40cHK7NEY4AAAABdFJOUwBA5thmAAAAoklEQVR42u3a3QmDMBSAUTfoHO0+3aGjFafrBjavAcH8mAvRc95UwveYXMmyAAAAkHmtyffwS+dD9v6xJb8Blec7+exVsi+dDyoqKioqKvNWYnbkmNMFAABw15+YhVNC0ZqWwah+TcuQV79GRUVFRUXlIpVhO3LM6QIAALjrr8qW+aHo3sWwqx5nznWF92FUVFRUVFSmqsTsyDGnCwAAAC7pD7JxTuQkCNCaAAAAAElFTkSuQmCC" alt=""><p>{{$t('Mam.screen3.line1')}}</p></div>
						<div class="col-md-4"><img src="../assets/image/Mam/icon@2x(1).c3196df.png" alt=""><p>{{$t('Mam.screen3.line2')}}</p></div>
						<div class="col-md-4"><img src="../assets/image/Mam/icon@2x(2).ae02756.png" alt=""><p>{{$t('Mam.screen3.line3')}}</p></div>
						<div class="col-md-4"><img src="../assets/image/Mam/icon@2x(3).0561a35.png" alt=""><p>{{$t('Mam.screen3.line4')}}</p></div>
						<div class="col-md-4"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAZQAAADCAgMAAAATC9xtAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAMUExURUdwTP///5eXl40cHK7NEY4AAAABdFJOUwBA5thmAAAAwElEQVR42u3ZwQ3CIBiA0UZnrK7jEi7BFC7hPHjz90CQpIYAvu9Kyks4EKDbJkmSJK3aKaV0W0W57xcKhbK2cs45P1ZRnvuVQqFQKBQKZVXl2C1wMOXQKlIolHGV2ENalfZdp7vynqdZaV5FCoVCoVAolF8occapK/E+P7AS89SVwiiFQqFQKBRKX6X0NjWt0jjP/yilU+3n2+JUype7CIVCoVAoFAqFQqFQllXiVSH+8sRdtHG0/kUvRZIkSZqnF3deOSu1onUYAAAAAElFTkSuQmCC" alt=""><p>{{$t('Mam.screen3.line5')}}</p></div>
						<div class="col-md-4"><img src="../assets/image/Mam/icon@2x(5).f212434.png" alt=""><p>{{$t('Mam.screen3.line6')}}</p></div>
					</div>
				</div>
			</div>

			<div class="mam6">
				<div class="container">
					<p class="public-title">{{$t('Mam.screen3.pageTitle2')}}</p>
					<p class="public-line"></p>
					<div class="row">
						<div class="col-md-6" v-for="(item, index) in $t('Mam.screen3.content')" :key="index">
							<img src="../assets/image/Mam/star-hover.png" alt="">
							<p>{{item}}</p>
						</div>
					</div>
					<a :href="$store.state.khUrl" class="public-btn">{{$t('Mam.screen0.btn')}}</a>
				</div>
			</div>
		</div>
		<page-slogan></page-slogan>
		<new-footer></new-footer>
	</div>
</template>

<script>
	import WOW from 'wowjs';
	export default {
		data() {
			return {
				khUrl: 'https://client.ebccrm.com/signup',
				seoInfo: ""
			}
		},
		metaInfo() {
			return {
				title: this.seoInfo.title || '',
				meta: [ // set meta
					{
						name: 'keyWords',
						content: this.seoInfo.keyWords || ''
					},
					{
						name: 'description',
						content: this.seoInfo.description || ''
					}
				]
			}
		},
		created() {
			this.$getSeoInfo({
				lang: this.$store.state.Lan,
				path: "/MAM"
			}).then(res => {
				this.seoInfo = res
			})
			let par = localStorage.getItem('advert')
			let lang = localStorage.getItem('language')
			let parms;
			if (lang === 'ct') {
				parms = 'tw'
			} else if (lang === 'ja') {
				parms = 'jp'
			} else if (lang === 'vi') {
				parms = 'zh'
			} else {
				parms = lang
			}
			par = JSON.parse(par)
			if (par != null) {
				this.khUrl = this.khUrl + '?utm_source=' + par.utm_source + '&utm_medium=' + par.utm_medium +
					'&utm_campaign=' + par.utm_campaign + '&utm_term=' + par.utm_term + '&utm_content=' + par.utm_content +
					'&hl=' + parms
			} else {
				this.khUrl = this.khUrl + '?hl=' + parms
			}
		},
		mounted() {
			let wow = new WOW.WOW({
				boxClass: 'wow',
				animateClass: 'animated',
				offset: 0,
				mobile: true,
				live: false
			});
			wow.init();
		},
		methods: {

		}
	}
</script>

<style>
.c-footer .fp-overflow{
	display: unset !important;
}
.mam1{
  background: url(../assets/image/Mam/mam-bg.png) 100%/auto 100% no-repeat;
  height: 750px;
  margin-top: 0px;
  text-align: center;
  padding-top: 126px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.mam1 .p1{
    color: #FFF;
    text-align: center;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    margin-top: 86px;
}
.line{
    width: 80px;
    border: 1px solid #D9D9D9;
    margin: 60px auto 50px;
}
.mam1 .p2{
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1px;
    width: 666px;
    margin: 0 auto;
    line-height: 36px;
    opacity: 0.9;
}
.mam1 .btn{
    display: block;
    width: max-content;
    padding: 10px 40px;
    height: 46px;
    font-size: 16px;
    border-radius: 2px;
    background: #3D0101;
    color: #AF8147;
    margin: 50px auto;
}
.mam1 .btn:hover{
    color: #3D0101;
    background: #AF8147;
}

.public-title{
    color: #000;
    text-align: center;
    font-size: 34px;
    font-weight: 700;
}
.public-line{
    width: 80px;
    border: 1px solid #AF8147;
    margin: 38px auto 36px;
}
.public-title2{
    color: #3D0101;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}
.public-desc{
    width: 54%;
    color: #666666;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    margin: 20px auto 0;
}


.mam2{
    background: #F8F8F8;
    padding: 80px 0;
}
.mam2 .row{
    background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
    margin: 46px auto;
    padding: 50px 10% 50px 17%;
    width: 1000px;
}
.mam2 .row div{
    margin-top: 10px;
}
.mam2 .row img{
    width: 30px;
    position: relative;
    top: 7px;
}
.mam2 .row p{
    color: #080E1C;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    display: inline;
    line-height: 46px;
    margin-left: 16px;
}


.public-btn{
    display: block;
    width: max-content;
    padding: 13px 40px;
    height: 44px;
    font-size: 16px;
    border-radius: 2px;
    color: #3D0101;
    margin: 50px auto 0px;
    text-align: center;
    border: 1px solid #3D0101;
    border-radius: 2px;
    line-height: 16px;
}
.public-btn:hover{
    color: #3D0101;
    background: #AF8147;
    border: 1px solid #AF8147;
}


.mam3{
    background: #ffffff;
    padding: 110px 0 120px;
}
.mam3 .row{
    background: linear-gradient(270deg, rgba(248, 248, 248, 0.00) 0%, #F8F8F8 100%);
    margin: 54px auto;
    padding: 50px 7% 80px 5%;
    width: 1000px;
}
.mam3 .row div{
    text-align: center;
}
.mam3 .row img{
    width: 65px;
    position: relative;
    top: 26px;
}
.mam3 .row p{
    color: #080E1C;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    display: inline;
    line-height: 46px;
    margin-left: 20px;
}


.mam4{
    background: #F8F8F8;
    padding: 80px 0;
}
.mam4 img{
    display: block;
    margin: 40px auto;
    width: 60%;
    background: #fff;
    padding: 30px;
}
.mam4 .public-desc{
    width: 60%;
    text-align: left;
}


.mam5{
    background: #FFFFFF;
    padding: 80px 0;
}
.mam5 .mam5-img{
    display: block;
    margin: 40px auto;
    width: 1000px;
}
.mam5 .public-desc{
    width: 500px;
}
.mam5-content{
    position: relative;
    width: 1000px;
    margin: 0 auto;
}
.mam5-bottom{
    position: absolute;
    bottom: 0px;
    height: 80px;
    background: rgba(29, 1, 1, 0.75);
    width: 1000px;
    text-align: center;
}
.mam5-bottom p{
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline;
    padding: 0 60px;
    line-height: 74px;
}
.mam5-bottom img{
    width: 7px;
    margin-left: 10px;
}
.mam5-bottom span{
    font-size: 26px;
    font-weight: bold;
}
.public-slogan{
    padding: 28px 0px;
    background: #F8F8F8;
    color: #3D0101;
    font-size: 16px;
    text-align: center;
    line-height: 30px;
    font-weight: 600;
}

.mamtner-box-en .mam2 .row{
    padding: 50px 0% 26px 22%;
}
.mamtner-box-en .mam2 .row div{
    width: 100%;
}
.mamtner-box-en .mam1 .p1{
    line-height: 52px;
    margin-top: 60px;
}
.mamtner-box-en .public-desc{
    width: 750px;
}

.mamtner-box-en .mam3 .row p{
    margin-left: 10px;
    display: block;
    line-height: 26px;
    margin-top: 30px;
}
.mamtner-box-en .mam1 .p2{
    letter-spacing: 0px;
    width: 718px;
    line-height: 30px;
}
.mamtner-box-en .mam2 .row p{
    letter-spacing: 0px;
}
.mam1 .btn-ug{
    width: 190px;
}
.mam3 img{
    width: 110px;
    margin: 0 auto 40px;
    display: block;
}


.mam5 .container{
    max-width: 1100px;
}
.mam5 .row div{
    text-align: center;
    margin-top: 30px;
    margin-bottom: 40px;
}
.mam5 .row img{
    width: 80%;
}
.mam5 .row p{
    color: #666666;
    font-size: 16px;
    line-height: 26px;
    width: 90%;
    margin: 30px auto 0;
}

.mam6{
    background: #F8F8F8;
    padding: 80px 0;
}
.mam6 .container{
    max-width: 1100px;
}
.mam6 .row div{
    background: #fff;
    width: auto;
    margin: 10px 2% 20px;
    height: 44px;
    padding: 14px 20px 14px 46px;
    position: relative;
    border-radius: 3px;
}
.mam6 .row div img{
    width: 18px;
    position: absolute;
    top: 13px;
    left: 20px;
}
.mam6 .row div p{
    float: left;
    font-size: 16px;
}
.mam1 .p1 br{
    display: none;
}

.en-style .mam1 .p2{
    letter-spacing: 0px;
}
.en-style .mam1 .p1{
    margin-top: 80px;
}
.vi-box .mam1 .p1{
    margin-top: 40px;
}







@media(max-width:1400px) {
    .mam1 .p1{
        font-size: 34px;
    }
    .mam1 .p2{
        line-height: 32px;
        font-size: 16px;
        width: 600px;
    }
    .mam1{
        height: 600px;
        padding-top: 80px;
    }
    .mam4 img{
        width: 700px;
    }
    .mam5 .mam5-img{
        width: 700px;
    }
    .mam5-bottom{
        width: 700px;
        height: 68px;
    }
    .mam5-content{
        width: 700px;
    }
    .mam5-bottom p{
        line-height: 60px;
    }
    .mamtner-box-en .mam1{
        height: 650px;
    }
}


@media(max-width:995px) {
    .mam1{
        background: url(../assets/image/Mam/mam-bg-m.png) 100%/auto 100% no-repeat;
        height: 750px;
        margin-top: 0px;
        text-align: center;
        padding-top: 126px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
    .mam1 .p1 br{
        display: block !important;
    }
    .mam2 .row{
        width: 90%;
        padding: 20px 0% 0px 13%;
        margin: 30px auto;
    }
    .mam2 .row p{
        font-size: 14px;
    }
    .mam2 .row div{
        margin-top: 0px;
    }
    .mam3 .row{
        width: 100%;
        padding: 20px 0% 0px 0%;
        margin: 30px auto;
        background: #ffffff;
    }
    .mam3 .row div{
        width: 33.3%;
    }
    .mam3 .row img{
        width: 54px;
        position: relative;
        top: 0px;
        left: 5%;
    }
    .mam3 .row p{
        display: block;
        font-size: 14px;
        margin-left: 9%;
        line-height: 20px;
    }
    .mam5-content{
        width: 100%;
    }
    .mam5 .mam5-img{
        width: 100%;
    }
    .mam5-bottom{
        width: 100%;
        height: 46px;
    }
    .mam5-bottom p{
        line-height: 42px;
        font-size: 14px;
        padding: 0px 20px;
    }
    .mam5-bottom span{
        font-size: 18px;
    }
    .mam5-bottom img{
        width: 6px;
        margin-left: 6px;
    }
    .mam1 .p2{
        width: 100%;
        padding: 8%;
        font-size: 14px;
        line-height: 26px;
    }
    .line{
        width: 62px;
        border: 1px solid #D9D9D9;
        margin: 26px auto 0px;
    }
    .mam1 .p1{
        margin-top: 58px;
        line-height: 58px;
        font-size: 34px;
    }
    .mam1 .btn{
        margin: 14px auto;
    }
    .public-desc{
        width: 90%;
        font-size: 15px;
        line-height: 24px;
    }
    .mam4 .public-desc,.mam5 .public-desc{
        width: 90%;
    }
    .mam2,.mam3,.mam4,.mam5{
        padding: 56px 0;
    }
    .public-line{
        width: 50px;
        margin: 26px auto 26px;
    }
    .public-title{
        font-size: 26px;
    }
    .mam2 .row img{
        width: 28px;
    }
    .mam4 img{
        width: 90%;
    }
    .public-slogan{
        padding: 20px 20px;
        line-height: 28px;
    }
    .mamtner-box-en .mam2 .row{
        padding: 20px 0% 0px 3%;
    }
    .mamtner-box-en .mam2 .row div{
        width: 100%;
        margin-top: 14px;
    }
    .mamtner-box-en .mam2 .row div p{
        line-height: 30px;
    }
    .mamtner-box-en .mam1 .p1{
        line-height: 44px;
        font-size: 28px;
        width: 90%;
        margin: 20px auto 0;
    }
    .public-title2{
        line-height: 26px;
    }
    .mamtner-box-en .mam5-bottom p{
        font-size: 12px;
        letter-spacing: 0px;
        padding: 0px 8px;
    }
    .mamtner-box-en .mam5-bottom span{
        font-size: 16px;
    }
    .mamtner-box-en .public-desc{
        width: 90%;
    }
    .mamtner-box-en .mam1 .p2{
        width: 100%;
        font-size: 14px;
        line-height: 26px;
    }
    .mamtner-box-en .mam2 .row{
        width: 100%;
    }
    .mamtner-box-en .mam2 .row img{
        width: 24px;
    }
    .mamtner-box-en .mam1 .p1{
        font-size: 26px;
        line-height: 34px;
    }
    .mamtner-box-en .public-title{
        font-size: 22px;
        line-height: 32px;
    }
    .mamtner-box-en .public-slogan{
        padding: 16px 0px;
        line-height: 28px;
        font-size: 13px;
    }
    .mamtner-box-en .mam2 .row div p{
        line-height: 30px;
        margin-left: 6px;
    }
    .mamtner-box-en .mam2 .row div{
        text-align: center;
    }
    .mamtner-box-en .mam3 .row p {
        margin-left: 8px;
        display: block;
        line-height: 18px;
        margin-top: 8px;
        font-size: 12px;
    }
    .public-slogan {
        padding: 16px 0px;
        line-height: 28px;
        font-size: 13px;
    }
    .mam1 .p1 br{
        display: block;
    }
    .mam3 img{
        width: 90px;
    }
    .mam4 img{
        padding: 0;
        background: none;
        margin: 0 auto;
        width: 96%;
    }
    .public-btn{
        margin: 40px auto 0px;
    }
    .mam4 .public-desc{
        margin-top: 30px;
    }
    .mam5 .row div{
        width: 50%;
        margin-bottom: 0px;
    }
    .mam5 .row p{
        font-size: 14px;
        width: 82%;
        margin: 20px auto 0;
        line-height: 22px;
    }
    .mam5 .row img{
        width: 88%;
    }
    .mam6 .row div p{
        font-size: 14px;
    }
    .mam6 .row div{
        margin-bottom: 6px;
        margin-left: 4%;
        padding: 16px 10px 14px 36px;
    }
    .mam6 .row div img{
        top: 13px;
        left: 12px;
    }
    .mam6{
        padding-bottom: 50px;
    }
    .en-style .mam6 .row div{
        width: 92%;
        line-height: 22px;
        height: auto;
        padding: 14px 10px 12px 36px;
    }
    .en-style .mam6 .row div p{
        font-size: 14px;
    }
    .en-style .mam6 .row div img{
        top: 18px;
        left: 12px;
        width: 13px;
    }
    .en-style .public-title{
        font-size: 23px;
    }
    .en-style .mam1 .p1{
        line-height: 46px;
        font-size: 30px;
        margin-top: 55px;
    }
}
@media(max-width:400px) {
    .mam6 .row div p{
        font-size: 13px;
    }
    .mam6 .row div{
        margin-bottom: 6px;
        margin-left: 4%;
        padding: 14px 10px 12px 34px;
        height: 38px;
    }
    .mam6 .row div img{
        top: 13px;
        left: 12px;
        width: 14px;
    }
    .en-style .mam6 .row div{
        width: 92%;
        line-height: 22px;
        height: auto;
    }
    .en-style .mam6 .row div p{
        font-size: 14px;
    }
}
</style>